const customercardReducer = (state, action) => {
  switch (action.type) {
    case "set":
      let set_state = { ...state };
      set_state[action.field] = {
        ...state[action.field],
        value: action.payload,
      };
      return set_state;
    case "error":
      let error_state = { ...state };
      error_state[action.field] = {
        ...state[action.field],
        errors: action.payload,
      };
      return error_state;
    case "reset":
      return action.payload;
    case "reset_errors":
      return {
        ...state,
        first_name: { ...state.first_name, errors: [] },
        last_name: { ...state.last_name, errors: [] },
        email: { ...state.email, errors: [] },
      };
    default:
      return state;
  }
};

export { customercardReducer };
