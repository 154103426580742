import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-scroll";
import "./style.scss";

function Element(prop) {
  return (
    <Col xs={prop.xs} sm={prop.sm} lg={prop.lg} className={prop.cls}>
      <Link to={prop.to} smooth="true" offset={-70} duration={500}>
        {prop.name}
      </Link>
    </Col>
  );
}

export default Element;
