import React, { useReducer, useState } from "react";
import Translation, { TranslationHTML } from "translation";
import { apiContactForm } from "api";
import { contactReducer } from "./reducers";
import { Col, Row, Button, Form, Spinner, Alert } from "react-bootstrap";
import "./style.scss";

const initialData = {
  name: {
    value: "",
    errors: [],
  },
  email: {
    value: "",
    errors: [],
  },
  text: {
    value: "",
    errors: [],
  },
};

function ContactForm({ lang }) {
  const [contact, ContactDispatch] = useReducer(contactReducer, initialData);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sendMessage = (event) => {
    event.preventDefault();
    setFormIsSubmitting(true);
    setFormSubmitted(false);
    ContactDispatch({ type: "reset_errors" });

    let submitData = {
      email: contact.email.value,
      name: contact.name.value,
      text: contact.text.value,
    };

    apiContactForm(submitData, lang).then(
      () => {
        setFormSubmitted(true);
        setFormIsSubmitting(false);
        ContactDispatch({ type: "reset", payload: initialData });
      },
      (error) => {
        let error_dispatches = {
          email: "set_email_error",
          text: "set_text_error",
        };

        for (let key in error_dispatches) {
          if (key in error) {
            ContactDispatch({
              type: error_dispatches[key],
              payload: error[key],
            });
          }
        }

        setFormIsSubmitting(false);
      }
    );
  };

  return (
    <Col>
      <Row className="form-wrap">
        <Col></Col>
        <Col sm="10" md="9" lg="10">
          <h3>
            <Translation k="contacts_form_title1" />
          </h3>
          <h1>
            <Translation k="contacts_form_title2" />
          </h1>
          <Form onSubmit={sendMessage}>
            <Form.Group controlId="formEmail">
              <Form.Label>
                <Translation k="giftcard_email" />
              </Form.Label>
              <Form.Control
                type="email"
                onChange={(e) =>
                  ContactDispatch({
                    type: "set_email",
                    payload: e.target.value,
                  })
                }
                isInvalid={contact.email.errors.length > 0}
                value={contact.email.value}
              />
              {contact.email.errors.map((error, i) => (
                <Form.Control.Feedback type="invalid" key={i}>
                  {error}
                </Form.Control.Feedback>
              ))}
            </Form.Group>
            <Form.Group controlId="formName">
              <Form.Label>
                <Translation k="customercard_first_name" />
              </Form.Label>
              <Form.Control
                type="name"
                onChange={(e) =>
                  ContactDispatch({
                    type: "set_name",
                    payload: e.target.value,
                  })
                }
                value={contact.name.value}
              />
            </Form.Group>
            <Form.Group controlId="formText">
              <Form.Label>
                <Translation k="contacts_form_text" />
              </Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={3}
                onChange={(e) =>
                  ContactDispatch({
                    type: "set_text",
                    payload: e.target.value,
                  })
                }
                isInvalid={contact.text.errors.length > 0}
                value={contact.text.value}
              />
              {contact.text.errors.map((error, i) => (
                <Form.Control.Feedback type="invalid" key={i}>
                  {error}
                </Form.Control.Feedback>
              ))}
            </Form.Group>
            <Row>
              <Col
                xs="6"
                sm="7"
                className="d-flex align-items-center justify-content-start"
              ></Col>
              <Col xs="6" sm="5" className="d-flex justify-content-end">
                <Button
                  type="submit"
                  disabled={formIsSubmitting}
                  variant="custom"
                  className="btn-custom-submit"
                >
                  <span hidden={formIsSubmitting}>
                    <Translation k="contacts_form_send" />
                  </span>
                  <Spinner
                    animation="border"
                    size="sm"
                    variant="light"
                    hidden={!formIsSubmitting}
                  />
                </Button>
              </Col>
            </Row>
            <Row hidden={!formSubmitted}>
              <Col>
                <Alert variant="success" className="mt-4">
                  <TranslationHTML k="contact_success" />
                </Alert>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </Row>
    </Col>
  );
}

export default ContactForm;
