import React, { useState } from "react";
import ProductModal from "pages/sortiment/components/product_modal/ProductModal";
import "./style.scss";
import ImageModal from "common/image_modal/ImageModal";

function RichText({ text, variant = "" }) {
  const [showProductModal, setShowProductModal] = useState(false);
  const [productId, setProductId] = useState(null);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  const [imageAltText, setImageAltText] = useState("");
  const [imageDescription, setImageDescription] = useState("");

  const onLinkClick = (e) => {
    const targetIsLink = e.target.closest("a");

    if (targetIsLink) {
      const url = targetIsLink.getAttribute("href");
      const splitted = url.split("/");

      if (splitted.length > 6) {
        if (splitted[splitted.length - 6] === "sortiment") {
          const productId = splitted[splitted.length - 2];
          setProductId(parseInt(productId));
          setShowProductModal(true);

          e.preventDefault();
        }
      }
    }

    if (e.target.tagName === "IMG") {
      const url = e.target.getAttribute("src");
      const cssClass = e.target.getAttribute("class");
      const altText = e.target.getAttribute("alt");
      const descriptionText = e.target.getAttribute("description");

      if (cssClass && cssClass.includes("no-popup")) {
        return;
      }

      setImageUrl(url);
      setImageAltText(altText);
      setImageDescription(descriptionText);
      setShowImageModal(true);
    }
  };

  return (
    <React.Fragment>
      <ProductModal
        show={showProductModal}
        onHide={() => setShowProductModal(false)}
        productID={productId}
      />
      <ImageModal
        show={showImageModal}
        src={imageUrl}
        name={imageAltText}
        description={imageDescription}
        onShowChange={() => setShowImageModal(false)}
      />
      <div
        dangerouslySetInnerHTML={{ __html: text }}
        className={"wysiwygBlock " + variant}
        onClick={(e) => onLinkClick(e)}
      ></div>
    </React.Fragment>
  );
}

export default RichText;
