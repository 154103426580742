import React, { useState, useEffect } from "react";
import InputRange from "react-input-range";
import "react-input-range/lib/css/index.css";
import "./style.scss";

function Slider({
  maxValue,
  minValue,
  step,
  onValueChange,
  showLabel = true,
  updateValues,
}) {
  const [value, setValue] = useState({
    min: minValue,
    max: maxValue,
  });

  useEffect(() => {
    setValue(updateValues);
  }, [updateValues]);

  const updateValue = (value) => {
    let newValue = value;

    if (newValue.min < minValue) {
      newValue.min = minValue;
    }

    if (newValue.max > maxValue) {
      newValue.max = maxValue;
    }

    setValue(newValue);
  };

  return (
    <InputRange
      formatLabel={(value) => (showLabel ? `${value}` : ``)}
      step={step}
      maxValue={maxValue}
      minValue={minValue}
      value={value}
      onChange={(value) => updateValue(value)}
      onChangeComplete={(value) => onValueChange(value)}
    />
  );
}

export default Slider;
