import axios from "axios";
import config from "config";
import { merge } from "lodash";

const _POST = (url, data, language, extra = null) => {
  let params = {
    headers: {
      "Accept-Language": language || config.DEFAULT_LANGUAGE,
    },
  };

  if (extra !== null) {
    params = merge(params, extra);
  }

  return axios
    .post(config.API_ENDPOINT + url, data, params)
    .then(function (response) {
      return new Promise(function (resolve, reject) {
        resolve(response);
      });
    })
    .catch(function (error) {
      return new Promise(function (resolve, reject) {
        if (error.response && error.response.status === 400) {
          reject(error.response.data);
        }

        // Log unhandled API errors
        console.log("API ERROR: ", error);
      });
    });
};

const _GET = (url, language) => {
  return axios
    .get(config.API_ENDPOINT + url, {
      headers: { "Accept-Language": language || config.DEFAULT_LANGUAGE },
    })
    .then(function (response) {
      return new Promise(function (resolve, reject) {
        resolve(response.data);
      });
    })
    .catch(function (error) {
      return new Promise(function (resolve, reject) {
        if (error.response && error.response.status === 404) {
          reject(error.response.data);
        }

        // Log unhandled API errors
        console.log("API ERROR: ", error);
      });
    });
};

const apiHomeNews = (language) => {
  return _GET("v1/home/news", language);
};

const apiHomeBanner = (language) => {
  return _GET("v1/home/banner", language);
};

const apiHomeActivities = (language) => {
  return _GET("v1/home/visits", language);
};

const apiNewsletterTypes = (language) => {
  return _GET("v1/subscribers/types", language);
};

const apiCreateNewsletterSubscriber = (data, language) => {
  return _POST("v1/subscribers", data, language);
};

const apiEvent = (id, language) => {
  return _GET("v1/event/" + id, language);
};

const apiGiftCardBanks = (language) => {
  return _GET("v1/giftcard/banks", language);
};

const apiOrderGiftCard = (data, language) => {
  return _POST("v1/giftcard", data, language);
};

const apiOrderCustomerCard = (data, language) => {
  return _POST("v1/customercard", data, language);
};

const apiHasActiveActivities = () => {
  return _GET("v1/home/visits/exist");
};

const apiHomeNewsImages = (language) => {
  return _GET("v1/home/image", language);
};

const apiSortimentProducts = (language) => {
  return _GET("v1/product", language);
};

const apiSortimentCategories = (language) => {
  return _GET("v1/product/category", language);
};

const apiSortimentProduct = (id, language) => {
  return _GET("v1/product/" + id, language);
};

const apiPurchasesGenerateExcel = (data, language) => {
  return _POST("v1/purchases/excel", data, language, { responseType: "blob" });
};

const apiAdvices = (language) => {
  return _GET("v1/pages/advice", language);
};

const apiPublicationCategories = (language) => {
  return _GET("v1/pages/publication/category", language);
};

const apiPublications = (language) => {
  return _GET("v1/pages/publication", language);
};

const apiCustomPage = (id, language) => {
  return _GET("v1/pages/custom/" + id, language);
};

const apiAboutUsPublications = (language) => {
  return _GET("v1/pages/publication/latest", language);
};

const apiContentPreview = (language, type, id, hash) => {
  return _GET("v1/pages/preview/" + type + "/" + id + "/" + hash, language);
};

const apiPartners = (language) => {
  return _GET("v1/partner", language);
};

const apiShowcase = (language) => {
  return _GET("v1/partner/showcase", language);
};

const apiPartnersResell = (language) => {
  return _GET("v1/partner/contact/resell", language);
};

const apiPartnersOther = (language) => {
  return _GET("v1/partner/contact/other", language);
};

const apiPartnersGardeners = (language) => {
  return _GET("v1/partner/contact/gardener", language);
};

const apiGalleries = (language) => {
  return _GET("v1/galleries", language);
};

const apiGalleryImages = (language, id) => {
  return _GET("v1/gallery/" + id, language);
};

const apiContactsOpenHours = (language) => {
  return _GET("v1/contact/info", language);
};

const apiContactForm = (data, language) => {
  return _POST("v1/contact", data, language);
};

const apiSortimentProductPreview = (id, hash, language) => {
  return _GET("v1/product/preview/" + id + "/" + hash, language);
};

const apiRedirectUrls = () => {
  return _GET("v1/redirect/links");
};

const apiLocales = () => {
  return _GET("v1/locales");
};

export {
  apiHomeNews,
  apiHomeBanner,
  apiHomeActivities,
  apiNewsletterTypes,
  apiCreateNewsletterSubscriber,
  apiEvent,
  apiGiftCardBanks,
  apiOrderGiftCard,
  apiOrderCustomerCard,
  apiHasActiveActivities,
  apiHomeNewsImages,
  apiSortimentProducts,
  apiSortimentCategories,
  apiSortimentProduct,
  apiPurchasesGenerateExcel,
  apiAdvices,
  apiPublicationCategories,
  apiPublications,
  apiCustomPage,
  apiAboutUsPublications,
  apiContentPreview,
  apiPartners,
  apiShowcase,
  apiPartnersResell,
  apiPartnersOther,
  apiPartnersGardeners,
  apiGalleries,
  apiGalleryImages,
  apiContactsOpenHours,
  apiContactForm,
  apiSortimentProductPreview,
  apiRedirectUrls,
  apiLocales,
};
