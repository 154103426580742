export default {
  home: "/",
  home_activities: "/#activities",
  home_newsletter: "/#newsletter",
  sortiment: "/sortiment",
  sortiment_purchases: "/sortiment/purchases",
  sortiment_category: "/sortiment/:categoryId(\\d+)/:categorySlug",
  sortiment_product:
    "/sortiment/:categoryId(\\d+)/:categorySlug/product/:productId(\\d+)/:productSlug",
  advice: "/advice",
  advice_single: "/advice/:adviceId(\\d+)/:adviceSlug",
  about_us: "/about",
  gallery: "/gallery",
  gallery_group: "/gallery/:galleryId(\\d+)/:gallerySlug",
  publications: "/publications",
  publications_category: "/publications/:categoryId(\\d+)/:categorySlug",
  publications_product:
    "/publications/:categoryId(\\d+)/:categorySlug/:publicationId(\\d+)/:publicationSlug",
  partners: "/partners",
  contact: "/contact",
  gift_card: "/card/gift",
  customer_card: "/card/customer",
  event: "/event/:eventId/:eventSlug",
  custom_page: "/page/:pageId/:pageSlug",
  content_preview: "/preview/:type/:id/:hash",
  product_preview: "/preview/product/:id/:hash",
};
