import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import "./style.scss";
import { LanguageContext } from "context";
import { apiHomeActivities } from "api";
import { Link } from "react-router-dom";
import Translation from "translation";
import { splitActivitiesInPastAndFuture } from "./utils";

function Activities() {
  const { language } = useContext(LanguageContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    setIsLoaded(false);

    apiHomeActivities(language).then((response) => {
      if (!isSubscribed) {
        return;
      }

      const { upcoming, past } = splitActivitiesInPastAndFuture(response);

      setUpcomingEvents(upcoming);
      setPastEvents(past);
      setIsLoaded(true);
    });

    return () => (isSubscribed = false);
  }, [language]);

  if (isLoaded && upcomingEvents.length === 0 && pastEvents.length === 0) {
    return null;
  }

  return (
    <Row className="Home-Activities" id="activities">
      <Col></Col>
      <Col xl="8" lg="10" md="12">
        <Row>
          <Col
            xl="6"
            lg="6"
            md="5"
            className="d-none d-md-block d-lg-block d-xl-block"
          >
            <div className="image"></div>
          </Col>
          <Col xl="6" lg="6" md="7">
            <div className="title">
              <Translation k="landing_activities_title" />
            </div>

            <div className="list overflow-auto">
              <div className="loading" hidden={isLoaded}>
                <Spinner animation="border" variant="secondary" />
              </div>

              {upcomingEvents.map((value, i) => (
                <ListItem
                  key={i}
                  items={value}
                  disabled={false}
                  ready={isLoaded}
                />
              ))}

              {pastEvents.map((value, i) => (
                <ListItem
                  key={i}
                  items={value}
                  disabled={true}
                  ready={isLoaded}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const ListItem = ({ ready, disabled, items }) => {
  if (!ready) {
    return null;
  }

  return (
    <div className={`item ${disabled ? "disabled" : ""}`}>
      <div className="date">
        <div className="month">
          <Translation k={"month_" + items[0].date.split("-")[1]} />
        </div>
        <div className="day">{items[0].date.split("-")[2]}.</div>
      </div>
      <div className="content">
        {items.map((content, i) => (
          <Link
            to={content.url}
            key={i}
            className={content.url.length === 0 ? "no-link" : ""}
          >
            <div className="primaryTitle">{content.primary_title}</div>
            <div className="secondaryTitle">{content.secondary_title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Activities;
