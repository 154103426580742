import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import routes from "routes";
import { LanguageContext, SortimentContext, LocalesContext } from "context";
import { apiLocales } from "api";
import { getFromStorage, setOnStorage } from "storage";
import { getLanguageKey } from "utils";
import { QueryClient, QueryClientProvider } from "react-query";
import config from "config";

import Header from "sections/header/Header";
import Footer from "sections/footer/Footer";

import Home from "pages/home/Home";
import AboutUs from "pages/about_us/AboutUs";
import Sortiment from "pages/sortiment/Sortiment";
import SortimentPurchases from "pages/sortiment_purchases/SortimentPurchases";
import Advice from "pages/advice/Advice";
import Gallery from "pages/gallery/Gallery";
import Publications from "pages/publications/Publications";
import CustomerCard from "pages/customer_card/CustomerCard";
import GiftCard from "pages/gift_card/GiftCard";
import Partners from "pages/partners/Partners";
import Contacts from "pages/contacts/Contacts";
import Event from "pages/event/Event";
import Error404 from "pages/error404/Error404";
import CustomPage from "pages/custom_page/CustomPage";
import PreviewContent from "pages/preview_content/PreviewContent";
import PreviewProduct from "pages/preview_product/PreviewProduct";
import Consent from "common/cookies-message/Consent";
import GAPageView from "common/helpers/GAPageView";

import PageTitles from "common/helpers/PageTitles";
import ScrollToTop from "common/helpers/ScrollToTop";
import RedirectToNewUrl from "common/helpers/RedirectToNewUrl";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: config.API_CACHE_EXPIRATION,
    },
  },
});

function App() {
  const [language, setLanguage] = useState(getLanguageKey());
  const [locales, setLocales] = useState({});

  const [selectedVariants, setSelectedVariants] = useState({});
  const [selectedVariantsFetched, setSelectedVariantsFetched] = useState(false);

  // Store current language in browser storage
  useEffect(() => {
    setOnStorage("language", language);
  }, [language]);

  // Load and update selected variants stored in browser storage
  useEffect(() => {
    if (!selectedVariantsFetched) {
      setSelectedVariantsFetched(true);
      const items = JSON.parse(getFromStorage("selectedVariants"));

      if (items !== null) {
        setSelectedVariants(items);
      }
    } else {
      setOnStorage("selectedVariants", JSON.stringify(selectedVariants));
    }
  }, [selectedVariants, selectedVariantsFetched]);

  // Fetch translation from API
  useEffect(() => {
    apiLocales().then((data) => {
      setLocales(data);
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Container fluid>
        <Router>
          <RedirectToNewUrl />
          <GAPageView />
          <ScrollToTop />

          <LanguageContext.Provider value={{ language, setLanguage }}>
            <LocalesContext.Provider value={{ locales, setLocales }}>
              <Consent />
              <SortimentContext.Provider
                value={{ selectedVariants, setSelectedVariants }}
              >
                <PageTitles />
                <Header />

                <Switch>
                  <Route exact path={routes.home} component={Home} />
                  <Route
                    exact
                    path={routes.sortiment_purchases}
                    component={SortimentPurchases}
                  />
                  <Route
                    exact
                    path={routes.sortiment_product}
                    component={Sortiment}
                  />
                  <Route
                    exact
                    path={routes.sortiment_category}
                    component={Sortiment}
                  />
                  <Route exact path={routes.sortiment} component={Sortiment} />
                  <Route exact path={routes.about_us} component={AboutUs} />
                  <Route exact path={routes.advice_single} component={Advice} />
                  <Route exact path={routes.advice} component={Advice} />
                  <Route exact path={routes.gallery} component={Gallery} />
                  <Route
                    exact
                    path={routes.gallery_group}
                    component={Gallery}
                  />
                  <Route
                    exact
                    path={routes.publications}
                    component={Publications}
                  />
                  <Route
                    exact
                    path={routes.publications_category}
                    component={Publications}
                  />
                  <Route
                    exact
                    path={routes.publications_product}
                    component={Publications}
                  />
                  <Route
                    exact
                    path={routes.customer_card}
                    component={CustomerCard}
                  />
                  <Route exact path={routes.gift_card} component={GiftCard} />
                  <Route exact path={routes.partners} component={Partners} />
                  <Route exact path={routes.contact} component={Contacts} />
                  <Route exact path={routes.event} component={Event} />
                  <Route
                    exact
                    path={routes.custom_page}
                    component={CustomPage}
                  />
                  <Route
                    exact
                    path={routes.product_preview}
                    component={PreviewProduct}
                  />
                  <Route
                    exact
                    path={routes.content_preview}
                    component={PreviewContent}
                  />
                  <Route path="*" component={Error404} />
                </Switch>

                <Footer />
              </SortimentContext.Provider>
            </LocalesContext.Provider>
          </LanguageContext.Provider>
        </Router>
      </Container>
    </QueryClientProvider>
  );
}

export default App;
