export default {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  VAT_PERCENT: 21,
  LANGAUGES: [
    {
      key: "lv",
      value: "LV",
    },
    {
      key: "ru",
      value: "RU",
    },
    {
      key: "en",
      value: "EN",
    },
  ],
  DEFAULT_LANGAUGE: "lv",
  CONTACTS: {
    header: {
      address: '"Sabīņi", Ādažu novads, LV-2164',
      phone: "67996370",
    },
    footer: {
      phone: "67996370",
      email: "baltezers@baltezers.lv",
      company_name: 'SIA "Kokaudzētava Baltezers"',
      address: '"Sabīņi", Ādažu novads, LV-2164',
      copyright: 'SIA "Kokaudzētava Baltezers" © ALL RIGHTS RESERVED',
    },
    contacts_page: {
      phone: "67996370",
      email: " baltezers@baltezers.lv",
      company_name: 'SIA "Kokaudzētava Baltezers"',
      address: '"Sabīņi", Ādažu novads, LV-2164',
      ceo: {
        name: " Varis Kazaks",
        phone: "29151190",
      },
    },
  },
  COMPANY_NAME: "Kokaudzētava Baltezers",
  SORTIMENTS: {
    products_per_page: 21,
  },
  CUSTOMER_CARD_DISCOUNT_PERCENT: 5,
  GA_TRACKING_ID: "UA-25034630-1",
  PRIVACY_POLICY_LINK: "/page/3/lietotas-sikdatnes-cookies",
  API_CACHE_EXPIRATION: 1000 * 60 * 5, // 5 minutes in miliseconds
};
