import React, { useEffect, useContext, useState } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { LanguageContext } from "context";
import "./style.scss";
import { apiEvent } from "api";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "routes";
import Translation from "translation";

const initialEvent = { days: [], images: [], texts: [] };

function Event() {
  const { eventId } = useParams();
  const { language } = useContext(LanguageContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(initialEvent);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    setData(initialEvent);

    apiEvent(eventId, language).then(
      (response) => {
        setData(response);
        setIsLoaded(true);
      },
      (error) => {
        setHasError(true);
      }
    );
  }, [language, eventId]);

  return (
    <React.Fragment>
      <Row className="Event-Header">
        <Col></Col>
        <Col xl="4" lg="6" md="10">
          <HeadingBox
            title={data.title}
            description={data.description}
            ready={isLoaded}
            hasError={hasError}
          />
        </Col>
        <Col></Col>
      </Row>

      <Row className="Event-Activities-And-Images" hidden={!isLoaded}>
        <Col></Col>
        <Col xl="8" lg="10" md="12">
          <ActivitiesAndImages
            title={data.schedule_title}
            days={data.days}
            images={data.images}
          />
        </Col>
        <Col></Col>
      </Row>

      <Row
        className="Event-Texts"
        hidden={!isLoaded || (isLoaded && data.texts.length === 0)}
      >
        <Col></Col>
        <Col xl="8" lg="10" md="12">
          <Texts texts={data.texts} />
        </Col>
        <Col></Col>
      </Row>

      <Row className="Event-Gallery" hidden={!isLoaded}>
        <Col></Col>
        <Col xl="8" lg="10" md="12">
          <Galleries ready={isLoaded} galleries={data.galleries} />
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

const HeadingBox = ({ ready, title, description, hasError }) => {
  return (
    <div className="headingBox">
      <div className="heading">
        <Translation k="event_title" />
      </div>

      <div className="title">{ready ? title : ""}</div>

      <div className="description">
        {ready && description}
        {!ready && !hasError && (
          <Spinner animation="border" variant="secondary" />
        )}
        {hasError && <Translation k="error_404" />}
      </div>
    </div>
  );
};

const ActivitiesAndImages = ({ title, days, images }) => {
  return (
    <React.Fragment>
      <Row>
        <Col className="title">{title}</Col>
      </Row>
      <Row>
        <Col xl="6" lg="6" md="7">
          <div className="contentHolder">
            <div className="activitites">
              {days.map((value, i) => (
                <ActivityItem key={i} activity={value} />
              ))}
            </div>
          </div>
        </Col>
        <Col xl="6" lg="6" md="5">
          <div className="contentHolder">
            <div className="images">
              <Row>
                {images.map((value, i) => (
                  <Image key={i} image={value} />
                ))}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const ActivityItem = ({ activity }) => {
  return (
    <div className="item">
      <div className="date">
        <div className="month">
          {activity.date.split("-")[2]}.{" "}
          <Translation k={"month_" + activity.date.split("-")[1]} />
        </div>
        <div className="time">{activity.duration}</div>
      </div>
      <div className="content">
        {activity.items.map((content, i) => (
          <Link
            to={content.url}
            key={i}
            className={content.url.length === 0 ? "no-link" : ""}
          >
            <div className="primaryTitle">{content.primary_title}</div>
            <div className="secondaryTitle">{content.secondary_title}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const Image = ({ image }) => {
  const alt = image.image.default_alt_text || image.name;

  return (
    <Col className="d-flex" xl="4" lg="4" md="6" sm="6">
      <div className="imageHolder">
        <a href={image.url} className={image.url.length === 0 ? "no-link" : ""}>
          <img src={image.thumbnails.list} alt={alt} />
        </a>
      </div>
    </Col>
  );
};

const Texts = ({ texts }) => {
  return (
    <React.Fragment>
      <Row>
        {texts.map((value, i) => (
          <Col xl="4" lg="4" md="4" sm="6" key={i} className="contentHolder">
            <div className="title">{value.title}</div>
            <div className="content">{value.content}</div>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

const Galleries = ({ ready, galleries }) => {
  if (!ready) {
    return null;
  }

  return (
    <React.Fragment>
      <Row>
        {galleries.map((gallery) => (
          <Col xl="3" lg="3" md="3" sm="6" key={gallery.id}>
            <Link to={routes.gallery + "/" + gallery.id + "/" + gallery.slug}>
              <div
                className="holder image"
                style={{
                  backgroundImage: "url(" + gallery.thumbnails.list + ")",
                }}
              ></div>
              <div className="title">{gallery.name}</div>
            </Link>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  );
};

export default Event;
