import React from "react";
import { Col } from "react-bootstrap";
import RichText from "common/richtext/RichText";
import "./style.scss";

const NewsBox = ({ data }) => {
  return (
    <Col>
      <RichText text={data.text} />
      {/* <p className="font-weight-bold">{data.text}</p> */}
    </Col>
  );
};

export default NewsBox;
