import React from "react";
import { Row } from "react-bootstrap";
import Translation from "translation";
import Element from "./Element";
import "./style.scss";

function AnchorList() {
  const xs = 4;
  const sm = 4;
  const lg = 4;
  const anchor_name1 = <Translation k="partners_anchor_1" />;
  const anchor_name2 = <Translation k="partners_anchor_2" />;
  const anchor_name3 = <Translation k="partners_anchor_3" />;

  return (
    <Row className="translate justify-content-center">
      <Element
        xs={xs}
        sm={sm}
        lg={lg}
        cls="anchor-list-item"
        to="resell"
        name={anchor_name1}
      />
      <Element
        xs={xs}
        sm={sm}
        lg={lg}
        cls="anchor-list-item"
        to="other"
        name={anchor_name2}
      />
      <Element
        xs={xs}
        sm={sm}
        lg={lg}
        cls="anchor-list-item"
        to="gardener"
        name={anchor_name3}
      />
    </Row>
  );
}

export default AnchorList;
