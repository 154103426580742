import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";

let style = {
  minHeight: "25.5625rem",
  paddingTop: "7rem",
};

function Loader() {
  return (
    <Row className="open-hours-wrap justify-content-center" style={style}>
      <Col xs="11">
        <Row>
          <Col xs="2"></Col>
          <Col lg="6" sm="8" className="loading d-flex justify-content-center">
            <Spinner animation="border" size="md" variant="light" />
          </Col>
          <Col lg="1"></Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Loader;
