import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { apiRedirectUrls } from "api";

const RedirectToNewUrl = () => {
  const history = useHistory();

  useEffect(() => {
    apiRedirectUrls().then((data) => {
      data.forEach((link) => {
        const oldLocation = new URL(link.old);

        if (window.location.search === oldLocation.search) {
          history.push(link.new);
        }
      });
    });
  }, [history]);

  return null;
};

export default RedirectToNewUrl;
