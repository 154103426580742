import { useContext, useEffect } from "react";
import config from "config";
import { LanguageContext, LocalesContext } from "context";
import { useLocation } from "react-router-dom";
import routes from "routes";

const PageTitles = () => {
  const { language } = useContext(LanguageContext);
  const { locales } = useContext(LocalesContext);
  const location = useLocation();

  const getLocaleName = (path) => {
    if (path.startsWith(routes.sortiment)) {
      return "navigation_sortiment";
    } else if (path.startsWith(routes.advice)) {
      return "navigation_suggetions";
    } else if (path.startsWith(routes.about_us)) {
      return "navigation_about_us";
    } else if (path.startsWith(routes.gallery)) {
      return "navigation_gallery";
    } else if (path.startsWith(routes.publications)) {
      return "navigation_publications";
    } else if (path.startsWith(routes.customer_card)) {
      return "navigation_customer_card";
    } else if (path.startsWith(routes.gift_card)) {
      return "navigation_gift_card";
    } else if (path.startsWith(routes.partners)) {
      return "navigation_partners";
    } else if (path.startsWith(routes.contact)) {
      return "navigation_contacts";
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    const locale = locales[getLocaleName(pathname)];

    if (!locale || pathname === "/") {
      document.title = config.COMPANY_NAME;
      return;
    }

    let title = locale[language];

    // In case language contains invalid value
    if (!title) {
      title = locale[config.DEFAULT_LANGAUGE];
    }

    if (title.length > 0) {
      document.title = config.COMPANY_NAME + " - " + title;
    } else {
      document.title = config.COMPANY_NAME;
    }
  }, [language, locales, location]);

  return null;
};

export default PageTitles;
