import React from "react";

import {
  TextBlock,
  TextImagesBlock,
  ThreeColumnsBlock,
  TextLeftImageRightBlock,
  TextRightImageLeftBlock,
} from "./components";

function ContentBlocks({ contents }) {
  return contents.map((content) => (
    <Block key={content.id} content={content} />
  ));
}

const Block = ({ content }) => {
  const {
    block_type_1,
    block_type_2,
    block_type_3,
    block_type_4,
    block_type_5,
  } = content;

  return (
    <React.Fragment>
      {block_type_1 !== null && <TextImagesBlock content={block_type_1} />}
      {block_type_2 !== null && <TextBlock content={block_type_2} />}
      {block_type_3 !== null && <ThreeColumnsBlock content={block_type_3} />}
      {block_type_4 !== null && (
        <TextLeftImageRightBlock content={block_type_4} />
      )}
      {block_type_5 !== null && (
        <TextRightImageLeftBlock content={block_type_5} />
      )}
    </React.Fragment>
  );
};

export default ContentBlocks;
