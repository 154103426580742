import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentBlockTitleRow from "common/content_blocks/components/common/title/ContentBlockTitleRow";
import ContentBlockImage from "common/content_blocks/components/common/image/ContentBlockImage";
import RichText from "common/richtext/RichText";

function TextLeftImageRightBlock({ content }) {
  const {
    image,
    primary_title,
    secondary_title,
    text,
    thumbnails,
    image_text,
  } = content;

  return (
    <Row>
      <Col>
        <ContentBlockTitleRow
          primary_title={primary_title}
          secondary_title={secondary_title}
        />
        <Row>
          <Col>
            <RichText text={text} />
          </Col>
          {image && (
            <Col md="4">
              <ContentBlockImage image={image} thumbnails={thumbnails} />
              <RichText text={image_text} />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
}

export default TextLeftImageRightBlock;
