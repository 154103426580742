import routes from "routes";

/**
 * Returns full publication url
 */
const getPublicationUrl = (publication, category) => {
  let categoryId = "0";
  let categorySlug = "all";

  if (category) {
    categoryId = category.id;
    categorySlug = category.slug;
  }

  return [
    routes.publications,
    categoryId,
    categorySlug,
    publication.id,
    publication.slug,
  ].join("/");
};

export { getPublicationUrl };
