const contactReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return action.payload;
    case "set_name":
      return { ...state, name: { ...state.name, value: action.payload } };
    case "set_email":
      return { ...state, email: { ...state.email, value: action.payload } };
    case "set_text":
      return { ...state, text: { ...state.text, value: action.payload } };
    case "reset_errors":
      return {
        ...state,
        email: { ...state.email, errors: [] },
        text: { ...state.text, errors: [] },
      };
    case "set_email_error":
      return { ...state, email: { ...state.email, errors: action.payload } };
    case "set_text_error":
      return { ...state, text: { ...state.text, errors: action.payload } };

    default:
      return state;
  }
};

export { contactReducer };
