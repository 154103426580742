import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentBlockTitleRow from "common/content_blocks/components/common/title/ContentBlockTitleRow";
import RichText from "common/richtext/RichText";

function TextBlock({ content }) {
  const { primary_title, secondary_title, text } = content;

  return (
    <Row>
      <Col>
        <ContentBlockTitleRow
          primary_title={primary_title}
          secondary_title={secondary_title}
        />
        <Row>
          <Col>
            <RichText text={text} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default TextBlock;
