import React from "react";
import { getProductTitles } from "pages/sortiment/utils";

function Title({ product }) {
  const { primaryTitle, secondaryTitle } = getProductTitles(product);

  return (
    <React.Fragment>
      <div className="primaryTitle">{primaryTitle}</div>
      <div className="secondaryTitle">{secondaryTitle}</div>
    </React.Fragment>
  );
}

export default Title;
