import React, { useContext, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ReactComponent as CartIcon } from "./icons/cart.svg";
import { ReactComponent as FilterIcon } from "./icons/filter.svg";
import routes from "routes";
import Search from "../search/Search";
import Translation from "translation";
import { Link } from "react-router-dom";
import "./style.scss";
import { SortimentContext } from "context";

function Header({
  showSections,
  parentCategories,
  onSearchChange,
  onChangeFiltersStatus,
  filtersAreVisible,
}) {
  const { selectedVariants } = useContext(SortimentContext);

  const PurchasesButton = () => {
    const count = Object.keys(selectedVariants).length;

    return (
      <Link to={routes.sortiment_purchases} className="btn cartButton">
        <CartIcon /> <Translation k="sortiment_purchases_button" />{" "}
        {count > 0 && "(" + count + ")"}
      </Link>
    );
  };

  const FilterButton = () => {
    if (!showSections) {
      return null;
    }

    return (
      <button
        className="btn filterButton"
        onClick={() => onChangeFiltersStatus()}
      >
        <FilterIcon />{" "}
        <Translation
          k={
            !filtersAreVisible
              ? "sortiment_filter_button_open"
              : "sortiment_filter_button_close"
          }
        />
      </button>
    );
  };

  // Hackish way to close filters on mobile devices by default
  useEffect(() => {
    if (window.innerWidth < 768) {
      onChangeFiltersStatus();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Row className="Sortiment-Header">
      <Col sm="9" className="d-none d-lg-block">
        <Sections show={showSections} categories={parentCategories} />
      </Col>

      <Col sm="3" className="d-none d-lg-block">
        <PurchasesButton />
      </Col>

      <Col className="d-lg-none mobileHeader">
        <Row>
          <Col md="6">
            <Search onSearchChange={(value) => onSearchChange(value)} />
          </Col>
          <Col sm="6" md="3">
            <PurchasesButton />
          </Col>
          <Col sm="6" md="3">
            <FilterButton />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const Sections = ({ show, categories }) => {
  const getCategoryUrl = (category) => {
    return [routes.sortiment, category.id, category.slug].join("/");
  };

  if (!show) {
    return (
      <div className="buttonHolder d-flex">
        <div className="navigationLevel">
          <Translation k="navigation_sortiment" />
        </div>
      </div>
    );
  }

  return (
    <div className="buttonHolder d-flex">
      <div className="navigationLevel">
        <Link to={routes.sortiment}>
          <Translation k="navigation_sortiment" />
        </Link>
        <i className="arrow right"></i>
        <Link to={routes.sortiment}>
          <Translation k="sortiment_base_category" />
        </Link>
        {categories.map((category) => (
          <span key={category.id}>
            <i className="arrow right"></i>
            <Link to={getCategoryUrl(category)}>{category.name}</Link>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Header;
