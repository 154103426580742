/**
 * Find advice by id in MPTT tree and return advice object and array of direct parents to object
 */
const getAdviceById = (advices, adviceId, prevValues = []) => {
  let values = prevValues;

  for (let advice of advices) {
    if (advice.id === parseInt(adviceId)) {
      values.push(advice);

      return {
        advice: advice,
        tree: values,
      };
    }

    if (advice.children.length > 0) {
      values.push(advice);
      const data = getAdviceById(advice.children, adviceId, values);

      if (data !== null) {
        return {
          advice: data.advice,
          tree: data.tree,
        };
      } else {
        values.pop();
      }
    }
  }

  return null;
};

export { getAdviceById };
