import React, { useContext, useEffect, useState } from "react";
import routes from "routes";
import { Link } from "react-router-dom";
import { apiAboutUsPublications } from "api";
import { LanguageContext } from "context";
import { Row, Col, Spinner } from "react-bootstrap";

function Publications() {
  const { language } = useContext(LanguageContext);
  const [publications, setPublications] = useState([]);
  const [loadingPublications, setLoadingPublications] = useState(true);

  useEffect(() => {
    setLoadingPublications(true);

    apiAboutUsPublications(language).then((data) => {
      setPublications(data);
      setLoadingPublications(false);
    });
  }, [language]);

  const Sections = ({ ready }) => {
    if (!ready) {
      return (
        <Col className="loading d-flex justify-content-center">
          <Spinner animation="border" size="md" variant="secondary" />
        </Col>
      );
    }

    return (
      <Col>
        {publications.length > 0 ? (
          publications.map((publications) => (
            <Link
              key={publications.id}
              to={
                routes.publications +
                "/" +
                publications.category.id +
                "/" +
                publications.category.slug +
                "/" +
                publications.id +
                "/" +
                publications.slug
              }
            >
              <Row className="publications">
                <Col
                  lg="8"
                  md="9"
                  xs="12"
                  className="d-flex align-items-center order-2 order-sm-2 order-lg-1 my-1 my-md-0"
                >
                  <p>{publications.name}</p>
                </Col>
                <Col
                  lg="2"
                  className="d-lg-flex d-none align-items-center order-lg-2"
                >
                  <p>{publications.issue_date}</p>
                </Col>
                <Col
                  lg="2"
                  md="3"
                  className="order-sm-1 order-1 order-md-3 my-1 my-md-0"
                >
                  <img
                    src={publications.category.thumbnails.carousel}
                    alt=""
                  ></img>
                </Col>
              </Row>
            </Link>
          ))
        ) : (
          <Row>
            <Col className="alert alert-secondary">
              <div className="d-flex justify-content-center">
                <p className="mb-0">Pašlaik publikāciju nav.</p>
              </div>
            </Col>
          </Row>
        )}
      </Col>
    );
  };

  return <Sections ready={!loadingPublications} />;
}

export default Publications;
