import React, { useState, useContext, useEffect, useReducer } from "react";
import { Row, Col } from "react-bootstrap";
import { apiSortimentProducts, apiSortimentCategories } from "api";
import { LanguageContext } from "context";
import {
  getPageCount,
  getCategoryById,
  getCategoryAndChildrenIDs,
} from "./utils";
import { useParams } from "react-router-dom";
import Header from "./components/header/Header";
import ProductList from "./components/product_list/ProductList";
import Filters from "./components/filters/Filters";
import config from "config";
import { filtersReducer } from "./reducers";
import {
  INITIAL_FILTERS,
  HEIGHT_MAX,
  HEIGHT_MIN,
  WIDTH_MIN,
  WIDTH_MAX,
} from "./config";
import isEqual from "lodash/isEqual";

function Sortiment() {
  const { categoryId } = useParams();
  const { language } = useContext(LanguageContext);
  const [showFilters, setShowFilters] = useState(true);

  // Product lists
  const [isProductsReady, setIsProductsReady] = useState(false);
  const [products, setProducts] = useState([]); // All products from API
  const [categoryProducts, setCategoryProducts] = useState([]); // "products" filtered by category
  const [filtersProducts, setFiltersProducts] = useState([]); // "categoryProducts" filtered by additional filters (height, width, price, et.c)
  const [searchFilteredProducts, setSearchFilteredProducts] = useState([]); // "filtersProducts" filtered by search query

  // Category values
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentCategoryParentTree, setCurrentCategoryParentTree] = useState(
    []
  );

  // Filtering values
  const [searchValue, setSearchValue] = useState(null);
  const [filterValues, filterValuesDispatch] = useReducer(
    filtersReducer,
    INITIAL_FILTERS
  );
  const [isFiltersReady, setIsFiltersReady] = useState(false);

  // Pagination values
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const changeFiltersStatus = () => {
    setShowFilters(!showFilters);
  };

  const onFiltersChange = (type, data) => {
    if (type === "height") {
      filterValuesDispatch({ type: "update_height", payload: data });
    } else if (type === "width") {
      filterValuesDispatch({ type: "update_width", payload: data });
    } else if (type === "price") {
      filterValuesDispatch({ type: "update_price", payload: data });
    } else if (type === "hedge") {
      filterValuesDispatch({ type: "update_hedge", payload: data });
    } else if (type === "discount") {
      filterValuesDispatch({ type: "update_discount", payload: data });
    }
  };

  // Load product and category data from API
  useEffect(() => {
    let isSubscribed = true;

    setIsProductsReady(false);
    setIsFiltersReady(false);

    // Reset filters
    setSearchValue(null);
    filterValuesDispatch({ type: "reset", payload: INITIAL_FILTERS });

    apiSortimentCategories(language).then((response) => {
      if (!isSubscribed) {
        return;
      }

      setCategories(response);
      setIsFiltersReady(true);
    });

    apiSortimentProducts(language).then((response) => {
      if (!isSubscribed) {
        return;
      }

      setProducts(response);
      setCategoryProducts(response);
      setFiltersProducts(response);
      setSearchFilteredProducts(response);
      setIsProductsReady(true);
    });

    return () => (isSubscribed = false);
  }, [language]);

  // Set current category
  useEffect(() => {
    if (categories.length === 0) {
      return;
    }

    const categoryTree = {
      id: 0,
      name: "",
      text: "",
      children: categories,
    };

    if (categoryId && parseInt(categoryId) !== 0) {
      const data = getCategoryById(categories, categoryId);

      if (data === null) {
        setCurrentCategory(categoryTree);
      } else {
        setCurrentCategory(data.category);
        setCurrentCategoryParentTree(data.tree);
      }
    } else {
      setCurrentCategory(categoryTree);
      setCurrentCategoryParentTree([]);
    }
  }, [categoryId, categories]);

  // Filter products based on selected category
  useEffect(() => {
    if (currentCategory === null || products.length === 0) {
      return;
    }

    if (currentCategory.id === 0) {
      setCategoryProducts(products);
    } else {
      const categoryIDs = getCategoryAndChildrenIDs(currentCategory);
      let filtered = [];

      products.forEach((product) => {
        if (categoryIDs.includes(product.category)) {
          filtered.push(product);
        }
      });

      setCategoryProducts(filtered);
    }
  }, [currentCategory, products]);

  // Filter products based on selected filters (height, width, price, etc.)
  useEffect(() => {
    // Don't perform filtering if filter values are the initial ones
    if (isEqual(filterValues, INITIAL_FILTERS)) {
      setFiltersProducts(categoryProducts);
      return;
    }

    let filtered = [];

    categoryProducts.forEach((product) => {
      let canAdd = false;

      for (let i = 0; i < product.variants.length; i++) {
        const variant = product.variants[i];

        let isMatchingHeight = null;
        let isMatchingWidth = null;
        let isMatchingPrice = null;
        let isMatchingDiscount = null;

        // Check if variant matches current height filters if either min or max values exist
        if (variant.height_from.length > 0 || variant.height_to.length > 0) {
          // If variant has only min or max height set another one to default
          const heightFrom =
            variant.height_from.length > 0
              ? parseInt(variant.height_from)
              : HEIGHT_MIN;
          const heightTo =
            variant.height_to.length > 0
              ? parseInt(variant.height_to)
              : HEIGHT_MAX;

          if (
            heightFrom <= filterValues.height.max &&
            heightTo >= filterValues.height.min
          ) {
            isMatchingHeight = true;
          } else {
            isMatchingHeight = false;
          }
        }

        // Check if variant matches current width filters if either min or max values exist
        if (variant.width_from.length > 0 || variant.width_to.length > 0) {
          // If variant has only min or max width set another one to default
          const widthFrom =
            variant.width_from.length > 0
              ? parseInt(variant.width_from)
              : WIDTH_MIN;
          const widthTo =
            variant.width_to.length > 0
              ? parseInt(variant.width_to)
              : WIDTH_MAX;

          if (
            widthFrom <= filterValues.width.max &&
            widthTo >= filterValues.width.min
          ) {
            isMatchingWidth = true;
          } else {
            isMatchingWidth = false;
          }
        }

        // Check if variant price is in selected range
        if (variant.price !== null && variant.price.length > 0) {
          const price = Number(variant.price);

          if (
            price >= filterValues.price.min &&
            price <= filterValues.price.max
          ) {
            isMatchingPrice = true;
          } else {
            isMatchingPrice = false;
          }
        }

        // Check if discount is larger then default
        if (filterValues.is_discount) {
          if (variant.discount > config.CUSTOMER_CARD_DISCOUNT_PERCENT) {
            isMatchingDiscount = true;
          } else {
            isMatchingDiscount = false;
          }
        }

        // If at least one filter doesn't match then don't add
        if (
          ![
            isMatchingHeight,
            isMatchingWidth,
            isMatchingPrice,
            isMatchingDiscount,
          ].includes(false)
        ) {
          canAdd = true;
          break;
        }
      }

      if (filterValues.is_hedge && canAdd) {
        canAdd = product.usable_for_hedge;
      }

      if (canAdd) {
        filtered.push(product);
      }
    });

    setFiltersProducts(filtered);
  }, [categoryProducts, filterValues]);

  // Filter products based on search query
  useEffect(() => {
    if (searchValue === null) {
      setSearchFilteredProducts(filtersProducts);
      return;
    }

    const search = searchValue.toLowerCase();
    let filtered = [];

    filtersProducts.forEach((product) => {
      const name = product.name.toLowerCase();
      const fullName = [
        product.latin_name,
        product.latin_species,
        product.latin_variety,
      ]
        .join(" ")
        .toLowerCase();

      if (name.includes(search) || fullName.includes(search)) {
        filtered.push(product);
      }
    });

    setSearchFilteredProducts(filtered);
  }, [filtersProducts, searchValue]);

  // Update pagination numbers
  useEffect(() => {
    setPageCount(
      getPageCount(
        searchFilteredProducts.length,
        config.SORTIMENTS["products_per_page"]
      )
    );
    setCurrentPage(0);
  }, [searchFilteredProducts]);

  return (
    <Row>
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Header
          showSections={isFiltersReady && isProductsReady}
          parentCategories={currentCategoryParentTree}
          onSearchChange={setSearchValue}
          onChangeFiltersStatus={changeFiltersStatus}
          filtersAreVisible={showFilters}
        />
        <Row>
          <Col lg="3">
            <Filters
              ready={isFiltersReady && isProductsReady}
              onSearchChange={(value) => setSearchValue(value)}
              category={currentCategory}
              categoryParentTree={currentCategoryParentTree}
              onFiltersChange={onFiltersChange}
              products={searchFilteredProducts}
              showFilters={showFilters}
            />
          </Col>
          <Col lg="9" className="Sortiment-Products">
            <ProductList
              ready={isFiltersReady && isProductsReady}
              products={searchFilteredProducts}
              currentPage={currentPage}
              pageCount={pageCount}
              setCurrentPage={setCurrentPage}
              category={currentCategory}
            />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

export default Sortiment;
