import React, { useContext } from "react";
import { LanguageContext } from "context";
import { apiHomeNews } from "api";
import { useQuery } from "react-query";

import News from "./components/news/News";
import Image from "./components/image/Image";
import Sortiment from "./components/sortiment/Sortiment";
import Sections from "./components/sections/Sections";
import AboutUs from "./components/about-us/AboutUs";
import Activities from "./components/activities/Activities";
import Newsletter from "./components/newsletter/Nesletter";
import NewsImages from "./components/news-images/NewsImages";

function Home() {
  const { language } = useContext(LanguageContext);

  const {
    isLoading: newsIsLoading,
    data: newsData,
    isError: newsIsError,
  } = useQuery(["homeNews", language], () => apiHomeNews(language), {
    retry: 1,
  });

  return (
    <React.Fragment>
      <NewsImages hasNewsText={!newsIsLoading && !newsIsError} />
      <News isLoading={newsIsLoading} isError={newsIsError} data={newsData} />
      <Image />
      <Sortiment />
      <Sections />
      <AboutUs />
      <Activities />
      <Newsletter />
    </React.Fragment>
  );
}

export default Home;
