import config from "config";
import { getFromStorage } from "storage";

/**
 * Returns value from localStorage by key
 * @returns {string}
 */
const getLanguageKey = () => {
  const lang = getFromStorage("language");

  if (lang === null) {
    return config.DEFAULT_LANGAUGE;
  }

  return lang;
};

export { getLanguageKey };
