import React from "react";
import RichText from "common/richtext/RichText";

function CustomText({ product }) {
  const { custom_text } = product;

  if (custom_text.length === 0) {
    return null;
  }

  return <RichText text={custom_text} />;
}

export default CustomText;
