import React, { useContext, useReducer, useEffect, useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import "./style.scss";
import { LanguageContext } from "context";
import { apiGiftCardBanks, apiOrderGiftCard } from "api";
import { giftcardReducer } from "./reducers";
import config from "config";
import CardImage from "./images/card.png";
import Translation, { TranslationHTML, TranslationOption } from "translation";

const initialData = {
  name: {
    value: "",
    errors: [],
  },
  email: {
    value: "",
    errors: [],
  },
  phone: {
    value: "",
    errors: [],
  },
  legal_nr: {
    value: "",
    errors: [],
  },
  address: {
    value: "",
    errors: [],
  },
  bank_account: {
    value: "",
    errors: [],
  },
  bank: {
    value: 0,
    errors: [],
  },
  price: {
    value: 10,
    error: false,
  },
  count: {
    value: 1,
    error: false,
  },
};

function GiftCard() {
  const { language } = useContext(LanguageContext);

  const [banks, setBanks] = useState([]);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [extraFieldsOpen, setExtraFieldsOpen] = useState(false);

  const [giftcard, giftcardDispatch] = useReducer(giftcardReducer, initialData);

  useEffect(() => {
    apiGiftCardBanks(language).then((response) => {
      setBanks(response);
    });
  }, [language]);

  const orderGiftCard = (event) => {
    event.preventDefault();
    setFormIsSubmitting(true);
    setFormSubmitted(false);
    giftcardDispatch({ type: "reset_errors" });

    let submitData = {
      name: giftcard.name.value,
      email: giftcard.email.value,
      phone: giftcard.phone.value,
      legal_nr: giftcard.legal_nr.value,
      address: giftcard.address.value,
      bank_account: giftcard.bank_account.value,
      value: giftcard.price.value,
      count: giftcard.count.value,
    };

    // If Bank is not selected don't pass Bank field to API
    if (giftcard.bank.value > 0) {
      submitData["bank"] = giftcard.bank.value;
    }

    apiOrderGiftCard(submitData, language).then(
      (response) => {
        setFormSubmitted(true);
        setFormIsSubmitting(false);
        giftcardDispatch({ type: "reset", payload: initialData });
        setExtraFieldsOpen(false);
      },
      (error) => {
        let error_dispatches = {
          name: "set_name_error",
          email: "set_email_error",
          phone: "set_phone_error",
          legal_nr: "set_legal_nr_error",
          address: "set_address_error",
          bank_account: "set_bank_account_error",
        };

        for (let key in error_dispatches) {
          if (key in error) {
            giftcardDispatch({
              type: error_dispatches[key],
              payload: error[key],
            });
          }
        }

        setFormIsSubmitting(false);
      }
    );
  };

  return (
    <React.Fragment>
      <Row className="GiftCard-Form">
        <Col></Col>
        <Col xl="7" lg="10" md="10">
          <div className="formHolder">
            <InfoBox />

            <Form className="submitForm" onSubmit={orderGiftCard}>
              <Row>
                <Col xs={{ span: 10, offset: 1 }} sm="10">
                  <Row className="section-name">
                    <Col>
                      <Translation k="giftcard_section_name" />
                    </Col>
                  </Row>
                  <Row className="title">
                    <Col>
                      <Translation k="giftcard_form_title" />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formName">
                        <Form.Label>
                          <Translation k="giftcard_name" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_name",
                              payload: e.target.value,
                            })
                          }
                          isInvalid={giftcard.name.errors.length > 0}
                          value={giftcard.name.value}
                        />
                        {giftcard.name.errors.map((error, i) => (
                          <Form.Control.Feedback type="invalid" key={i}>
                            {error}
                          </Form.Control.Feedback>
                        ))}
                      </Form.Group>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formEmail">
                        <Form.Label>
                          <Translation k="giftcard_email" />
                        </Form.Label>
                        <Form.Control
                          type="email"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_email",
                              payload: e.target.value,
                            })
                          }
                          isInvalid={giftcard.email.errors.length > 0}
                          value={giftcard.email.value}
                        />
                        {giftcard.email.errors.map((error, i) => (
                          <Form.Control.Feedback type="invalid" key={i}>
                            {error}
                          </Form.Control.Feedback>
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formPhone">
                        <Form.Label>
                          <Translation k="giftcard_phone" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_phone",
                              payload: e.target.value,
                            })
                          }
                          isInvalid={giftcard.phone.errors.length > 0}
                          value={giftcard.phone.value}
                        />
                        {giftcard.phone.errors.map((error, i) => (
                          <Form.Control.Feedback type="invalid" key={i}>
                            {error}
                          </Form.Control.Feedback>
                        ))}
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="12" xs="12">
                      <Form.Group controlId="formPrice">
                        <Form.Label>
                          <Translation k="giftcard_value" />
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_price",
                              payload: e.target.value,
                            })
                          }
                          value={giftcard.price.value}
                          isInvalid={giftcard.price.error}
                          step={5}
                        />
                        <Form.Control.Feedback type="invalid">
                          <Translation k="giftcard_price_error" />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col xl="3" lg="3" md="3" sm="12" xs="12">
                      <Form.Group controlId="formCount">
                        <Form.Label>
                          <Translation k="giftcard_count" />
                        </Form.Label>
                        <Form.Control
                          type="number"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_count",
                              payload: e.target.value,
                            })
                          }
                          value={giftcard.count.value}
                          isInvalid={giftcard.count.error}
                        />
                        <Form.Control.Feedback type="invalid">
                          <Translation k="giftcard_count_error" />
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="6" xs="12">
                      <Button
                        variant="custom"
                        className={`btn-custom-extra-fields ${
                          extraFieldsOpen ? "active" : ""
                        }`}
                        onClick={() => setExtraFieldsOpen(!extraFieldsOpen)}
                      >
                        <Translation k="giftcard_extra_field" />
                      </Button>
                    </Col>
                  </Row>
                  <Row hidden={!extraFieldsOpen}>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formLegalNr">
                        <Form.Label>
                          <Translation k="giftcard_legal_nr" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_legal_nr",
                              payload: e.target.value,
                            })
                          }
                          isInvalid={giftcard.legal_nr.errors.length > 0}
                          value={giftcard.legal_nr.value}
                        />
                        {giftcard.legal_nr.errors.map((error, i) => (
                          <Form.Control.Feedback type="invalid" key={i}>
                            {error}
                          </Form.Control.Feedback>
                        ))}
                      </Form.Group>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formAddress">
                        <Form.Label>
                          <Translation k="giftcard_address" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_address",
                              payload: e.target.value,
                            })
                          }
                          isInvalid={giftcard.address.errors.length > 0}
                          value={giftcard.address.value}
                        />
                        {giftcard.address.errors.map((error, i) => (
                          <Form.Control.Feedback type="invalid" key={i}>
                            {error}
                          </Form.Control.Feedback>
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row hidden={!extraFieldsOpen}>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formBank">
                        <Form.Label>
                          <Translation k="giftcard_bank" />
                        </Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_bank",
                              payload: e.target.value,
                            })
                          }
                          value={giftcard.bank.value}
                        >
                          <option value="0">-</option>
                          {banks.map((value, i) => (
                            <option value={value.id} key={i}>
                              {value.name}
                            </option>
                          ))}
                          <TranslationOption
                            k="giftcard_other_bank"
                            value="-1"
                          />
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col xl="6" lg="6" md="6" sm="12" xs="12">
                      <Form.Group controlId="formBankAccount">
                        <Form.Label>
                          <Translation k="giftcard_bank_account" />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) =>
                            giftcardDispatch({
                              type: "set_bank_account",
                              payload: e.target.value,
                            })
                          }
                          isInvalid={giftcard.bank_account.errors.length > 0}
                          value={giftcard.bank_account.value}
                        />
                        {giftcard.bank_account.errors.map((error, i) => (
                          <Form.Control.Feedback type="invalid" key={i}>
                            {error}
                          </Form.Control.Feedback>
                        ))}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Pricing
                    price={giftcard.price.value}
                    count={giftcard.count.value}
                  />
                  <Row>
                    <Col lg={{ offset: 9 }} sm={{ offset: 6 }}>
                      <Button
                        type="submit"
                        variant="custom"
                        disabled={
                          formIsSubmitting ||
                          giftcard.price.error ||
                          giftcard.count.error
                        }
                        className="btn-custom-submit"
                      >
                        <span hidden={formIsSubmitting}>
                          <Translation k="giftcard_submit_button" />
                        </span>
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="light"
                          hidden={!formIsSubmitting}
                        />
                      </Button>
                    </Col>
                  </Row>
                  <Row hidden={!formSubmitted}>
                    <Col>
                      <Alert variant="success" className="mt-4">
                        <TranslationHTML k="giftcard_success" />
                      </Alert>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col></Col>
      </Row>
      <Row className="GiftCard-Info">
        <Col></Col>
        <Col xl="7" lg="10" md="10">
          <Row>
            <Col lg="3" md="4" className="d-none d-md-block">
              <img src={CardImage} alt="" />
            </Col>
            <Col lg="9" md="8" xs="12">
              <TranslationHTML k="giftcard_info" />
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

const Pricing = ({ price, count }) => {
  let total = price * count;
  let vat_price =
    (total / ((100 + config.VAT_PERCENT) / 100)) * (config.VAT_PERCENT / 100);
  let price_without_vat = Number((total - vat_price).toFixed(2));

  return (
    <Row className="pricingHolder">
      <Col xl={{ offset: 6 }} lg={{ offset: 6 }} md={{ offset: 6 }}>
        <Row>
          <Col className="text-right" xs="9">
            <strong className="label">
              <Translation k="giftcard_total" />
            </strong>
          </Col>
          <Col xs="3">
            <span className="value">{price_without_vat}</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="9">
            <strong className="label">
              <Translation k="giftcard_vat" />
            </strong>
          </Col>
          <Col xs="3">
            <span className="value">{config.VAT_PERCENT}%</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 mb-2" xs={{ offset: 6 }}>
            <div className="divider"></div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="9">
            <span className="value">
              <Translation k="giftcard_amount_to_pay" />
            </span>
          </Col>
          <Col xs="3">
            <strong className="price">{total}.00</strong>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const InfoBox = () => {
  const expirationDate = new Date(new Date().getFullYear() + 1, 11, 31);
  const formattedExpirationDate = [
    expirationDate.getDate(),
    expirationDate.getMonth() + 1,
    expirationDate.getFullYear(),
  ].join(".");

  return (
    <div className="d-none d-md-block">
      <div className="infoBox d-flex">
        <div className="contentHolder">
          <div>
            <strong>
              <Translation k="giftcard_info_type_label" />
            </strong>{" "}
            <Translation k="giftcard_info_type" />
          </div>
          <div>
            <strong>
              <Translation k="giftcard_info_expiration_date_label" />
            </strong>{" "}
            {formattedExpirationDate}
          </div>
          <div>
            <strong>
              <Translation k="giftcard_info_min_value_label" />
            </strong>{" "}
            <Translation k="giftcard_info_min_value" />
          </div>
          <div>
            <strong>
              <Translation k="giftcard_info_max_value_label" />
            </strong>{" "}
            <Translation k="giftcard_info_max_value" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCard;
