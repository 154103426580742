import React from "react";
import { InputGroup } from "react-bootstrap";
import { ReactComponent as SearchIcon } from "./icons/search.svg";
import { TranslationFormControl } from "translation";
import "./style.scss";

const Search = ({ onSearchChange }) => {
  return (
    <div className="Sortiment-Search">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <SearchIcon />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <TranslationFormControl
          placeholder_k="sortiment_search_placeholder"
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </InputGroup>
    </div>
  );
};

export default Search;
