import React from "react";
import { Col, Spinner } from "react-bootstrap";

function Loader(prop) {
  return (
    <Col className="loading d-flex justify-content-center">
      <Spinner animation="border" size="md" variant={prop.variant} />
    </Col>
  );
}

export default Loader;
