import React, { useContext, useEffect, useState, useReducer } from "react";
import { Row, Col, Alert, Spinner } from "react-bootstrap";
import { LanguageContext } from "context";
import { useParams } from "react-router-dom";
import { apiCustomPage } from "api";
import "./style.scss";
import Translation from "translation";
import { pageNavigationHistoryReducer } from "./reducers";
import ContentBlocks from "common/content_blocks/ContentBlocks";

function CustomPage() {
  const { pageId } = useParams();
  const { language } = useContext(LanguageContext);

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState(null);

  const [history, setHistory] = useState([]);
  const [content, setContent] = useState([]);

  useEffect(() => {
    setLoading(true);
    setHasError(false);

    apiCustomPage(pageId, language).then(
      (data) => {
        setData(data);
        setLoading(false);
      },
      (error) => {
        setHasError(true);
        setLoading(false);
      }
    );
  }, [pageId, language]);

  const showNavigation = () => {
    if (data === null) {
      return true;
    }

    return data.children.length > 0;
  };

  return (
    <Row className="CustomPage">
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Row className="header">
          <Col>
            <Header
              ready={!loading}
              data={data}
              history={history}
              hasError={hasError}
            />
          </Col>
        </Row>
        <Row>
          <Col hidden={!showNavigation()}>
            <Navigation
              ready={!loading}
              data={data}
              onHitsoryChange={(value) => setHistory(value)}
              onContentChange={(value) => setContent(value)}
              hasError={hasError}
            />
          </Col>
          <Col md={showNavigation() ? "9" : "12"}>
            <Content ready={!loading} content={content} hasError={hasError} />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const Header = ({ ready, data, history, hasError }) => {
  if (!ready || hasError) {
    return <div className="navigationLevel">...</div>;
  }

  return (
    <div className="navigationLevel">
      {data.name}
      {history.map((section) => (
        <span key={section.id}>
          <i className="arrow right"></i> {section.name}
        </span>
      ))}
    </div>
  );
};

const Navigation = ({
  ready,
  data,
  onHitsoryChange,
  onContentChange,
  hasError,
}) => {
  const [current, setCurrent] = useState(null);
  const [children, setChildren] = useState([]);
  const [history, historyDispatch] = useReducer(
    pageNavigationHistoryReducer,
    []
  );

  useEffect(() => {
    if (data === null || data.length === 0) {
      return;
    }

    setCurrent(data);
    setChildren(data.children);
  }, [data]);

  useEffect(() => {
    onHitsoryChange(history);
  }, [history, onHitsoryChange]);

  useEffect(() => {
    onContentChange(current);
  }, [current, onContentChange]);

  const nextSection = (section) => {
    if (section.children.length > 0) {
      historyDispatch({ type: "add", payload: section });
      setChildren(section.children);
    }

    setCurrent(section);
  };

  const previousSection = () => {
    if (history.length <= 1) {
      setCurrent(data);
      setChildren(data.children);
    }

    if (history.length > 1) {
      const prev = history[history.length - 2];
      setCurrent(prev);
      setChildren(prev.children);
    }

    historyDispatch({ type: "remove" });
  };

  if (hasError) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Translation k="error_404" />
      </Alert>
    );
  }

  if (!ready) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  return (
    <div className="filterHolder">
      <div className="label category">
        <NavigationTitle
          current={current}
          history={history}
          onBackClick={previousSection}
        />
      </div>
      <ul className="categories">
        {children.map((section) => (
          <li key={section.id}>
            <a
              href="#next"
              onClick={() => nextSection(section)}
              className={section.id === current.id ? "active" : ""}
            >
              {section.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const NavigationTitle = ({ history, onBackClick }) => {
  if (history.length === 0) {
    return (
      <a href="#parent" onClick={onBackClick}>
        <Translation k="custom_page_sub_sections_title" />
      </a>
    );
  }

  return (
    <a href="#previous" onClick={onBackClick}>
      <i className="arrow left"></i> {history[history.length - 1].name}
    </a>
  );
};

const Content = ({ ready, content, hasError }) => {
  if (hasError) {
    return (
      <Alert variant="secondary" className="text-center">
        <Translation k="error_404" />
      </Alert>
    );
  }

  if (!ready || content === null) {
    return (
      <Alert variant="secondary" className="text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  return <ContentBlocks contents={content.contents} />;
};

export default CustomPage;
