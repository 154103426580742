import React, { useState } from "react";
import { Spinner, Modal } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const ImageModal = ({ show, src, name, description, onShowChange }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <Modal size="lg" show={show} onHide={onShowChange}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative w-100">
          <div
            hidden={!showPlaceholder}
            className="position-absolute text-center w-100 imageLoader"
          >
            <Spinner animation="border" variant="secondary" size="sm" />
          </div>
          <div className="galleryLazyImage text-center">
            <LazyLoadImage
              src={src}
              alt={name}
              effect="opacity"
              wrapperClassName="w-100"
              afterLoad={() => setShowPlaceholder(false)}
            />
          </div>
        </div>
      </Modal.Body>
      {description && <Modal.Footer>{description}</Modal.Footer>}
    </Modal>
  );
};

export default ImageModal;
