import React, { useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import "./style.scss";
import { Link } from "react-router-dom";
import routes from "routes";
import YouTube from "react-youtube";
import Translation from "translation";

const YOUTUBE_VIDEO_ID = "PlSunJ2xPJw";

function AboutUs() {
  const [showVideo, setShowVideo] = useState(false);

  const VideoModal = () => {
    const opts = {
      height: "450",
      width: "100%",
      playerVars: {
        autoplay: 1,
      },
    };

    return (
      <Modal size="lg" show={showVideo} onHide={() => setShowVideo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kokaudzētava Baltezers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <YouTube videoId={YOUTUBE_VIDEO_ID} opts={opts} />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      <VideoModal />

      <Row className="Home-AboutUs-Content">
        <Col></Col>
        <Col xl="8" lg="10" md="12">
          <Row className="d-block d-md-none text-center">
            {" "}
            {/* Display this on mobile */}
            <TitleCol />
          </Row>
          <Row>
            <Col className="d-none d-md-block d-lg-block d-xl-block">
              {" "}
              {/* Display this on large device */}
              <Row className="h-50">
                <TitleCol />
              </Row>
              <Row className="h-50">
                <TextCol />
              </Row>
            </Col>
            <Col
              className="d-flex
                            mt-5 pt-2 mb-5 pb-4 
                            mt-xl-0 mt-lg-0 mt-md-0 pt-xl-0 pt-lg-0 pt-md-0
                            mb-xl-0 mb-lg-0 mb-md-0 pb-xl-0 pb-lg-0 pb-md-0
                            "
            >
              <div className="video" onClick={() => setShowVideo(true)}></div>
              <GalleryButton classes="d-none d-md-block d-lg-block d-xl-block left" />{" "}
              {/* Display this on large device */}
              <GalleryButton classes="d-block d-md-none text-center right" />{" "}
              {/* Display this on mobile */}
            </Col>
          </Row>
          <Row className="d-block d-md-none">
            {" "}
            {/* Display this on mobile */}
            <TextCol />
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

const TitleCol = () => {
  return (
    <Col className="d-flex">
      <div className="title">
        <Translation k="landing_about_us_title" />
      </div>
    </Col>
  );
};

const TextCol = () => {
  return (
    <Col>
      <div className="text">
        <Translation k="landing_about_us_text" />
      </div>
      <Link to={routes.about_us} className="link text-uppercase">
        <Translation k="landing_about_us_button" />
      </Link>
    </Col>
  );
};

const GalleryButton = ({ classes }) => {
  return (
    <Link
      to={routes.gallery}
      className={"galleryButton text-center " + classes}
    >
      <Translation k="landing_about_us_gallery_button" />
      <i className="arrow right"></i>
    </Link>
  );
};

export default AboutUs;
