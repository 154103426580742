import React from "react";
import { Row, Col } from "react-bootstrap";
import "./style.scss";
import { ReactComponent as FacebookIcon } from "./icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "./icons/instagram.svg";
import { ReactComponent as DraugiemIcon } from "./icons/draugiem.svg";
import config from "config";
import { TranslationHTML } from "translation";

function AboutUs() {
  return (
    <React.Fragment>
      <Row>
        <Col className="Footer contacts">
          <a href={"tel:+371" + config.CONTACTS.footer.phone}>
            {"(+371) " + config.CONTACTS.footer.phone} <br />
          </a>
          <a href={"mailto:" + config.CONTACTS.footer.email}>
            {config.CONTACTS.footer.email}
          </a>
        </Col>
      </Row>

      <Row>
        <Col className="Footer address">
          {config.CONTACTS.footer.company_name} <br />
          {config.CONTACTS.footer.address}
        </Col>
      </Row>

      <Row>
        <Col className="Footer icons">
          <a
            href="https://www.draugiem.lv/baltezers.lv/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DraugiemIcon />
          </a>
          <a
            href="https://www.instagram.com/kokaudzetavabaltezers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.facebook.com/baltezers.lv/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
        </Col>
      </Row>

      <Row>
        <Col className="Footer copyright">
          {config.CONTACTS.footer.copyright}
        </Col>
      </Row>

      <Row className="Footer developers">
        <Col>
          <TranslationHTML k="footer_developers" />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default AboutUs;
