import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Modal, Alert, Spinner } from "react-bootstrap";
import { LanguageContext } from "context";
import { apiSortimentProduct, apiSortimentProductPreview } from "api";
import "./style.scss";
import Translation from "translation";
import Variables from "./components/Variables";
import Images from "./components/Images";
import Title from "./components/Title";
import Icons from "./components/Icons";
import Variants from "./components/Variants";
import Texts from "./components/Texts";
import MonthsTable from "./components/MonthsTable";
import HedgeTable from "./components/HedgeTable";
import FruitTable from "./components/FruitTable";
import CustomText from "./components/CustomText";
import Links from "./components/Links";

function ProductModal({ show, onHide, productID, previewHash = null }) {
  const { language } = useContext(LanguageContext);
  const [product, setProduct] = useState(null);
  const [hasError, sethasError] = useState(false);

  useEffect(() => {
    if (productID !== null) {
      setProduct(null);
      sethasError(false);

      if (!previewHash) {
        // Fetch only active product
        apiSortimentProduct(productID, language).then(
          (response) => {
            setProduct(response);
          },
          (error) => {
            sethasError(true);
          }
        );
      } else {
        // Fetch product for preview mode
        apiSortimentProductPreview(productID, previewHash, language).then(
          (response) => {
            setProduct(response);
          },
          (error) => {
            sethasError(true);
          }
        );
      }
    }
  }, [productID, previewHash, language]);

  if (productID === null) {
    return null;
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      className="Sortiment-Product-Modal"
      dialogClassName="modalDialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Translation k="sortiment_modal_title" />
        </Modal.Title>
      </Modal.Header>
      <Body product={product} hasError={hasError} />
    </Modal>
  );
}

const Body = ({ product, hasError }) => {
  if (hasError) {
    return (
      <Modal.Body>
        <Alert variant="secondary" className="text-center mb-0">
          <Translation k="error_no_content" />
        </Alert>
      </Modal.Body>
    );
  }

  if (product === null) {
    return (
      <Modal.Body>
        <Alert variant="secondary" className="text-center mb-0">
          <Spinner animation="border" variant="secondary" />
        </Alert>
      </Modal.Body>
    );
  }

  return (
    <Modal.Body>
      <Row>
        <Col xs="12" className="titleContent d-block d-sm-none">
          <Title product={product} />
        </Col>
        <Col xs="12" sm="6" md="5" className="imagesContent">
          <Images
            mainImage={{ image: product.image, thumbnails: product.thumbnails }}
            galleryImages={product.gallery_images}
          />
        </Col>
        <Col xs="12" sm="6" md="7">
          <Row>
            <Col className="titleContent d-none d-sm-block">
              <Title product={product} />
            </Col>
          </Row>
          <Row>
            <Col className="variablesContent">
              <Variables product={product} />
            </Col>
          </Row>
          <Row>
            <Col className="iconContent">
              <Icons product={product} />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="variantsContent">
          <Variants product={product} />
        </Col>
      </Row>
      <Row>
        <Col className="customContent">
          <CustomText product={product} />
        </Col>
      </Row>
      <Row>
        <Col className="textsContent">
          <Texts product={product} />
        </Col>
      </Row>
      <Row>
        <Col className="tableContent">
          <MonthsTable product={product} />
        </Col>
      </Row>
      <Row>
        <Col className="tableContent">
          <Row>
            <HedgeTable product={product} />
            <FruitTable product={product} />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="linksContent">
          <Links product={product} />
        </Col>
      </Row>
    </Modal.Body>
  );
};

export default ProductModal;
