import React, { useState } from "react";
import { Row, Col, Alert, Modal } from "react-bootstrap";
import Translation, { TranslationHTML } from "translation";
import YouTube from "react-youtube";
import Publications from "./publications";
import BackgroundA from "./images/background_A.jpg";
import HistoryA from "./images/historyA.jpg";
import HistoryB from "./images/historyB_md.jpg";
import GreeneryA from "./images/greeneryA.jpg";
import GreeneryB from "./images/greeneryB.jpg";
import sab_img from "./images/sab.png";
import MotoImg from "./images/moto_bgr.jpg";
import "./style.scss";

let backgroundA = {
  background: "url(" + BackgroundA + ") no-repeat center",
  backgroundSize: "cover",
  minHeight: "50vh",
};

let MotoBgr = {
  background: "url(" + MotoImg + ") no-repeat",
  backgroundSize: "cover",
};

const YOUTUBE_VIDEO_ID = "PlSunJ2xPJw";

function AboutUs() {
  const [showVideo, setShowVideo] = useState(false);

  const VideoModal = () => {
    const opts = {
      height: "450",
      width: "100%",
      playerVars: {
        autoplay: 1,
      },
    };

    return (
      <Modal size="lg" show={showVideo} onHide={() => setShowVideo(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Kokaudzētava Baltezers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <YouTube videoId={YOUTUBE_VIDEO_ID} opts={opts} />
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div className="AboutUs">
      <VideoModal />
      <Row style={backgroundA}></Row>
      <Row className="grnBgr">
        <Col className="transformY"></Col>
        <Col xl="8" lg="11" className="transformY">
          <Alert variant="light">
            <Row className="justify-content-center">
              <Col className="d-md-block d-none"></Col>
              <Col xl="8" lg="8" md="9" sm="10" xs="11" className="content">
                <h3>
                  <Translation k="about_us_secondary_title" />
                </h3>
                <h1>
                  <Translation k="about_us_primary_title" />
                </h1>
                <TranslationHTML k="about_us_description" />
              </Col>
              <Col className="d-md-block d-none"></Col>
            </Row>
          </Alert>
        </Col>
        <Col className="transformY"></Col>
      </Row>
      <Row className="bg-white section-history">
        <Col
          xl="4"
          lg="5"
          md="7"
          className="px-0 order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1"
        >
          <img src={HistoryA} alt=""></img>
        </Col>
        <Col
          xl="2"
          lg="3"
          onClick={() => setShowVideo(true)}
          className="d-xl-block d-lg-block pointer d-none mx-3 order-lg-2 order-xl-2 position-relative"
        >
          <img src={HistoryB} alt=""></img>
          <div className="ellipse">
            <div className="vector">
              <div className="arrow-right"></div>
            </div>
          </div>
        </Col>
        <Col className="media-padding order-1 order-sm-1 order-md-3 order-lg-3 order-xl-3">
          <Row className="align-items-center justify-content-center h-100">
            <Col className="d-xl-block d-none"></Col>
            <Col xl="7" lg="10" md="10" className="textCenter">
              <h1>
                <Translation k="about_us_section_history_title" />
              </h1>
              <TranslationHTML k="about_us_section_history_description" />
            </Col>
            <Col xl="3" md="1"></Col>
          </Row>
        </Col>
      </Row>
      <Row className="section-greenery">
        <Col>
          <Row>
            <Col className="d-xl-block d-none"></Col>
            <Col xl="7" lg="10" md="10" className="textCenter">
              <h1>
                <Translation k="about_us_section_greenery_title" />
              </h1>
              <TranslationHTML k="about_us_section_greenery_description" />
            </Col>
            <Col xl="4"></Col>
          </Row>
          <Row className="mt-4">
            <Col className="d-none d-sm-block"></Col>
            <Col xl="5" lg="3" md="3" sm="3" xs="5">
              <Row className="d-flex">
                <img
                  src={sab_img}
                  alt=""
                  className="ml-auto sab-img img-fluid"
                ></img>
              </Row>
            </Col>
            <Col xl="4" lg="6" md="6" sm="5" xs="5">
              <Row className="align-items-center h-100">
                <p className="text-left pl-3">
                  <Translation k="contact_sab_first_part" />{" "}
                  <a
                    href="http://www.stadi.lv/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Translation k="contact_sab_second_part" />
                  </a>{" "}
                  <Translation k="contact_sab_third_part" />
                </p>
              </Row>
            </Col>
            <Col className="d-xl-block d-none d-sm-block"></Col>
          </Row>
        </Col>
        <Col xl="2" lg="3" className="d-xl-block d-lg-block d-none pt-5">
          <img src={GreeneryA} alt=""></img>
        </Col>
        <Col xl="4" lg="5" md="7" className="pt-5 px-0">
          <img src={GreeneryB} alt=""></img>
        </Col>
      </Row>
      <Row className="section-description justify-content-center">
        <Col md="9">
          <Row className="column-text-center">
            <Col md="4">
              <TranslationHTML k="about_us_column_1" />
            </Col>
            <Col md="4">
              <TranslationHTML k="about_us_column_2" />
            </Col>
            <Col md="4">
              <TranslationHTML k="about_us_column_3" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={MotoBgr} className="section-moto">
        <Col className="text-center">
          <TranslationHTML k="about_us_section_moto_titles" />
        </Col>
      </Row>
      <Row className="section-publications">
        <Col></Col>
        <Col xl="8" lg="11" md="11">
          <h3>
            <Translation k="publication_publication" />
          </h3>
          <h1>
            <Translation k="landing_about_us_button" />
          </h1>
          <Publications />
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default AboutUs;
