import React from "react";
import Loader from "./../multiuse/Loader";
import Message from "./../multiuse/Message";
import CheckLink from "./../multiuse/checkLink";
import { Row, Col, Card } from "react-bootstrap";

const List = ({ data, ready }) => {
  if (!ready) {
    return <Loader variant="secondary" />;
  }
  if (data.length === 0) {
    return <Message variant="secondary" />;
  }

  return (
    <Row>
      {data.map((data) => (
        <Col sm="6" md="4" lg="4" xl="3" key={data.id} className="my-1 px-1">
          <Card className="h-100">
            <Card.Header>
              <h3 className="py-1">{data.name}</h3>
            </Card.Header>
            <Card.Body>
              <Card.Text className="mb-1">{data.description}</Card.Text>
              {data.phones.split(",").map((phone) => (
                <Card.Text key={phone} className="mb-2">
                  {phone}
                </Card.Text>
              ))}
              <Card.Text>{data.address}</Card.Text>
              <CheckLink url={data.www} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default List;
