import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Alert, Spinner } from "react-bootstrap";
import { apiAdvices } from "api";
import { LanguageContext } from "context";
import { getAdviceById } from "./utils";
import { useParams } from "react-router-dom";
import "./style.scss";
import { Link } from "react-router-dom";
import routes from "routes";
import Translation from "translation";
import ContentBlocks from "common/content_blocks/ContentBlocks";

function Advice() {
  const { adviceId } = useParams();
  const { language } = useContext(LanguageContext);

  const [loading, setLoading] = useState(true);
  const [advices, setAdvices] = useState([]);

  const [currentAdvice, setCurrentAdvice] = useState(null);
  const [currentAdviceParentTree, setCurrentAdviceParentTree] = useState([]);
  const [navigation, setNavigation] = useState([]);

  // Load advices from API
  useEffect(() => {
    setLoading(true);

    apiAdvices(language).then((data) => {
      setAdvices(data);
      setNavigation(data);
      setLoading(false);
    });
  }, [language]);

  // Set currently selected advice
  useEffect(() => {
    if (advices.length === 0) {
      return;
    }

    // Default values if adviceId has not been given
    let advice = advices[0];
    let parentTree = [advices[0]];

    // Load specific advice data
    if (adviceId) {
      const data = getAdviceById(advices, adviceId);

      if (data) {
        advice = data.advice;
        parentTree = data.tree;
      } else {
        advice = null;
        parentTree = [];
      }
    }

    setCurrentAdviceParentTree(parentTree);
    setCurrentAdvice(advice);
  }, [adviceId, advices]);

  // Update navigation based on selected advice
  useEffect(() => {
    if (currentAdvice === null) {
      return;
    }

    if (currentAdvice.children.length === 0) {
      if (currentAdvice.parent_id === null) {
        setNavigation(advices);
      } else {
        setNavigation(
          getAdviceById(advices, currentAdvice.parent_id).advice.children
        );
      }
    } else {
      setNavigation(currentAdvice.children);
    }
  }, [currentAdvice, advices]);

  return (
    <Row className="Advices">
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Row className="header">
          <Col>
            <Header ready={!loading} parentTree={currentAdviceParentTree} />
          </Col>
        </Row>
        <Row>
          <Col lg="3">
            <Navigation
              ready={!loading}
              navigation={navigation}
              currentAdvice={currentAdvice}
              parentTree={currentAdviceParentTree}
            />
          </Col>
          <Col lg="9">
            <Content ready={!loading} advice={currentAdvice} />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const Header = ({ ready, parentTree }) => {
  if (!ready) {
    return (
      <div className="navigationLevel">
        <Translation k="advice_advices" />
      </div>
    );
  }

  return (
    <div className="navigationLevel">
      <Link to={routes.advice}>
        <Translation k="advice_advices" />
      </Link>
      {parentTree.map((item, index) => (
        <span key={index}>
          <i className="arrow right"></i>
          <Link to={routes.advice + "/" + item.id + "/" + item.slug}>
            {item.name}
          </Link>
        </span>
      ))}
    </div>
  );
};

const Navigation = ({ ready, navigation, currentAdvice, parentTree }) => {
  if (!ready) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  if (currentAdvice === null) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Translation k="error_404" />
      </Alert>
    );
  }

  return (
    <div className="filterHolder">
      <div className="label category">
        <NavigationTitle advice={currentAdvice} parentTree={parentTree} />
      </div>
      <ul className="categories">
        {navigation.map((item) => (
          <li key={item.id}>
            <NavigationItem
              advice={item}
              isCurrentAdvice={item.id === currentAdvice.id}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const NavigationItem = ({ advice, isCurrentAdvice }) => {
  if (isCurrentAdvice) {
    return <div className="active">{advice.name}</div>;
  }

  return (
    <Link to={routes.advice + "/" + advice.id + "/" + advice.slug}>
      {advice.name}
    </Link>
  );
};

const NavigationTitle = ({ advice, parentTree }) => {
  let routeParams = "";
  let title = null;

  if (advice.parent_id === null && advice.children.length > 0) {
    title = advice.name;
  }

  if (advice.parent_id !== null && advice.children.length === 0) {
    const parent = parentTree[parentTree.length - 2];
    routeParams =
      parentTree.length >= 3
        ? "/" +
          parentTree[parentTree.length - 3].id +
          "/" +
          parentTree[parentTree.length - 3].slug
        : "";
    title = parent.name;
  }

  if (advice.parent_id !== null && advice.children.length > 0) {
    routeParams =
      parentTree.length >= 2
        ? "/" +
          parentTree[parentTree.length - 2].id +
          "/" +
          parentTree[parentTree.length - 2].slug
        : "";
    title = advice.name;
  }

  if (title !== null) {
    return (
      <Link to={routes.advice + routeParams}>
        <i className="arrow left"></i> {title}
      </Link>
    );
  }

  return <Translation k="advice_all" />;
};

const Content = ({ ready, advice }) => {
  if (!ready) {
    return (
      <Alert variant="secondary" className="text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  if (advice === null) {
    return (
      <Alert variant="secondary" className="text-center">
        <Translation k="error_404" />
      </Alert>
    );
  }

  return <ContentBlocks contents={advice.contents} />;
};

export default Advice;
