export default {
  loading: {
    lv: "Ielādē...",
    ru: "",
    en: "Loading...",
  },
  navigation_home: {
    lv: "Uz sākumu",
    ru: "",
    en: "Home",
  },
  navigation_sortiment: {
    lv: "Sortiments",
    ru: "",
    en: "",
  },
  navigation_suggetions: {
    lv: "Padomi",
    ru: "",
    en: "",
  },
  navigation_place: {
    lv: "Kokaudzētava",
    ru: "",
    en: "",
  },
  navigation_about_us: {
    lv: "Par mums",
    ru: "",
    en: "About us",
  },
  navigation_gallery: {
    lv: "Galerija",
    ru: "",
    en: "Gallery",
  },
  navigation_publications: {
    lv: "Publikācijas",
    ru: "",
    en: "Publications",
  },
  navigation_customer_card: {
    lv: "Klienta karte",
    ru: "",
    en: "",
  },
  navigation_gift_card: {
    lv: "Dāvanu karte",
    ru: "",
    en: "",
  },
  navigation_partners: {
    lv: "Partneri",
    ru: "",
    en: "",
  },
  navigation_contacts: {
    lv: "Kontakti",
    ru: "",
    en: "",
  },
  navigation_shop: {
    lv: "Veikals",
    ru: "",
    en: "",
  },
  navigation_active_activities: {
    lv: "Notikumi",
    ru: "",
    en: "",
  },
  landing_tujas: {
    lv: "Tūjas",
    ru: "",
    en: "Thuja",
  },
  landing_egles: {
    lv: "Egles",
    ru: "",
    en: "",
  },
  landing_barbeles: {
    lv: "Bārbeles",
    ru: "",
    en: "",
  },
  landing_rododendri: {
    lv: "Rododendri",
    ru: "",
    en: "",
  },
  landing_kadiki: {
    lv: "Kadiķi",
    ru: "",
    en: "",
  },
  landing_view_all: {
    lv: "SKATĪT VISUS",
    ru: "",
    en: "VIEW ALL",
  },
  landing_pay_attention: {
    lv: "ŅEM VĒRĀ",
    ru: "",
    en: "",
  },
  landing_pay_attention_text: {
    lv: "Stādus konteineros un podos var stādīt no agra pavasara līdz vēlam rudenim!",
    ru: "",
    en: "",
  },
  landing_customer_card_title: {
    lv: "Ar klienta karti izdevīgāk!",
    ru: "",
    en: "",
  },
  landing_customer_card_button: {
    lv: "PIETEIKTIES",
    ru: "",
    en: "",
  },
  landing_gift_card_title: {
    lv: "Dāvanu karte",
    ru: "",
    en: "",
  },
  landing_gift_card_button: {
    lv: "IEGĀDĀTIES ŠEIT",
    ru: "",
    en: "",
  },
  landing_about_us_title: {
    lv: "Latvijas lielākā stādu audzētava kopš 1972. gada",
    ru: "",
    en: "",
  },
  landing_about_us_text: {
    lv: "Kokaudzētavas tirdzniecības laukumā, ar platību 8000 kvadrātmetri, tiek piedāvāts viens no plašākajiem augu sortimentiem Latvijā. Uz doto brīdi tiek piedāvāti vairāk kā 600 augu nosaukumu. Ik gadu stādu sortiments tiek papildināts, veicot jaunu šķirņu pārbaudi, atlasi un pavairošanu.",
    ru: "",
    en: "",
  },
  landing_about_us_button: {
    lv: "Par mums raksta",
    ru: "",
    en: "",
  },
  landing_about_us_gallery_button: {
    lv: "APSKATĪT GALERIJU",
    ru: "",
    en: "",
  },
  landing_activities_title: {
    lv: "Ciemiņi un Notikumi",
    ru: "",
    en: "",
  },
  month_01: {
    lv: "Janvāris",
    ru: "",
    en: "",
  },
  month_02: {
    lv: "Februāris",
    ru: "",
    en: "",
  },
  month_03: {
    lv: "Marts",
    ru: "",
    en: "",
  },
  month_04: {
    lv: "Aprīlis",
    ru: "",
    en: "",
  },
  month_05: {
    lv: "Maijs",
    ru: "",
    en: "",
  },
  month_06: {
    lv: "Jūnijs",
    ru: "",
    en: "",
  },
  month_07: {
    lv: "Jūlijs",
    ru: "",
    en: "",
  },
  month_08: {
    lv: "Augusts",
    ru: "",
    en: "",
  },
  month_09: {
    lv: "Septembris",
    ru: "",
    en: "",
  },
  month_10: {
    lv: "Oktobris",
    ru: "",
    en: "",
  },
  month_11: {
    lv: "Novembris",
    ru: "",
    en: "",
  },
  month_12: {
    lv: "Decembris",
    ru: "",
    en: "",
  },
  month_short_01: {
    lv: "Jan",
    ru: "",
    en: "",
  },
  month_short_02: {
    lv: "Feb",
    ru: "",
    en: "",
  },
  month_short_03: {
    lv: "Mar",
    ru: "",
    en: "",
  },
  month_short_04: {
    lv: "Apr",
    ru: "",
    en: "",
  },
  month_short_05: {
    lv: "Mai",
    ru: "",
    en: "",
  },
  month_short_06: {
    lv: "Jūn",
    ru: "",
    en: "",
  },
  month_short_07: {
    lv: "Jūl",
    ru: "",
    en: "",
  },
  month_short_08: {
    lv: "Aug",
    ru: "",
    en: "",
  },
  month_short_09: {
    lv: "Sep",
    ru: "",
    en: "",
  },
  month_short_10: {
    lv: "Okt",
    ru: "",
    en: "",
  },
  month_short_11: {
    lv: "Nov",
    ru: "",
    en: "",
  },
  month_short_12: {
    lv: "Dec",
    ru: "",
    en: "",
  },
  home_newsletter_title: {
    lv: "Pierakstīties jaunumiem",
    ru: "",
    en: "",
  },
  home_newsletter_text: {
    lv: "Laipni lūdzam jūs pieteikties uz jaunumiem, kurus Jūs saņemsiet uz savu e-pasta adresi un/vai telefonu. <br />Aizpildiet laukus ar informāciju un sekojiet tālākām norādēm.",
    ru: "",
    en: "",
  },
  home_newsletter_label_email: {
    lv: "E-pasts (obligāts):",
    ru: "",
    en: "",
  },
  home_newsletter_label_phone: {
    lv: "Telefona numurs:",
    ru: "",
    en: "",
  },
  home_newsletter_label_first_name: {
    lv: "Vārds (obligāts):",
    ru: "",
    en: "",
  },
  home_newsletter_label_last_name: {
    lv: "Uzvārds (obligāts):",
    ru: "",
    en: "",
  },
  home_newsletter_label_types: {
    lv: "Vēlos saņemt informāciju par:",
    ru: "",
    en: "",
  },
  home_newsletter_label_receive_types: {
    lv: "Saņemt informāciju:",
    ru: "",
    en: "",
  },
  home_newsletter_receive_type_email: {
    lv: "E-pastā",
    ru: "",
    en: "",
  },
  home_newsletter_receive_type_sms: {
    lv: "SMS",
    ru: "",
    en: "",
  },
  home_newsletter_button: {
    lv: "Pieteikties",
    ru: "",
    en: "",
  },
  home_newsletter_receive_type_error: {
    lv: "Lai noņemtu šo saņemšanas veidu, izvēlieties citu",
    ru: "",
    en: "",
  },
  home_newsletter_submit_success: {
    lv: "Jūs veiksmīgi esat pieteicies jaunumu saņemšanai",
    ru: "",
    en: "",
  },
  event_title: {
    lv: "PASĀKUMS",
    ru: "",
    en: "",
  },
  event_gallery_button: {
    lv: "SKATĪT GALERIJU",
    ru: "",
    en: "",
  },
  giftcard_info: {
    lv: "Lai veiktu pasūtījumu apmaksai ar pārskaitījumu, jāaizpilda elektroniskā Pasūtījuma forma. Priekšapmaksas rēķins par dāvanu karšu pasūtījumu automātiski tiek izsūtīts uz Pasūtījuma formā norādīto e-pasta adresi. (Ja gadījumā rēķins nav saņemts, lūdzu, informējiet mūs zvanot uz zemāk norādīto numuru). <br />Pēc rēķina apmaksas saņemšanas, nosūtīsim Jums Dāvanu Karti/es (PDF formātā) uz Pasūtījuma formā norādīto e-pasta adresi. Pasūtījuma formā norādītā informācija par dāvanu kartes pasūtītāju parādīsies tikai rēķinā, dāvanu kartē šī informācija netiks norādīta. <br /><br />Jautājumu vai neskaidrību gadījumā zvaniet (+371) 29353636.",
    ru: "",
    en: "",
  },
  giftcard_section_name: {
    lv: "PASŪTĪJUMA FORMA",
    ru: "",
    en: "",
  },
  giftcard_form_title: {
    lv: "Dāvanu kartes iegāde",
    ru: "",
    en: "",
  },
  giftcard_extra_field: {
    lv: "Papildus dati rekvizītiem",
    ru: "",
    en: "",
  },
  giftcard_name: {
    lv: "Vārds, Uzvārds / Uzņ. (obligāts):",
    ru: "",
    en: "",
  },
  giftcard_email: {
    lv: "E-pasts (obligāts):",
    ru: "",
    en: "",
  },
  giftcard_phone: {
    lv: "Tālrunis:",
    ru: "",
    en: "",
  },
  giftcard_value: {
    lv: "Vērtība (EUR):",
    ru: "",
    en: "",
  },
  giftcard_count: {
    lv: "Karšu skaits:",
    ru: "",
    en: "",
  },
  giftcard_legal_nr: {
    lv: "Pers. kods / Reģ. Nr.:",
    ru: "",
    en: "",
  },
  giftcard_address: {
    lv: "Adrese:",
    ru: "",
    en: "",
  },
  giftcard_bank_account: {
    lv: "Bankas konts:",
    ru: "",
    en: "",
  },
  giftcard_bank: {
    lv: "Banka:",
    ru: "",
    en: "",
  },
  giftcard_other_bank: {
    lv: "Cita",
    ru: "",
    en: "",
  },
  giftcard_submit_button: {
    lv: "Pasūtīt",
    ru: "",
    en: "",
  },
  giftcard_total: {
    lv: "Kopā",
    ru: "",
    en: "",
  },
  giftcard_vat: {
    lv: "PVN",
    ru: "",
    en: "",
  },
  giftcard_amount_to_pay: {
    lv: "Summa apmaksai (EUR)",
    ru: "",
    en: "",
  },
  giftcard_price_error: {
    lv: "Minimālā vērtība ir 10 Euro",
    ru: "",
    en: "",
  },
  giftcard_count_error: {
    lv: "Var pasūtīt 1-8 kartes",
    ru: "",
    en: "",
  },
  giftcard_success: {
    lv: "<p>Rēķins tika sagatavots un nosūtīts uz Jūsu norādīto e-pasta adresi. <br />Pēc rēķina apmaksas saņemšanas, nosūtīsim Jums Elektronisko dāvanu Karti/es un pavadzīmes PDF formātā uz Jūsu norādīto e-pasta adresi. <br /></p>* visi dokumenti ir sagatavoti elektroniski un ir derīgi bez paraksta <br />* dažām bankām naudas pārskaitījums izpildās 24-48h laikā. <br />* jautājumu vai neskaidrību gadījumā zvaniet 67996370.",
    ru: "",
    en: "",
  },
  giftcard_info_type_label: {
    lv: "Veids:",
    ru: "",
    en: "",
  },
  giftcard_info_type: {
    lv: "Elektroniska",
    ru: "",
    en: "",
  },
  giftcard_info_expiration_date_label: {
    lv: "Derīguma termiņš:",
    ru: "",
    en: "",
  },
  giftcard_info_min_value_label: {
    lv: "Minimālā vērtība:",
    ru: "",
    en: "",
  },
  giftcard_info_min_value: {
    lv: "€ 10.00",
    ru: "",
    en: "",
  },
  giftcard_info_max_value_label: {
    lv: "Maksimālā vērtība:",
    ru: "",
    en: "",
  },
  giftcard_info_max_value: {
    lv: "nav noteikta",
    ru: "",
    en: "",
  },
  error_404: {
    lv: "Kļūda 404 - Lapa netika atrasta",
    ru: "",
    en: "",
  },
  error_no_content: {
    lv: "Saturs netika atrasts",
    ru: "",
    en: "",
  },
  customercard_section_name: {
    lv: "PIETEIKUMA FORMA",
    ru: "",
    en: "",
  },
  customercard_title: {
    lv: "Pieteikums klienta kartei",
    ru: "",
    en: "",
  },
  customercard_first_name: {
    lv: "Vārds:",
    ru: "",
    en: "",
  },
  customercard_last_name: {
    lv: "Uzvārds:",
    ru: "",
    en: "",
  },
  customercard_email: {
    lv: "E-pasts:",
    ru: "",
    en: "",
  },
  customercard_button: {
    lv: "Pieteikties",
    ru: "",
    en: "",
  },
  customercard_success: {
    lv: "Jūs east veiksmīgi pieteicies klienta kartei",
    ru: "",
    en: "",
  },
  customercard_info: {
    lv: "<p>Reģistrējoties kļūsti par mūsu klientu un saņem ATLAIDES visiem augiem<br />(AKCIJAS ATLAIDI augiem ar akcijas uzlīmi un 5% ATLAIDI visiem pārējiem augiem)</p><p>Lai saņemtu atlaižu karti...<br/>Pie pirkuma informējat pārdevēju-konsultanti, ka esat reģistrējies/-usies mūsu mājas lapā un Jums tiks izsniegta Jūsu atlaižu karte un jau pie šī pirkuma saņemsiet atlaidi.</p>",
    ru: "",
    en: "",
  },
  customercard_subscribe_to_newsletter: {
    lv: "Vēlos saņemt informāciju par jaunumiem e-pastā",
    ru: "",
    en: "",
  },
  sortiment_purchases_button: {
    lv: "Atlasītie produkti",
    ru: "",
    en: "",
  },
  sortiment_filter_button_open: {
    lv: "Atvērt filtrus",
    ru: "",
    en: "",
  },
  sortiment_filter_button_close: {
    lv: "Aizvērt filtrus",
    ru: "",
    en: "",
  },
  sortiment_view: {
    lv: "SKATĪT",
    ru: "",
    en: "",
  },
  sortiment_not_available: {
    lv: "Nav pārdošanā",
    ru: "",
    en: "",
  },
  sortiment_search_placeholder: {
    lv: "Meklēt",
    ru: "",
    en: "",
  },
  sortiment_empty_list: {
    lv: "Pēc izvēlētajiem kritērijiem neviens produkts netika atrasts",
    ru: "",
    en: "",
  },
  sortiment_base_category: {
    lv: "Visi produkti",
    ru: "",
    en: "",
  },
  sortiment_filter_height: {
    lv: "Stāda augstums (cm)",
    ru: "",
    en: "",
  },
  sortiment_filter_width: {
    lv: "Stāda platums (cm)",
    ru: "",
    en: "",
  },
  sortiment_filter_price: {
    lv: "Stāda cena (EUR)",
    ru: "",
    en: "",
  },
  sortiment_filter_checkboxes: {
    lv: "Iestatījumi",
    ru: "",
    en: "",
  },
  sortiment_filter_add_all_to_cart: {
    lv: "Pievienot visu atlasītajiem produktiem",
    ru: "",
    en: "",
  },
  sortiment_checkbox_hedge: {
    lv: "Piemērots dzīvžogam",
    ru: "",
    en: "",
  },
  sortiment_checkbox_discount: {
    lv: "Īpašie atlaižu piedāvājumi",
    ru: "",
    en: "",
  },
  sortiment_modal_title: {
    lv: "Produkts",
    ru: "",
    en: "",
  },
  sortiment_modal_no_image: {
    lv: "Nav attēlu",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_from: {
    lv: "No",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_to: {
    lv: "Līdz",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_height: {
    lv: "Augstums:",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_flower_height: {
    lv: "Ziedu augstums:",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_width: {
    lv: "Platums:",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_crown: {
    lv: "Vainags:",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_foliage: {
    lv: "Lapojums:",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_flower: {
    lv: "Ziedi:",
    ru: "",
    en: "",
  },
  sortiment_modal_variable_fruit: {
    lv: "Augļi:",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_light_label: {
    lv: "Gaisma:",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_light_full: {
    lv: "saule",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_light_half: {
    lv: "pusēna",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_light_none: {
    lv: "ēna",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_soil_label: {
    lv: "Augsne:",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_soil_full: {
    lv: "bagāta",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_soil_half: {
    lv: "vidēji bagāta",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_soil_none: {
    lv: "pieticīga",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_humidity_label: {
    lv: "Mitrums:",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_humidity_full: {
    lv: "mitrs/slapjš",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_humidity_half: {
    lv: "vidēji mitrs",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_humidity_none: {
    lv: "sauss",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_city_label: {
    lv: "Pilsētas apstākļiem",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_protected_area_label: {
    lv: "Aizsargātā vietā",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_bee_label: {
    lv: "Bišu augs",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_floristic_label: {
    lv: "Izmanto floristikā",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_rock_label: {
    lv: "Akmensdārziem",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_dec_skin_label: {
    lv: "Miza/dzinumi",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_dec_leaf_label: {
    lv: "Lapojums",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_dec_autumn_label: {
    lv: "Rudens krāsa",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_foliage_evergreen: {
    lv: "Mūžzaļs",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_foliage_aroma: {
    lv: "Aromāts",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_flower_aroma: {
    lv: "Aromāts",
    ru: "",
    en: "",
  },
  sortiment_modal_icon_flower_decorative: {
    lv: "Dekoratīvs",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_title: {
    lv: "Audzētavā pieejami:",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_height: {
    lv: "Augstums, cm",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_width: {
    lv: "Platums, cm",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_package: {
    lv: "Iepakojums",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_code: {
    lv: "Kods",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_price: {
    lv: "Cena (EUR)",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_price_with_discount: {
    lv: "Cena ar klienta karti (EUR)",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_discount: {
    lv: "Atlaide ar klienta karti (%)",
    ru: "",
    en: "",
  },
  sortiment_modal_variants_actions: {
    lv: "Darbības",
    ru: "",
    en: "",
  },
  sortiment_modal_availability_contact: {
    lv: "Sazināties ar mums par pieejamību",
    ru: "",
    en: "",
  },
  sortiment_modal_texts_notes: {
    lv: "Piezīmes:",
    ru: "",
    en: "",
  },
  sortiment_modal_texts_planting: {
    lv: "Stādīšana:",
    ru: "",
    en: "",
  },
  sortiment_modal_texts_care: {
    lv: "Kopšana:",
    ru: "",
    en: "",
  },
  sortiment_modal_table_months_time: {
    lv: "Laiks",
    ru: "",
    en: "",
  },
  sortiment_modal_table_months_blooming: {
    lv: "Ziedēšanas",
    ru: "",
    en: "",
  },
  sortiment_modal_table_months_trimming: {
    lv: "Apgriešanas",
    ru: "",
    en: "",
  },
  sortiment_modal_table_fruit_edable: {
    lv: "Ēdami",
    ru: "",
    en: "",
  },
  sortiment_modal_table_fruit_decorative: {
    lv: "Dekoratīvi",
    ru: "",
    en: "",
  },
  sortiment_modal_table_fruit_poisonous: {
    lv: "Indīgi",
    ru: "",
    en: "",
  },
  sortiment_modal_table_fruit: {
    lv: "Augļi",
    ru: "",
    en: "",
  },
  sortiment_modal_table_hedge: {
    lv: "Dzīvžogs",
    ru: "",
    en: "",
  },
  sortiment_modal_table_hedge_low: {
    lv: "Zems",
    ru: "",
    en: "",
  },
  sortiment_modal_table_hedge_medium: {
    lv: "Vidēji augsts",
    ru: "",
    en: "",
  },
  sortiment_modal_table_hedge_high: {
    lv: "Augsts",
    ru: "",
    en: "",
  },
  sortiment_modal_table_hedge_free_growing: {
    lv: "Brīvi augošs",
    ru: "",
    en: "",
  },
  sortiment_modal_table_hedge_formable: {
    lv: "Fromējams",
    ru: "",
    en: "",
  },
  sortiment_modal_add_to_cart: {
    lv: "Pievienot atlasītajiem produktiem",
    ru: "",
    en: "",
  },
  sortiment_modal_not_available: {
    lv: "Nav pieejams",
    ru: "",
    en: "",
  },
  sortiment_list_info: {
    lv: "Sortimentam ir tikai informatīva nozīme. Stādus pasūtīt var tikai e-veikalā (<a href='https://veikals.baltezers.lv/' target='_blank'>veikals.baltezers.lv</a>).",
    ru: "",
    en: "",
  },
  purchases_title: {
    lv: "Atlasītie produkti",
    ru: "",
    en: "",
  },
  purchases_back_to_sortiment: {
    lv: "Atpakaļ uz sortimentu",
    ru: "",
    en: "",
  },
  purchases_empty_cart: {
    lv: "Atlasītie produktu saraksts ir tukšs",
    ru: "",
    en: "",
  },
  purchases_button_pdf: {
    lv: "Lejupielādēt PDF formātā",
    ru: "",
    en: "",
  },
  purchases_button_excel: {
    lv: "Lejupielādēt Excel formātā",
    ru: "",
    en: "",
  },
  purchases_delete_confirmation: {
    lv: "Vai tiešām vēlaties dzēst?",
    ru: "",
    en: "",
  },
  purchases_delete_all_confirmation: {
    lv: "Vai tiešām vēlaties dzēst visu?",
    ru: "",
    en: "",
  },
  purchases_delete_popup_cancel: {
    lv: "Atcelt",
    ru: "",
    en: "",
  },
  purchases_delete_popup_delete: {
    lv: "Dzēst",
    ru: "",
    en: "",
  },
  purchases_product_label: {
    lv: "Nosaukums",
    ru: "",
    en: "",
  },
  purchases_amount: {
    lv: "Daudzums",
    ru: "",
    en: "",
  },
  purchases_total_amount: {
    lv: "Summa (EUR)",
    ru: "",
    en: "",
  },
  purchases_with_customer_card: {
    lv: "izmantojot klienta karti",
    ru: "",
    en: "",
  },
  purchases_clear_list: {
    lv: "Notīrīt sarakstu",
    ru: "",
    en: "",
  },
  advice_advices: {
    lv: "Padomi",
    ru: "",
    en: "",
  },
  advice_all: {
    lv: "Visi",
    ru: "",
    en: "",
  },
  publication_all: {
    lv: "Visi izdevumi",
    ru: "",
    en: "",
  },
  publication_publication: {
    lv: "Publikācijas",
    ru: "",
    en: "",
  },
  publication_donwload_original: {
    lv: "Skatīt oriģinālizdevumu",
    ru: "",
    en: "",
  },
  publication_date: {
    lv: "Datums",
    ru: "",
    en: "",
  },
  publication_issuer: {
    lv: "Izdevums",
    ru: "",
    en: "",
  },
  about_us_secondary_title: {
    lv: "Kokaudzētava",
    ru: "",
    en: "",
  },
  about_us_primary_title: {
    lv: "Par mums",
    ru: "",
    en: "",
  },
  about_us_description: {
    lv: "<p>”Kokaudzētavas Baltezers” darbība ir stādu pavairošana, audzēšana un tirdzniecība. </p><p>Kokaudzētavas tirdzniecības laukumā, ar platību 8000 kvadrātmetri, tiek piedāvāts viens no plašākajiem augu sortimentiem Latvijā. Uz doto brīdi tiek piedāvāti vairāk kā 600 augu nosaukumu. Ik gadu stādu sortiments tiek papildināts, veicot jaunu šķirņu pārbaudi, atlasi un pavairošanu.</p><p> Dekoratīvo augu atbilstība Latvijas klimatiskajiem apstākļiem un izcilas auga dekoratīvās īpašības, vienmēr ir bijusi kokaudzētavas prioritāte. Lai nodrošinātu iespēju ierīkot stādījumus visu sezonu, t.i., no agra pavasara līdz vēlam rudenim, ”Kokaudzētavas Baltezers” stādi tiek piedāvāti plastmasas konteineros. Tirdzniecības laukumā Jūs apkalpos šajā nozarē zinoši speciālisti. </p><p> Primārais kokaudzētavas darbības mērķis ir izaudzēt augstas kvalitātes stādus, t.i., ar kompaktu sakņu kamolu un standartiem atbilstošu vainagu, kas ļauj stādam labi iesakņoties jaunajā sagatavotajā vietā. </p><p>Kokaudzētavas darba procesā tiek ieviestas jaunākās tehnoloģijas un darba paņēmieni, lai samazinātu stāda pašizmaksu.</p>",
    ru: "",
    en: "",
  },
  about_us_section_history_title: {
    lv: "Vēsture",
    ru: "",
    en: "",
  },
  about_us_section_history_description: {
    lv: "<p>Saimniecība izveidota uz meža kokaudzētavas bāzes, kuras darbība aizsākta jau 1972. gadā Inčukalna MRS sastāvā, kuru tolaik vadīja Helmūts Kazaks (Vara Kazaka tēvs). Kopš 1990. gada kokaudzētava darbojas kā privātuzņēmums - sākotnēji kā Vara Kazaka zemnieku saimniecība ”Sabīņi”, vēlāk - SIA ”Kokaudzētava Baltezers”.</p>",
    ru: "",
    en: "",
  },
  about_us_section_greenery_title: {
    lv: "Apstādījumi",
    ru: "",
    en: "",
  },
  about_us_section_greenery_description: {
    lv: "<p> Pie kokaudzētavas ir izveidoti stādījumi, lai apmeklētāji varētu apskatīt dekoratīvos augus jau pieaugušā izmērā, kā arī smelties idejas augu izvietošanā. Dārzus var apskatīt kokaudzētavas darba laikā bez maksas. Apstādījumu projekta autore Anita Kazaka.</p>",
    ru: "",
    en: "",
  },
  about_us_column_1: {
    lv: "<p>Domājot par cilvēkiem, kas vēlas veidot savu ikdienu skaistāku, tiek audzēti stādi. Tas ir tas mazumiņš, ko mēs varam dot.<br> Uzaust rīts un saule apspīd stādiņus, arī tos jaunos,vēl svešiniekus. Gadiem ritot, tie tiek vēroti, un pārbaudes izturējuši, tiek pavairoti. Jebkuram dārzu entuziastam, vai tasir profesionālis, vai tikai iesācējs, katru gadu tiek piedāvāts kaut kas jauns, ar ko papildināt jau esošos stādījumus, vai iekļaut pilnīgi jaunās dārza kompozīcijās.</p>",
    ru: "",
    en: "",
  },
  about_us_column_2: {
    lv: "<p>Varis Kazaks ar lielu aizrautību, un rūpīgu atlasi, meklē tos skaistākos, izturīgākos, tos, kuros ir kas īpašs. Lai dārznieki, ainavu arhitekti un citi dārzu mākslinieki, kā arī piemājas dārziņu īpašnieki, varētu plānot, projektēt dažādas kompozīcijas. Lai viņu plāniem būtu atbilstošs materiāls, ar bagātīgu krāsu gammu, dažādām vainagu formām, interesantām noskaņām apveltītiem augiem, un galvenais Latvijas klimatam piemēroti augi.</p>",
    ru: "",
    en: "",
  },
  about_us_column_3: {
    lv: "<p>Skaistums, vai tas ir dārzā, vai kādā citā mākslā, ir tā īpašība, pēc kuras mūsu sirdis tiecas visvairāk. Radot skaistas, harmoniskas lietas cilvēki kļūst labestīgāki, sirsnīgāki, vairāk atvērti mīlestībai.<br> Rūpējoties par augiem, par savu apkārtējo vidi, mēs ne tikai veidojam krāšņu savu Latviju, bet gūstam milzīgu prieku, ko sniedz augi, darbs, mīlestība.<br></br> ”Kokaudzētava Baltezers” strādā, lai Jūsu dārzi kļūtu arvien krāšņāki.</p>",
    ru: "",
    en: "",
  },
  about_us_section_moto_titles: {
    lv: "<h2>Skaistākam dārzam</h2><h2>Skaistākai dobei mājas priekšā</h2><h2>Skaistākam parkam</h2><h2>Skaistākai mūsu apkārtējai videi</h2>",
    ru: "",
    en: "",
  },
  custom_page_sub_sections_title: {
    lv: "Apakšsadaļas",
    ru: "",
    en: "",
  },
  partners_main_title: {
    lv: "Mēs sadarbojamies",
    ru: "",
    en: "",
  },
  partners_main_description: {
    lv: "<p>Mūsu klienti ir mazdārziņu un privātmāju īpašnieki, pilsētu labiekārtošanas saimniecības, lielākā daļa Latvijas daiļdārznieku, privātfirmas un valsts iestādes. Mūsu produkciju pazīst Igaunijā, Baltkrievijā, Maskavā, Sanktpēterburgā, mazliet Somijā un Zviedrijā.</p>",
    ru: "",
    en: "",
  },
  partners_showcase: {
    lv: "Mūsu audzētos augus var aplūkot",
    ru: "",
    en: "",
  },
  partners_resell: {
    lv: "Pie mums var iegādāties produkciju no sekojošiem sadarbības partneriem",
    ru: "",
    en: "",
  },
  partners_other: {
    lv: "Citi sadarbības partneri",
    ru: "",
    en: "",
  },
  partners_gardeners: {
    lv: "Dārznieki",
    ru: "",
    en: "",
  },
  gallery: {
    lv: "Galerija",
    ru: "",
    en: "",
  },
  gallery_loading: {
    lv: "Ielādē attēlus...",
    ru: "",
    en: "",
  },
  gallery_empty: {
    lv: "Galerija ir tukša",
    ru: "",
    en: "",
  },
  contacts_form_title1: {
    lv: "Sazinies ar mums",
    ru: "",
    en: "",
  },
  contacts_form_title2: {
    lv: "Rakstīt ziņu",
    ru: "",
    en: "",
  },
  contacts_form_text: {
    lv: "Ziņas teksts (obligāts):",
    ru: "",
    en: "",
  },
  contacts_form_send: {
    lv: "Sūtīt",
    ru: "",
    en: "",
  },
  contacts_requisites_ceo: {
    lv: "Uzņ. vadītājs:",
    ru: "",
    en: "",
  },
  contacts_open_hours: {
    lv: "Darba laiks:",
    ru: "",
    en: "",
  },
  contacts_waze: {
    lv: "Waze norādes",
    ru: "",
    en: "",
  },
  contacts_find_us: {
    lv: "Kā mūs atrast:",
    ru: "",
    en: "",
  },
  contact_success: {
    lv: "Ziņa tika veiksmīgi nosūtīta.",
    ru: "",
    en: "",
  },
  contact_sab_first_part: {
    lv: "Kokaudzētava Baltezers ir",
    ru: "",
    en: "",
  },
  contact_sab_second_part: {
    lv: "Latvijas Stādu audzētāju biedrības",
    ru: "",
    en: "",
  },
  contact_sab_third_part: {
    lv: "biedrs",
    ru: "",
    en: "",
  },
  partners_anchor_1: {
    lv: "Sadarbības partneri",
    ru: "",
    en: "",
  },
  partners_anchor_2: {
    lv: "Citi sadarbības partneri",
    ru: "",
    en: "",
  },
  partners_anchor_3: {
    lv: "Dārznieki",
    ru: "",
    en: "",
  },
  variable_evergreen: {
    lv: "mūžzaļš",
    ru: "",
    en: "",
  },
  variable_aroma: {
    lv: "smaržīgs",
    ru: "",
    en: "",
  },
  variable_decorative: {
    lv: "dekoratīvs",
    ru: "",
    en: "",
  },
  variable_poisonous: {
    lv: "indīgi",
    ru: "",
    en: "",
  },
  fruit_decorative: {
    lv: "dekoratīvi",
    ru: "",
    en: "",
  },
  variable_edible: {
    lv: "ēdami",
    ru: "",
    en: "",
  },
  footer_developers: {
    lv: "Izstrādāja <a href='https://babrisoftware.com/' target='_blank'> SIA Babri Software</a>",
    ru: "Pазработанный <a href='https://babrisoftware.com/' target='_blank'> SIA Babri Software</a>",
    en: "Developed by <a href='https://babrisoftware.com/' target='_blank'> SIA Babri Software</a>",
  },
  consent: {
    lv: "Turpināt",
    ru: "",
    en: "I understand",
  },
  privacy_policy: {
    lv: "Privātuma Politika",
    ru: "",
    en: "Privacy Policy",
  },
  privacy_text: {
    lv: "Informējam, ka mēs izmantojam sīkdatnes jeb 'cookies'. Turpinot lietot šo lapu, Jūs piekrītat, ka mēs uzkrāsim un izmantosim sīkdatnes Jūsu ierīcē.",
    ru: "",
    en: "This website uses cookies to enhance the user experience.",
  },
};
