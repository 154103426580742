import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import ProductModal from "pages/sortiment/components/product_modal/ProductModal";
import "./style.scss";

function PreviewProduct() {
  const { id, hash } = useParams();
  const [show, setShow] = useState(true);
  const [productId, setProductId] = useState(null);

  // This is needed to fetch latest data from API each time modal is opened
  useEffect(() => {
    setProductId(id);
  }, [id]);

  const closeModal = () => {
    setShow(false);
    setProductId(null);
  };

  const openModal = () => {
    setProductId(id);
    setShow(true);
  };

  return (
    <React.Fragment>
      <ProductModal
        show={show}
        onHide={() => closeModal()}
        productID={productId}
        previewHash={hash}
      />
      <Row>
        <Col></Col>
        <Col xl="8" lg="11" md="11">
          <Alert
            variant="secondary"
            className="text-center"
            style={{ marginTop: "100px" }}
          >
            <p>Produkta ID: {id}</p>
            <p>Produkta drošības kods: {hash}</p>
            <span className="link" onClick={() => openModal()}>
              Apskatīt produktu testa režīmā
            </span>
          </Alert>
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

export default PreviewProduct;
