import React from "react";
import { Table, Col } from "react-bootstrap";
import Translation from "translation";
import { ReactComponent as CheckIcon } from "./icons/check.svg";

function FruitTable({ product }) {
  const fruitLiterals = ["edible", "decorative", "poisonous"];

  const isVisible = () => {
    let visible = false;
    fruitLiterals.forEach((name) => {
      if (product["fruit_" + name]) visible = true;
    });
    return visible;
  };

  if (!isVisible()) {
    return null;
  }

  return (
    <Col xs="12" sm="6">
      <Table responsive className="text-center">
        <thead>
          <tr>
            <th></th>
            <th>
              <Translation k="sortiment_modal_table_fruit_edable" />
            </th>
            <th>
              <Translation k="sortiment_modal_table_fruit_decorative" />
            </th>
            <th>
              <Translation k="sortiment_modal_table_fruit_poisonous" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left">
              <Translation k="sortiment_modal_table_fruit" />
            </td>
            {fruitLiterals.map((name, index) => (
              <td key={index}>{product["fruit_" + name] && <CheckIcon />}</td>
            ))}
          </tr>
        </tbody>
      </Table>
    </Col>
  );
}

export default FruitTable;
