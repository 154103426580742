/**
 * Find gallery by id in MPTT tree and return gallery object and array of direct parents to object
 */
const getGalleryById = (galleries, galleryId, prevValues = []) => {
  let values = prevValues;

  for (let gallery of galleries) {
    if (gallery.id === parseInt(galleryId)) {
      values.push(gallery);

      return {
        gallery: gallery,
        tree: values,
      };
    }

    if (gallery.children.length > 0) {
      values.push(gallery);
      const data = getGalleryById(gallery.children, galleryId, values);

      if (data !== null) {
        return {
          gallery: data.gallery,
          tree: data.tree,
        };
      } else {
        values.pop();
      }
    }
  }

  return null;
};

/**
 * Get all current gallery items
 */
const getGalleryChildren = (gallery, hasChildren) => {
  let items = [];
  let data = gallery;

  if (hasChildren) {
    data = gallery.children;
  }

  data.forEach((item) => {
    items.push(item);
  });

  return items;
};

export { getGalleryById, getGalleryChildren };
