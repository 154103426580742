import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import Translation from "translation";
import {
  getDiscountedPrice,
  addOrRemoveVariantOnPurchasesList,
} from "pages/sortiment/utils";
import { ReactComponent as CartIcon } from "./icons/cart.svg";
import { SortimentContext } from "context";
import sha1 from "object-hash";
import ProductVariantRange from "pages/sortiment/components/common/ProductVariantRange";
import { FaRegTimesCircle as NotAvailableIcon } from "react-icons/fa";

function Variants({ product }) {
  return (
    <React.Fragment>
      <Row>
        <Col className="title">
          <Translation k="sortiment_modal_variants_title" />
        </Col>
      </Row>

      <Row className="text-center variantsHeader">
        <Col xs="4" sm="3" md="2" className="d-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_height" />
          </div>
        </Col>
        <Col xs="4" sm="3" md="2" className="d-none d-sm-block">
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_width" />
            </div>
          </div>
        </Col>
        <Col className="d-none d-md-block">
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_package" />
            </div>
          </div>
        </Col>
        <Col className="d-none d-md-block">
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_code" />
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_price" />
            </div>
          </div>
        </Col>
        <Col className="d-none d-md-block">
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_discount" />
            </div>
          </div>
        </Col>
        <Col className="d-none d-sm-block">
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_price_with_discount" />
            </div>
          </div>
        </Col>
        <Col>
          <div className="d-flex h-100">
            <div className="m-auto">
              <Translation k="sortiment_modal_variants_actions" />
            </div>
          </div>
        </Col>
      </Row>

      <Row hidden={product.variants.length > 0}>
        <Col>
          <div className="variantHolder">
            <Translation k="sortiment_modal_availability_contact" />
          </div>
        </Col>
      </Row>

      {product.variants.map((variant) => (
        <Variant key={variant.id} product={product} variant={variant} />
      ))}
    </React.Fragment>
  );
}

const Variant = ({ variant, product }) => {
  const { selectedVariants, setSelectedVariants } = useContext(
    SortimentContext
  );
  const {
    height_from,
    height_to,
    price,
    discount,
    width_from,
    width_to,
  } = variant;

  const onVariantsSelect = (variant) => {
    setSelectedVariants(
      addOrRemoveVariantOnPurchasesList(selectedVariants, product, variant)
    );
  };

  const isSelected = (variant) => {
    return sha1(variant) in selectedVariants;
  };

  return (
    <Row>
      <Col>
        <div
          className={`variantHolder ${isSelected(variant) ? "selected" : ""}`}
        >
          <Row>
            <Col xs="4" sm="3" md="2">
              <ProductVariantRange from={height_from} to={height_to} />
            </Col>
            <Col xs="4" sm="3" md="2" className="d-none d-sm-block">
              <ProductVariantRange from={width_from} to={width_to} />
            </Col>
            <Col className="d-none d-md-block">{variant.package || "-"}</Col>
            <Col className="d-none d-md-block">{variant.code || "-"}</Col>
            <Col>{price || "-"}</Col>
            <Col className="d-none d-md-block">
              {discount ? discount + "%" : "-"}
            </Col>
            <Col className="d-none d-sm-block">
              {discount === 0 ? (
                price
              ) : (
                <strong>{getDiscountedPrice(price, discount)}</strong>
              )}
            </Col>
            <Col>
              {variant.is_available ? (
                <CartIcon
                  onClick={() => onVariantsSelect(variant)}
                  className={`cartButton ${
                    isSelected(variant) ? "selected" : ""
                  }`}
                  title={<Translation k="sortiment_modal_add_to_cart" />}
                />
              ) : (
                <NotAvailableIcon
                  title={<Translation k="sortiment_modal_not_available" />}
                />
              )}
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default Variants;
