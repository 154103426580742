import React from "react";
import { Card } from "react-bootstrap";

const ItemCover = ({ data }) => {
  if (data.cover) {
    return (
      <Card.Img variant="top" style={{ borderRadius: 0 }} src={data.cover} />
    );
  } else return <div className="alert alert-secondary extra-height"></div>;
};

export default ItemCover;
