import React from "react";
import { Table, Col } from "react-bootstrap";
import Translation from "translation";
import { ReactComponent as CheckIcon } from "./icons/check.svg";

function HedgeTable({ product }) {
  const hedgeLiterals = ["small", "medium", "large"];

  const isVisible = () => {
    let visible = false;
    hedgeLiterals.forEach((name) => {
      if (product["hedge_free_" + name] || product["hedge_form_" + name])
        visible = true;
    });
    return visible;
  };

  if (!isVisible()) {
    return null;
  }

  return (
    <Col xs="12" sm="6">
      <Table responsive className="text-center">
        <thead>
          <tr>
            <th>
              <Translation k="sortiment_modal_table_hedge" />
            </th>
            <th>
              <Translation k="sortiment_modal_table_hedge_low" />
            </th>
            <th>
              <Translation k="sortiment_modal_table_hedge_medium" />
            </th>
            <th>
              <Translation k="sortiment_modal_table_hedge_high" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left">
              <Translation k="sortiment_modal_table_hedge_free_growing" />
            </td>
            {hedgeLiterals.map((name, index) => (
              <td key={index}>
                {product["hedge_free_" + name] && <CheckIcon />}
              </td>
            ))}
          </tr>
          <tr>
            <td className="text-left">
              <Translation k="sortiment_modal_table_hedge_formable" />
            </td>
            {hedgeLiterals.map((name, index) => (
              <td key={index}>
                {product["hedge_form_" + name] && <CheckIcon />}
              </td>
            ))}
          </tr>
        </tbody>
      </Table>
    </Col>
  );
}

export default HedgeTable;
