import React from "react";
import { Link } from "react-router-dom";

function Links({ product }) {
  const { links } = product;

  if (links.length === 0) {
    return null;
  }

  return (
    <div>
      {links.map((link) => (
        <Item key={link.id} link={link} />
      ))}
    </div>
  );
}

const Item = ({ link }) => {
  const { name, url } = link;

  return (
    <div className="linkHolder">
      <Link to={url} className="productLink">
        {name}
      </Link>
    </div>
  );
};

export default Links;
