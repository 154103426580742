const customTypesReducer = (state, action) => {
  switch (action.type) {
    case "set":
      return action.payload;
    case "change":
      return state.map((item) => {
        if (item.id === action.payload) {
          return { ...item, selected: !item.selected };
        } else {
          return item;
        }
      });
    case "reset":
      return state.map((item) => {
        return { ...item, selected: true };
      });
    default:
      return state;
  }
};

const subscriberReducer = (state, action) => {
  switch (action.type) {
    case "set_email":
      return { ...state, email: { ...state.email, value: action.payload } };
    case "set_phone":
      return { ...state, phone: { ...state.phone, value: action.payload } };
    case "set_first_name":
      return {
        ...state,
        first_name: { ...state.first_name, value: action.payload },
      };
    case "set_last_name":
      return {
        ...state,
        last_name: { ...state.last_name, value: action.payload },
      };
    case "set_receive_email":
      if (
        !state.receive_types.email === false &&
        state.receive_types.sms === false
      ) {
        return {
          ...state,
          receive_types: { ...state.receive_types, error: true },
        };
      } else {
        return {
          ...state,
          receive_types: {
            ...state.receive_types,
            error: false,
            email: !state.receive_types.email,
          },
        };
      }
    case "set_receive_sms":
      if (
        !state.receive_types.sms === false &&
        state.receive_types.email === false
      ) {
        return {
          ...state,
          receive_types: { ...state.receive_types, error: true },
        };
      } else {
        return {
          ...state,
          receive_types: {
            ...state.receive_types,
            error: false,
            sms: !state.receive_types.sms,
          },
        };
      }
    case "set_email_error":
      return { ...state, email: { ...state.email, errors: action.payload } };
    case "set_phone_error":
      return { ...state, phone: { ...state.phone, errors: action.payload } };
    case "set_first_name_error":
      return {
        ...state,
        first_name: { ...state.first_name, errors: action.payload },
      };
    case "set_last_name_error":
      return {
        ...state,
        last_name: { ...state.last_name, errors: action.payload },
      };
    case "reset_errors":
      return {
        ...state,
        email: { ...state.email, errors: [] },
        phone: { ...state.phone, errors: [] },
        first_name: { ...state.first_name, errors: [] },
        last_name: { ...state.last_name, errors: [] },
        receive_types: { ...state.receive_types, errors: false },
      };
    case "reset":
      return action.payload;
    default:
      return state;
  }
};

export { customTypesReducer, subscriberReducer };
