import React, { useContext, useEffect, useState } from "react";
import Translation from "translation";
import OpenHours from "./components/open_hours/OpenHours";
import NewsBox from "./components/multiuse/NewsBox";
import ContactForm from "./components/contact_form/ContactForm";
import { apiContactsOpenHours } from "api";
import { LanguageContext } from "context";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import BacgroundImg from "./images/bgr_img.jpg";
import WazeImg from "./images/waze_s.png";
import DirectionImg from "./images/direction.jpg";
import config from "config";
import "./style.scss";

function Contacts() {
  const { language } = useContext(LanguageContext);

  const [openHours, setOpenHours] = useState([]);
  const [loadingOpenHours, setLoadingOpenHours] = useState(true);

  useEffect(() => {
    setLoadingOpenHours(true);

    apiContactsOpenHours(language).then((data) => {
      setOpenHours(data);
      setLoadingOpenHours(false);
    });
  }, [language]);
  return (
    <div className="Contacts">
      <Row className="top-section justify-content-center">
        <Col xl="2" className="d-xl-block d-none"></Col>
        <Col
          xl="4"
          md="6"
          lg="5"
          className="order-2 order-sm-2 order-md-1 order-lg-1 order-xl-1"
        >
          <ContactForm lang={language} />
        </Col>
        <Col
          xl="4"
          md="6"
          className="order-1 order-sm-1 order-md-2 order-lg-2 order-xl-2"
        >
          <Row className="requisites-wrap">
            <Col>
              <Row>
                <Col className="d-none d-sm-block d-md-none d-lg-block"></Col>
                <Col sm="9" md="12" lg="10">
                  <Row>
                    <Col>
                      <h2>{config.CONTACTS.contacts_page.company_name}</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <b className="color">
                          <Translation k="giftcard_address" />
                        </b>{" "}
                        {config.CONTACTS.contacts_page.address}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <b className="color">Tel.: </b>
                        <a
                          href={
                            "tel:+371" + config.CONTACTS.contacts_page.phone
                          }
                        >
                          {"(+371) " + config.CONTACTS.contacts_page.phone}
                        </a>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <b className="color">
                          <Translation k="customercard_email" />
                        </b>
                        <a
                          href={"mailto:" + config.CONTACTS.contacts_page.email}
                        >
                          {config.CONTACTS.contacts_page.email}
                        </a>
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <p>
                        <b className="color">
                          <Translation k="contacts_requisites_ceo" />
                        </b>
                        {config.CONTACTS.contacts_page.ceo.name}
                        <a
                          href={
                            "tel:+371" + config.CONTACTS.contacts_page.ceo.phone
                          }
                        >
                          {"(+371) " + config.CONTACTS.contacts_page.ceo.phone}
                        </a>
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col className="d-none d-sm-block d-md-none d-lg-block"></Col>
              </Row>
            </Col>
          </Row>
          <OpenHours data={openHours} ready={!loadingOpenHours} />
        </Col>

        <Col xl="2" className="d-xl-block d-none order-3">
          {openHours.text ? (
            <Row className="news upper align-items-center">
              <NewsBox data={openHours} />
            </Row>
          ) : null}
        </Col>
      </Row>
      <Row className="img-bgr-section">
        <img src={BacgroundImg} alt=""></img>
      </Row>
      <Row className="direction-section">
        <Col></Col>
        <Col sm="10" md="8">
          <Row>
            <Col xs="6" md="8" lg="9" xl="10">
              <h2>
                <Translation k="contacts_find_us" />
              </h2>
            </Col>
            <Col xs="6" md="4" lg="3" xl="2">
              <Link
                to={{
                  pathname:
                    "https://waze.com/ul?ll=57.05895,24.30882&navigate=yes&zoom=17",
                }}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Col className="waze-box d-flex px-1 align-items-center justify-content-center h-100">
                  <img src={WazeImg} alt=""></img>
                  <p>
                    <Translation k="contacts_waze" />
                  </p>
                </Col>
              </Link>
            </Col>
          </Row>
          <Row className="maps">
            <Col lg="6">
              <img src={DirectionImg} alt=""></img>
            </Col>
            <Col lg="6">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3345.94207621331!2d24.30996021121398!3d57.05881109976702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eec988851387eb%3A0xabbc37bd82f4271a!2sSIA%20%22Kokaudz%C4%93tava%20Baltezers%22!5e0!3m2!1sen!2slv!4v1605275534021!5m2!1sen!2slv"
                style={{ border: 0, width: "100%", height: "100%" }}
              ></iframe>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default Contacts;
