import React, { useState, useContext } from "react";
import { Row, Col, Spinner, Alert, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./style.scss";
import routes from "routes";
import Translation from "translation";
import Search from "../search/Search";
import RangeFilter from "./RangeFilter";
import { PRICE_MIN, PRICE_MAX } from "../../config";
import Checkbox from "common/checkbox/Checkbox";
import { SortimentContext } from "context";
import { addMultipleVariantToPurchasesList } from "pages/sortiment/utils";

const ADD_ALL_TO_PURCHASES_LIMIT = 100;

function Filters({
  ready,
  onSearchChange,
  category,
  categoryParentTree,
  onFiltersChange,
  products,
  showFilters,
}) {
  if (!ready) {
    return (
      <Row>
        <Col className="text-center">
          <Alert variant="secondary" className="pt-5 pb-5">
            <Spinner animation="border" variant="secondary" />
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="d-none d-lg-block">
          <Search onSearchChange={(value) => onSearchChange(value)} />
        </Col>
      </Row>
      <Row className="Sortiment-Filters">
        <Col>
          <Row>
            <Col lg="12" sm="6">
              <div className="filterHolder">
                <Categories
                  category={category}
                  categoryParentTree={categoryParentTree}
                />
              </div>
            </Col>
            <Col lg="12" sm="6" hidden={!showFilters}>
              <div className="filterHolder">
                <Checkboxes onCheckboxChange={onFiltersChange} />
              </div>
            </Col>
          </Row>
          <div className="filterHolder" hidden={!showFilters}>
            <Row>
              {/* <Col lg="12">
                <RangeFilter
                  type="height"
                  label={<Translation k="sortiment_filter_height" />}
                  defaultMin={HEIGHT_MIN}
                  defaultMax={HEIGHT_MAX}
                  onRangeChange={onFiltersChange}
                />
              </Col> */}
              {/* <Col lg="12">
                <RangeFilter
                  type="width"
                  label={<Translation k="sortiment_filter_width" />}
                  defaultMin={WIDTH_MIN}
                  defaultMax={WIDTH_MAX}
                  onRangeChange={onFiltersChange}
                />
              </Col> */}
              <Col lg="12">
                <RangeFilter
                  type="price"
                  label={<Translation k="sortiment_filter_price" />}
                  defaultMin={PRICE_MIN}
                  defaultMax={PRICE_MAX}
                  onRangeChange={onFiltersChange}
                  contentClasses={"last"}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <AddAllToPurcahses products={products} />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const Categories = ({ category, categoryParentTree }) => {
  const getParentUrl = () => {
    const tree = categoryParentTree;

    if (tree.length <= 1) {
      return routes.sortiment;
    }

    return (
      routes.sortiment +
      "/" +
      tree[tree.length - 2].id +
      "/" +
      tree[tree.length - 2].slug
    );
  };

  const getSectionTitle = () => {
    if (categoryParentTree.length === 0) {
      return <Translation k="sortiment_base_category" />;
    }

    return (
      <Link to={getParentUrl()}>
        <i className="arrow left"></i>{" "}
        {categoryParentTree[categoryParentTree.length - 1].name}
      </Link>
    );
  };

  return (
    <React.Fragment>
      <div className="label category">{getSectionTitle()}</div>
      <ul className="categories">
        {category.children.map((value) => (
          <li key={value.id}>
            <Link to={routes.sortiment + "/" + value.id + "/" + value.slug}>
              {value.name}
            </Link>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

const Checkboxes = ({ onCheckboxChange }) => {
  const [hedge, setHedge] = useState(false);
  const [discount, setDiscount] = useState(false);

  const valueChange = (type, value) => {
    if (type === "hedge") {
      setHedge(value);
      onCheckboxChange("hedge", value);
    }

    if (type === "discount") {
      setDiscount(value);
      onCheckboxChange("discount", value);
    }
  };

  return (
    <React.Fragment>
      <div className="label">
        <Translation k="sortiment_filter_checkboxes" />
      </div>
      <div className="content last">
        <Checkbox
          name={<Translation k="sortiment_checkbox_hedge" />}
          isChecked={hedge}
          onChange={() => valueChange("hedge", !hedge)}
          uniqueId="hedge"
          variant="dark"
          size="small"
        />
      </div>
      <div className="content last">
        <Checkbox
          name={<Translation k="sortiment_checkbox_discount" />}
          isChecked={discount}
          onChange={() => valueChange("discount", !discount)}
          uniqueId="discount"
          variant="dark"
          size="small"
        />
      </div>
    </React.Fragment>
  );
};

const AddAllToPurcahses = ({ products }) => {
  const [adding, setAdding] = useState(false);
  const { selectedVariants, setSelectedVariants } = useContext(
    SortimentContext
  );
  const history = useHistory();

  const onAddToPurchases = () => {
    setAdding(true);

    setSelectedVariants(
      addMultipleVariantToPurchasesList(selectedVariants, products)
    );

    history.push(routes.sortiment_purchases);
  };

  if (products.length === 0 || products.length > ADD_ALL_TO_PURCHASES_LIMIT) {
    return null;
  }

  return (
    <Button
      variant="custom"
      className="addAllTocartButton w-100"
      onClick={() => onAddToPurchases()}
      disabled={adding}
    >
      {adding && <Spinner animation="border" variant="secondary" size="sm" />}
      {!adding && <Translation k="sortiment_filter_add_all_to_cart" />}
    </Button>
  );
};

export default Filters;
