import React from "react";
import { Col, Row } from "react-bootstrap";

let style = {
  minHeight: "25.5625rem",
  paddingTop: "7rem",
};

function Message() {
  return (
    <Row className="open-hours-wrap justify-content-center" style={style}>
      <Col xs="11">
        <Row>
          <Col xs="1"></Col>
          <Col lg="8" sm="10" className="alert alert-secondary">
            <Row className="justify-content-center">
              <p className="mb-0">Pašlaik informācija vēl nav pievienota.</p>
            </Row>
          </Col>
          <Col lg="1"></Col>
        </Row>
      </Col>
    </Row>
  );
}
export default Message;
