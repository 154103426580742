import React from "react";
import { Row, Col } from "react-bootstrap";
import Translation from "translation";

function Texts({ product }) {
  const { notes_care, notes_planting, notes_text } = product;

  return (
    <React.Fragment>
      <Row hidden={notes_text.length === 0}>
        <Col>
          <div className="title">
            <Translation k="sortiment_modal_texts_notes" />
          </div>
          <div className="text">{notes_text}</div>
        </Col>
      </Row>

      <Row hidden={notes_planting.length === 0}>
        <Col>
          <div className="title">
            <Translation k="sortiment_modal_texts_planting" />
          </div>
          <div className="text">{notes_planting}</div>
        </Col>
      </Row>

      <Row hidden={notes_care.length === 0}>
        <Col>
          <div className="title">
            <Translation k="sortiment_modal_texts_care" />
          </div>
          <div className="text">{notes_care}</div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default Texts;
