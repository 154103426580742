import React from "react";
import { Row, Col } from "react-bootstrap";
import ContentBlockTitleRow from "common/content_blocks/components/common/title/ContentBlockTitleRow";
import ContentBlockImage from "common/content_blocks/components/common/image/ContentBlockImage";
import RichText from "common/richtext/RichText";

function ThreeColumnsBlock({ content }) {
  const {
    primary_title,
    secondary_title,
    image_1,
    image_2,
    image_3,
    text_1,
    text_2,
    text_3,
    thumbnails,
  } = content;

  return (
    <Row>
      <Col>
        <ContentBlockTitleRow
          primary_title={primary_title}
          secondary_title={secondary_title}
        />
        <Row>
          <Col>
            {image_1 && (
              <ContentBlockImage
                image={image_1}
                thumbnails={thumbnails["image_1"]}
              />
            )}
            <RichText text={text_1} />
          </Col>
          <Col>
            {image_2 && (
              <ContentBlockImage
                image={image_2}
                thumbnails={thumbnails["image_2"]}
              />
            )}
            <RichText text={text_2} />
          </Col>
          <Col>
            {image_3 && (
              <ContentBlockImage
                image={image_3}
                thumbnails={thumbnails["image_3"]}
              />
            )}
            <RichText text={text_3} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ThreeColumnsBlock;
