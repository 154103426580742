import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Alert, Spinner, Modal } from "react-bootstrap";
import Translation from "translation";
import "./style.scss";
import { LanguageContext } from "context";
import { apiGalleries, apiGalleryImages } from "api";
import { useParams, Link } from "react-router-dom";
import { getGalleryChildren, getGalleryById } from "./utils";
import routes from "routes";
import {
  FaImage as NoImageIcon,
  FaPlay as VideoPlayIcon,
  FaFolder as FolderIcon,
} from "react-icons/fa";
import YouTube from "react-youtube";
import PlaceHolderImage from "./images/placeholder.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const MODAL_OPTS = {
  height: "450",
  width: "100%",
  playerVars: {
    autoplay: 1,
  },
};

function Gallery() {
  const { galleryId } = useParams();
  const { language } = useContext(LanguageContext);

  const [loading, setLoading] = useState(true);
  const [galleries, setGalleries] = useState([]);

  const [galleryChildren, setGalleryChildren] = useState([]);
  const [parentTree, setParentTree] = useState([]);

  useEffect(() => {
    setLoading(true);

    apiGalleries(language).then((response) => {
      setGalleries(response);
      setLoading(false);
    });
  }, [language]);

  useEffect(() => {
    if (galleries.length === 0) {
      return;
    }

    if (!galleryId) {
      setGalleryChildren(getGalleryChildren(galleries, false));
      setParentTree([]);
      return;
    }

    const data = getGalleryById(galleries, galleryId);

    if (data) {
      setGalleryChildren(getGalleryChildren(data.gallery, true));
      setParentTree(data.tree);
    }
  }, [galleryId, galleries]);

  return (
    <Row className="Gallery">
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Row>
          <Col className="header">
            <Header sections={parentTree} />
          </Col>
        </Row>
        <Row>
          <Col>
            <GalleryItems ready={!loading} subGalleries={galleryChildren} />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const Header = ({ sections }) => {
  return (
    <div>
      <Link to={routes.gallery}>
        <Translation k="gallery" />
      </Link>
      {sections.map((item) => (
        <span key={item.id}>
          <i className="arrow right"></i>{" "}
          <Link to={routes.gallery + "/" + item.id + "/" + item.slug}>
            {item.name}
          </Link>
        </span>
      ))}
    </div>
  );
};

const GalleryItems = ({ ready, subGalleries }) => {
  const { galleryId } = useParams();
  const { language } = useContext(LanguageContext);

  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [showNoImage, setShowNoImage] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalImageKey, setModalImageKey] = useState(0);

  useEffect(() => {
    let isSubscribed = true;

    setLoading(true);
    setShowNoImage(false);
    setImages([]);
    setVideos([]);

    if (!galleryId) {
      setLoading(false);
      return;
    }

    apiGalleryImages(language, galleryId).then((data) => {
      if (!isSubscribed) {
        return;
      }

      const { folder, videos } = data;
      const images = folder ? folder.files : [];

      setImages(images);
      setVideos(videos);
      setShowNoImage(videos.length === 0 && images.length === 0);
      setLoading(false);
    });

    return () => (isSubscribed = false);
  }, [language, galleryId]);

  useEffect(() => {
    setModalData(images[modalImageKey] || null);
  }, [images, modalImageKey]);

  const openModalImage = (image, key) => {
    setShowModal(true);
    setModalData(image);
    setModalImageKey(key);
  };

  const setNextImage = () => {
    if (modalImageKey === images.length - 1) {
      setModalImageKey(0);
    } else {
      setModalImageKey(modalImageKey + 1);
    }
  };

  const setPrevImage = () => {
    if (modalImageKey === 0) {
      setModalImageKey(images.length - 1);
    } else {
      setModalImageKey(modalImageKey - 1);
    }
  };

  if (!ready) {
    return (
      <Alert variant="secondary" className="text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  return (
    <Row>
      {subGalleries.map((item) => (
        <ItemHolder key={item.id}>
          <SubCategoryItem data={item} />
        </ItemHolder>
      ))}

      {loading && (
        <ItemHolder>
          <PlaceholderHolder>
            <Spinner
              animation="border"
              variant="secondary"
              className="m-auto"
            />
          </PlaceholderHolder>
          <Name name={<Translation k="gallery_loading" />} />
        </ItemHolder>
      )}

      {showNoImage && subGalleries.length === 0 && (
        <Col>
          <Alert variant="secondary" className="text-center">
            <Translation k="gallery_empty" />
          </Alert>
        </Col>
      )}

      {videos.map((item) => (
        <ItemHolder key={item.id}>
          <VideoItem data={item} />
        </ItemHolder>
      ))}

      {images.map((item, i) => (
        <ItemHolder key={item.id} show={item.thumbnails !== null}>
          <ImageItem data={item} onShowModal={() => openModalImage(item, i)} />
        </ItemHolder>
      ))}

      <ImageModal
        show={showModal}
        data={modalData}
        onShowChange={() => setShowModal(false)}
        onPrevious={() => setPrevImage()}
        onNext={() => setNextImage()}
      />
    </Row>
  );
};

const ItemHolder = (props) => {
  if ("show" in props && !props.show) {
    return null;
  }

  return (
    <Col className="item mb-4" md="3" xs="6">
      {props.children}
    </Col>
  );
};

const PlaceholderHolder = (props) => {
  return (
    <div className="position-relative">
      <img src={PlaceHolderImage} alt="" />
      <div className="position-absolute iconHolder">{props.children}</div>
    </div>
  );
};

const Image = ({ image, thumbnails, icon = null }) => {
  if (!image) {
    return (
      <PlaceholderHolder>
        <NoImageIcon className="icon" />
      </PlaceholderHolder>
    );
  }

  return (
    <div className="position-relative">
      <img src={PlaceHolderImage} alt="" />
      <div className="position-absolute w-100 lazyImage">
        <LazyLoadImage
          src={thumbnails.list}
          alt={image.default_alt_text}
          effect="opacity"
          wrapperClassName="w-100"
        />
      </div>
      <div className="position-absolute iconHolder">{icon}</div>
    </div>
  );
};

const Name = ({ name }) => {
  return <div className="name pt-2">{name}</div>;
};

const SubCategoryItem = ({ data }) => {
  const { id, slug, image, thumbnails, name } = data;

  return (
    <Link to={routes.gallery + "/" + id + "/" + slug} className="link">
      <Image
        image={image}
        thumbnails={thumbnails}
        icon={<FolderIcon className="icon" />}
      />
      <Name name={name} />
    </Link>
  );
};

const ImageModal = ({ show, data, onShowChange, onPrevious, onNext }) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [load, setLoad] = useState(true);

  const ICON_STYLE = {
    width: "30px",
    height: "30px",
  };

  const nextImage = () => {
    setLoad(false);
    setShowPlaceholder(true);
    onNext();
  };

  const prevImage = () => {
    setLoad(false);
    setShowPlaceholder(true);
    onPrevious();
  };

  useEffect(() => {
    setLoad(true);

    // Make sure to close modal if data is missing
    if (data == null) {
      onShowChange();
    }
  }, [data, onShowChange]);

  useEffect(() => {
    setShowPlaceholder(true);
  }, [show]);

  if (!show || !data) {
    return null;
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onShowChange}
      className="gallery-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{data.default_alt_text}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="position-relative w-100">
          <div
            hidden={!showPlaceholder}
            className="position-absolute text-center w-100 imageLoader"
          >
            <Spinner animation="border" variant="secondary" size="sm" />
          </div>
          <div className="galleryLazyImage">
            {load && (
              <LazyLoadImage
                src={data.thumbnails.carousel}
                alt={data.default_alt_text}
                effect="opacity"
                wrapperClassName="w-100"
                afterLoad={() => setShowPlaceholder(false)}
              />
            )}
          </div>

          <a
            class="carousel-control-prev"
            role="button"
            href="#prev"
            onClick={prevImage}
          >
            <span
              aria-hidden="true"
              class="carousel-control-prev-icon"
              style={ICON_STYLE}
            ></span>
          </a>

          <a
            class="carousel-control-next"
            role="button"
            href="#next"
            onClick={nextImage}
          >
            <span
              aria-hidden="true"
              class="carousel-control-next-icon"
              style={ICON_STYLE}
            ></span>
          </a>
        </div>
      </Modal.Body>
      {data.description ? (
        <Modal.Footer>{data.description}</Modal.Footer>
      ) : null}
    </Modal>
  );
};

const ImageItem = ({ data, onShowModal }) => {
  const { default_alt_text, thumbnails } = data;

  return (
    <React.Fragment>
      <span onClick={onShowModal} className="link">
        <Image image={data} thumbnails={thumbnails} />
        <Name name={default_alt_text} />
      </span>
    </React.Fragment>
  );
};

const YouTubeModal = ({ show, youtubeId, name, onShowChange }) => {
  if (!show) {
    return null;
  }

  return (
    <Modal size="lg" show={show} onHide={onShowChange}>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <YouTube videoId={youtubeId} opts={MODAL_OPTS} />
      </Modal.Body>
    </Modal>
  );
};

const VideoItem = ({ data }) => {
  const { name, youtube_id, image, thumbnails } = data;
  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <YouTubeModal
        show={show}
        youtubeId={youtube_id}
        name={name}
        onShowChange={changeShow}
      />
      <span onClick={changeShow} className="link">
        <Image
          image={image}
          thumbnails={thumbnails}
          icon={<VideoPlayIcon className="icon" />}
        />
        <Name name={name} />
      </span>
    </React.Fragment>
  );
};

export default Gallery;
