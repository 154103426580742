import React, { useContext, useState } from "react";
import {
  Row,
  Col,
  Alert,
  InputGroup,
  FormControl,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { SortimentContext, LanguageContext } from "context";
import "./style.scss";
import Translation from "translation";
import config from "config";
import routes from "routes";
import { Link } from "react-router-dom";
import { getProductTitles, getDiscountedPrice } from "pages/sortiment/utils";
import ProductVariantRange from "pages/sortiment/components/common/ProductVariantRange";
import { FaTrashAlt as RemoveIcon } from "react-icons/fa";
import {
  getSummedVariantsPrices,
  getVariantIDsWithCount,
  downloadFile,
  formatObjectToList,
} from "./utils";
import { apiPurchasesGenerateExcel } from "api";
import ProductModal from "pages/sortiment/components/product_modal/ProductModal";

const EXCEL_FILE_NAME = "purchases.xlsx";

function SortimentPurchases() {
  const { selectedVariants } = useContext(SortimentContext);

  return (
    <Row className="Sortiment-Purchases">
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Row className="headerContent">
          <Col xs="12" md="6" className="d-flex">
            <div className="m-auto w-100 backLinkHolder">
              <Link to={routes.sortiment} className="backLink">
                <i className="arrow left"></i>
                <Translation k="purchases_back_to_sortiment" />
              </Link>
            </div>
          </Col>
          {/* <Col xs="6" md="3">
            <PDFButton />
          </Col> */}
          <Col xs="12" md={{ offset: 3, span: 3 }}>
            <ExcelButton variants={formatObjectToList(selectedVariants)} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="listHolder">
              <Row>
                <Col className="d-none d-lg-block"></Col>
                <Col className="title d-flex">
                  <div className="text-center m-auto">
                    <Translation k="purchases_title" />
                  </div>
                </Col>
                <Col className="text-right d-none d-sm-block info">
                  <p>
                    <strong>{config.CONTACTS.footer.phone}</strong>
                    <br />
                    <strong>{config.CONTACTS.footer.email}</strong>
                  </p>
                  <p className="mb-0">
                    {config.CONTACTS.footer.company_name}
                    <br />
                    {config.CONTACTS.footer.address}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col className="variantsHolder">
                  <Variants variants={formatObjectToList(selectedVariants)} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Pricing variants={formatObjectToList(selectedVariants)} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const Pricing = ({ variants }) => {
  const { total, totalWithDiscount } = getSummedVariantsPrices(variants);
  const vatPrice =
    (total / ((100 + config.VAT_PERCENT) / 100)) * (config.VAT_PERCENT / 100);
  const priceWithoutVat = Number((total - vatPrice).toFixed(2));

  if (variants.length === 0) {
    return null;
  }

  return (
    <Row className="pricingHolder">
      <Col lg={{ offset: 5, span: 4 }} md={{ offset: 5, span: 4 }} xs="12">
        <Row>
          <Col className="text-right" xs="8" sm="9">
            <strong className="label">
              <Translation k="giftcard_total" />
            </strong>
          </Col>
          <Col xs="4" sm="3">
            <span className="value">{priceWithoutVat}</span>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="8" sm="9">
            <strong className="label">
              <Translation k="giftcard_vat" />
            </strong>
          </Col>
          <Col xs="4" sm="3">
            <span className="value">{config.VAT_PERCENT}%</span>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2 mb-2" xs={{ offset: 6 }}>
            <div className="divider"></div>
          </Col>
        </Row>
        <Row>
          <Col className="text-right" xs="8" sm="9">
            <span className="value">
              <Translation k="purchases_total_amount" />
            </span>
          </Col>
          <Col xs="4" sm="3">
            <strong className="price">{total}</strong>
          </Col>
        </Row>
        <Row className="pt-2" hidden={total === totalWithDiscount}>
          <Col className="text-right" xs="8" sm="9">
            <span className="value">
              <Translation k="purchases_total_amount" />
              <br />
              <span className="discounted">
                <Translation k="purchases_with_customer_card" />
              </span>
            </span>
          </Col>
          <Col xs="4" sm="3" className="d-flex">
            <div className="m-auto w-100">
              <strong className="price discounted">{totalWithDiscount}</strong>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const Variants = ({ variants }) => {
  const { selectedVariants, setSelectedVariants } = useContext(
    SortimentContext
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletableItemHash, setDeletableItemHash] = useState(null);
  const [showProductModal, setShowProductModal] = useState(false);
  const [productId, setProductId] = useState(null);

  const onDeleteShow = (hash) => {
    setDeletableItemHash(hash);
    setShowDeleteModal(true);
  };

  const onDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const onDelete = () => {
    let newData = Object.assign({}, selectedVariants);
    delete newData[deletableItemHash];
    setSelectedVariants(newData);
    setShowDeleteModal(false);
  };

  const openProductInModal = (productId) => {
    setProductId(productId);
    setShowProductModal(true);
  };

  if (variants.length === 0) {
    return (
      <Alert variant="secondary" className="text-center mb-0">
        <Translation k="purchases_empty_cart" />
      </Alert>
    );
  }

  return (
    <React.Fragment>
      <ProductModal
        show={showProductModal}
        onHide={() => setShowProductModal(false)}
        productID={productId}
      />

      <Modal
        size="md"
        show={showDeleteModal}
        onHide={onDeleteCancel}
        dialogClassName="variantDeleteModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translation k="purchases_delete_popup_delete" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="title text-center">
              <Translation k="purchases_delete_confirmation" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="custom"
                onClick={onDeleteCancel}
                className="w-100 buttonCancel"
              >
                <Translation k="purchases_delete_popup_cancel" />
              </Button>
            </Col>
            <Col>
              <Button
                variant="custom"
                onClick={onDelete}
                className="w-100 buttonDelete"
              >
                <Translation k="purchases_delete_popup_delete" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <DeleteAllRow />

      <Row className="header">
        <Col xs="8" md="4" lg="2" className="d-flex">
          <div className="m-auto text-left w-100 pl-3">
            <Translation k="purchases_product_label" />
          </div>
        </Col>
        <Col md="2" lg="1" className="d-none d-md-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_height" />
          </div>
        </Col>
        <Col lg="1" className="d-none d-lg-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_width" />
          </div>
        </Col>
        <Col lg="1" className="d-none d-lg-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_package" />
          </div>
        </Col>
        <Col lg="1" className="d-none d-lg-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_code" />
          </div>
        </Col>
        <Col md="1" lg="1" className="d-none d-md-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_price" />
          </div>
        </Col>
        <Col md="1" lg="1" className="d-none d-md-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_discount" />
          </div>
        </Col>
        <Col md="1" lg="1" className="d-none d-md-flex">
          <div className="m-auto">
            <Translation k="sortiment_modal_variants_price_with_discount" />
          </div>
        </Col>
        <Col md="2" lg="2" className="d-none d-md-flex">
          <div className="m-auto">
            <Translation k="purchases_amount" />
          </div>
        </Col>
        <Col xs="4" md="1" lg="1" className="d-flex">
          <div className="m-auto">
            <Translation k="purchases_delete_popup_delete" />
          </div>
        </Col>
      </Row>

      {variants.map((variant) => (
        <Variant
          key={variant[0]}
          hash={variant[0]}
          variant={variant[1]}
          onDelete={(hash) => onDeleteShow(hash)}
          onProductOpen={(productId) => openProductInModal(productId)}
        />
      ))}
    </React.Fragment>
  );
};

const Variant = ({ hash, variant, onDelete, onProductOpen }) => {
  const { product, data, count } = variant;
  const { selectedVariants, setSelectedVariants } = useContext(
    SortimentContext
  );
  const { primaryTitle, secondaryTitle } = getProductTitles(product);
  const [localCount, setLocalCount] = useState(count);

  const onCountChange = (value) => {
    let newValue = value;

    if (newValue.length === 0) {
      newValue = 1;
    } else {
      newValue = parseInt(value);
    }

    setLocalCount(newValue);

    if (newValue <= 0) {
      onDelete(hash);
      setLocalCount(1);
    } else {
      let newData = Object.assign({}, selectedVariants);
      newData[hash].count = newValue;
      setSelectedVariants(newData);
    }
  };

  return (
    <Row className="text-center data">
      <Col>
        <div className="dataHolder">
          <Row>
            <Col xs="12" md="4" lg="2" className="d-flex text-left">
              <div className="m-auto w-100 item pl-2 pr-2">
                <div
                  className="primaryTitle"
                  onClick={() => onProductOpen(variant.product.id)}
                >
                  {primaryTitle}
                </div>
                <div className="secondaryTitle">{secondaryTitle}</div>
              </div>
            </Col>
            <Col md="1" lg="1" className="d-flex d-md-none">
              <div className="m-auto w-100 text-left item pl-2 mobile">
                <Translation k="sortiment_modal_variants_height" />:{" "}
                <ProductVariantRange
                  from={data.height_from}
                  to={data.height_to}
                />
                <br />
                <Translation k="sortiment_modal_variants_width" />:{" "}
                <ProductVariantRange
                  from={data.width_from}
                  to={data.width_to}
                />
                <br />
                {data.price} EUR
              </div>
            </Col>
            <Col md="2" lg="1" className="d-none d-md-flex">
              <div className="m-auto item">
                <ProductVariantRange
                  from={data.height_from}
                  to={data.height_to}
                />
              </div>
            </Col>
            <Col lg="1" className="d-none d-lg-flex">
              <div className="m-auto item">
                <ProductVariantRange
                  from={data.width_from}
                  to={data.width_to}
                />
              </div>
            </Col>
            <Col lg="1" className="d-none d-lg-flex">
              <div className="m-auto item">{data.package || "-"}</div>
            </Col>
            <Col lg="1" className="d-none d-lg-flex">
              <div className="m-auto item">{data.code || "-"}</div>
            </Col>
            <Col md="1" lg="1" className="d-none d-md-flex">
              <div className="m-auto item">{data.price}</div>
            </Col>
            <Col className="d-none d-md-flex">
              <div className="m-auto item">
                {data.discount ? data.discount + "%" : "-"}
              </div>
            </Col>
            <Col md="1" lg="1" className="d-none d-md-flex">
              <div className="m-auto item discountedPrice">
                {data.discount === 0 ? (
                  data.price
                ) : (
                  <strong>
                    {getDiscountedPrice(data.price, data.discount)}
                  </strong>
                )}
              </div>
            </Col>
            <Col xs="6" md="2" lg="2" className="d-flex pr-0">
              <div className="m-auto w-100 item">
                <InputGroup className="incrementHolder">
                  <InputGroup.Prepend>
                    <Button
                      variant="outline-secondary"
                      onClick={() => onCountChange(localCount - 1)}
                    >
                      -
                    </Button>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    value={localCount}
                    onChange={(e) => setLocalCount(e.target.value)}
                    onBlur={() => onCountChange(localCount)}
                    className="text-center"
                  />
                  <InputGroup.Append>
                    <Button
                      variant="outline-secondary"
                      onClick={() => onCountChange(localCount + 1)}
                    >
                      +
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </Col>
            <Col
              xs={{ offset: 2, span: 4 }}
              md={{ offset: 0, span: 1 }}
              lg={{ offset: 0, span: 1 }}
              className="d-flex"
            >
              <div className="m-auto item w-100 pr-1">
                <button
                  className="btn removeButton"
                  onClick={() => onDelete(hash)}
                >
                  <RemoveIcon />
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const ExcelButton = ({ variants }) => {
  const { language } = useContext(LanguageContext);
  const [loading, setLoading] = useState(false);

  const downloadExcel = () => {
    const data = new URLSearchParams();
    data.append("variants", JSON.stringify(getVariantIDsWithCount(variants)));

    setLoading(true);

    apiPurchasesGenerateExcel(data, language).then(
      (response) => {
        downloadFile(response.data, EXCEL_FILE_NAME);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  if (variants.length === 0) {
    return null;
  }

  return (
    <button
      className="btn downloadButton"
      onClick={() => downloadExcel()}
      disabled={loading}
    >
      {loading && <Spinner size="sm" animation="border" variant="light" />}
      {!loading && <Translation k="purchases_button_excel" />}
    </button>
  );
};

// eslint-disable-next-line
const PDFButton = ({ variants }) => {
  const downloadPDF = () => {};

  return (
    <button className="btn downloadButton" onClick={() => downloadPDF()}>
      <Translation k="purchases_button_pdf" />
    </button>
  );
};

const DeleteAllRow = () => {
  const [showModal, setShowModal] = useState(false);
  const { setSelectedVariants } = useContext(SortimentContext);

  const onDelete = () => {
    setSelectedVariants([]);
  };

  return (
    <React.Fragment>
      <Modal
        size="md"
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="variantDeleteModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Translation k="purchases_delete_popup_delete" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="title text-center">
              <Translation k="purchases_delete_all_confirmation" />
            </Col>
          </Row>

          <Row>
            <Col>
              <Button
                variant="custom"
                onClick={() => setShowModal(false)}
                className="w-100 buttonCancel"
              >
                <Translation k="purchases_delete_popup_cancel" />
              </Button>
            </Col>
            <Col>
              <Button
                variant="custom"
                onClick={onDelete}
                className="w-100 buttonDelete"
              >
                <Translation k="purchases_delete_popup_delete" />
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Row className="header pb-3 pb-md-0">
        <Col
          xs="12"
          sm={{ span: 6, offset: 6 }}
          md={{ span: 3, offset: 9 }}
          lg={{ span: 3, offset: 9 }}
          className="text-center"
        >
          <Button
            variant="custom"
            className="w-100 removeAllButton"
            onClick={() => setShowModal(true)}
          >
            <Translation k="purchases_clear_list" />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SortimentPurchases;
