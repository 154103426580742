import React from "react";
import { getProductDimensionPrefixLocale } from "pages/sortiment/utils";

const ProductVariantRange = ({ from, to }) => {
  const hasMin = from.length > 0;
  const hasMax = to.length > 0;
  const showHeightSeparator =
    (hasMin && hasMax) || (hasMin === false && hasMax === false) ? true : false;

  return (
    <React.Fragment>
      <span className="prefix">
        {getProductDimensionPrefixLocale(hasMin, hasMax)}
      </span>{" "}
      {from} {showHeightSeparator && "-"} {to}
    </React.Fragment>
  );
};

export default ProductVariantRange;
