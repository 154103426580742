import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import CookieConsent from "react-cookie-consent";
import Translation from "translation";
import config from "config";
import "./styles.scss";

function Consent() {
  let consent = <Translation k="consent" />;

  return (
    <Row
      style={{
        background: "#506350",
      }}
    >
      <Col></Col>
      <Col lg="11" xl="8" className="p-lg-0">
        <CookieConsent
          debug={process.env.NODE_ENV === "development"}
          buttonText={consent}
          location="top"
          cookieName="Cookie consent"
          buttonClasses="consent-button"
          contentClasses="consent-wrap"
          buttonWrapperClasses="button-wrapper"
          ariaAcceptLabel="Accept cookies"
          style={{
            position: "relative",
            background: "#506350",
            paddingTop: "1rem",
            paddingBottom: "1rem",
          }}
        >
          <Translation k="privacy_text" />{" "}
          <Link className="privacy-policy-link" to={config.PRIVACY_POLICY_LINK}>
            <Translation k="privacy_policy" />
          </Link>
        </CookieConsent>
      </Col>
      <Col></Col>
    </Row>
  );
}

export default Consent;
