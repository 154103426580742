import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import "./style.scss";
import { LanguageContext } from "context";
import { apiHomeBanner } from "api";
import { Link } from "react-router-dom";
import { ReactComponent as PercentIcon } from "./icons/percent.svg";
import CardImage from "./images/card.png";
import routes from "routes";
import Translation from "translation";

function Sections() {
  const { language } = useContext(LanguageContext);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  useEffect(() => {
    let isSubscribed = true;

    setIsLoaded(false);

    apiHomeBanner(language).then(
      (response) => {
        if (!isSubscribed) {
          return;
        }

        setItems(response);
        setIsLoaded(true);
      },
      (error) => {}
    );

    return () => (isSubscribed = false);
  }, [language]);

  return (
    <React.Fragment>
      <Row className="Home-Section">
        <Col>
          <Row className="inspiringQuote">
            <Col></Col>
            <Col xl="4" lg="5" md="8" className="text-center">
              <div className="title">
                <Translation k="landing_pay_attention" />
              </div>
              <div className="text">
                <Translation k="landing_pay_attention_text" />
              </div>
              <span className="divider"></span>
            </Col>
            <Col></Col>
          </Row>

          <Row className="cardBoxes">
            <Col></Col>
            <Col xl="4" lg="5" md="5">
              <Banner ready={isLoaded} data={items} />
            </Col>
            <Col
              xl="4"
              lg="5"
              md="5"
              className="pt-0 pt-4 pt-sm-4 pt-md-0 pt-lg-0 pt-xl-0"
            >
              <Link to={routes.customer_card}>
                <div className="cardContent customer">
                  <div className="iconHolder d-flex">
                    <PercentIcon className="icon" />
                  </div>
                  <div className="textHolder d-flex">
                    <div className="content w-100">
                      <span className="title">
                        <Translation k="landing_customer_card_title" />
                      </span>
                      <br />
                      <span className="button">
                        <Translation k="landing_customer_card_button" />
                        <i className="arrow right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>

              <Link to={routes.gift_card}>
                <div className="cardContent gift">
                  <div className="iconHolder d-flex">
                    <img src={CardImage} alt="" className="icon" />
                  </div>
                  <div className="textHolder d-flex">
                    <div className="content w-100">
                      <span className="title">
                        <Translation k="landing_gift_card_title" />
                      </span>
                      <br />
                      <span className="button">
                        <Translation k="landing_gift_card_button" />
                        <i className="arrow right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col></Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
}

const Banner = ({ ready, data }) => {
  const { button_url, thumbnails, title, button_name } = data;

  if (ready) {
    return (
      <Link to={button_url} className="bannerLink">
        <div
          className="bannerCard"
          style={{ backgroundImage: "url(" + thumbnails.list + ")" }}
        >
          <div className="content">
            <div className="title">{title}</div>

            <div className="button">
              {button_name}
              <i className="arrow right"></i>
            </div>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div className="bannerCard d-flex">
      <div className="loading">
        <Spinner animation="border" variant="secondary" />
      </div>
    </div>
  );
};

export default Sections;
