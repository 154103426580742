import React, { useContext } from "react";
import { LanguageContext, LocalesContext } from "context";
import translations from "./translations";
import { FormControl } from "react-bootstrap";
import config from "config";

const _getTranslation = (key) => {
  const { language } = useContext(LanguageContext);
  const { locales } = useContext(LocalesContext);

  // Search for translation on API translated values
  if (key in locales) {
    let value = locales[key][language];

    // Reset to default if value wasn't found by language key
    if (!value) {
      value = locales[key][config.DEFAULT_LANGAUGE];
    }

    if (value.length > 0) {
      return value;
    }
  }

  // Check if the key exists in the local translation file
  if (!(key in translations)) {
    return `[ERROR NONE EXISTING TRANSLATION, key: "${key}" language: "${language}"]`;
  }

  // Search for translation locally if it's missing on API
  const value = translations[key][language];

  // Check if for some reason value doesn't exist (for example language contains invalid value)
  if (value === undefined) {
    return translations[key][config.DEFAULT_LANGAUGE];
  }

  const text =
    value.length > 0 ? value : translations[key][config.DEFAULT_LANGAUGE];

  return text.length > 0 ? text : '[MISSING TRANSLATION, key: "' + key + '"]';
};

const Translation = ({ k }) => {
  return _getTranslation(k);
};

const TranslationImage = ({ k, src, variant = "" }) => {
  return <img src={src} alt={_getTranslation(k)} className={variant} />;
};

const TranslationHTML = ({ k }) => {
  return <span dangerouslySetInnerHTML={{ __html: _getTranslation(k) }}></span>;
};

const TranslationOption = ({ k, value }) => {
  return <option value={value}>{_getTranslation(k)}</option>;
};

const TranslationFormControl = ({ placeholder_k, onChange }) => {
  return (
    <FormControl
      placeholder={_getTranslation(placeholder_k)}
      onChange={onChange}
    />
  );
};

const TranslationCombine = ({ k1, k2, seperator = " " }) => {
  return _getTranslation(k1) + seperator + _getTranslation(k2);
};

export {
  Translation,
  TranslationImage,
  TranslationHTML,
  TranslationOption,
  TranslationFormControl,
  TranslationCombine,
};
