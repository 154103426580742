const pageNavigationHistoryReducer = (state, action) => {
  let data = [...state];

  switch (action.type) {
    case "add":
      data.push(action.payload);
      return data;
    case "remove":
      data.pop();
      return data;
    default:
      return state;
  }
};

export { pageNavigationHistoryReducer };
