import React from "react";
import { NavLink } from "react-router-dom";
import "./styles/style.scss";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as NavigationArrowDownIcon } from "./icons/navigation_dropdown_arrow_down.svg";
import { ReactComponent as ShopArrowRight } from "./icons/shop_arrow_right.svg";
import { LinkContainer } from "react-router-bootstrap";
import Translation from "translation";

function NavigationButton(props) {
  const { translation_key, type, url, children } = props.data;
  const button_name = <Translation k={translation_key} />;

  if (type === "dropdown") {
    const dropdownItems = children.map((value, index) => (
      <LinkContainer key={index} to={value.url}>
        <Dropdown.Item className="dropdownItem">
          <Translation k={value.translation_key} />
        </Dropdown.Item>
      </LinkContainer>
    ));

    return (
      <li>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle}>{button_name}</Dropdown.Toggle>

          <Dropdown.Menu alignRight>{dropdownItems}</Dropdown.Menu>
        </Dropdown>
      </li>
    );
  }

  if (type === "shop") {
    return (
      <li>
        <a
          href={url}
          target="_blank"
          className="shopButton"
          rel="noopener noreferrer"
        >
          {button_name} <ShopArrowRight />
        </a>
      </li>
    );
  }

  if (type === "basic") {
    return (
      <li>
        <NavLink exact={url === "/"} to={url} activeClassName="active">
          {button_name}
        </NavLink>
      </li>
    );
  }

  return null;
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="navigationDropdown"
  >
    {children}{" "}
    <NavigationArrowDownIcon className="navigationDropdownArrowDown" />
  </div>
));

export default NavigationButton;
