import React from "react";
import { Row, Col } from "react-bootstrap";

const Item = ({ data }) => {
  if (data.logo) {
    return (
      <Col>
        <Row
          className="justify-content-center item-img py-3 align-items-center"
          style={{ minHeight: "100px" }}
        >
          <img src={data.logo} alt=""></img>
        </Row>
      </Col>
    );
  } else return <div className="alert alert-secondary extra-height"></div>;
};

export default Item;
