import React from "react";
import { Row, Col, Spinner, Alert } from "react-bootstrap";
import "./style.scss";
import Translation, { TranslationHTML } from "translation";
import ReactPaginate from "react-paginate";
import {
  getLargestAndSmallestVariantPrice,
  getProductTitles,
} from "pages/sortiment/utils";
import config from "config";
import ProductModal from "../product_modal/ProductModal";
import { Link, useHistory } from "react-router-dom";
import routes from "routes";
import { useParams } from "react-router-dom";
import RichText from "common/richtext/RichText";

function ProductList({
  ready,
  products,
  currentPage,
  pageCount,
  setCurrentPage,
  category,
}) {
  const { categoryId, categorySlug, productId } = useParams();
  const history = useHistory();

  const showModal = () => {
    if (productId) return true;
  };

  const hideModal = () => {
    const catID = categoryId ? categoryId : 0;
    const catSlug = categorySlug ? categorySlug : "all";

    history.push(routes.sortiment + "/" + catID + "/" + catSlug);
  };

  const getProductID = () => {
    if (productId) return parseInt(productId);
    return null;
  };

  if (!ready) {
    return (
      <Row>
        {[1, 2, 3].map((index) => (
          <Col className="text-center" key={index}>
            <Alert variant="secondary">
              <Spinner animation="border" variant="secondary" />
            </Alert>
          </Col>
        ))}
      </Row>
    );
  }

  return (
    <React.Fragment>
      <ProductModal
        show={showModal()}
        onHide={() => hideModal()}
        productID={getProductID()}
      />
      <InfoText />
      <CategoryText category={category} />
      <Items products={products} page={currentPage} />
      <Pagination
        pageCount={pageCount}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
    </React.Fragment>
  );
}

const Items = ({ products, page }) => {
  const { categoryId, categorySlug } = useParams();

  const perPage = config.SORTIMENTS["products_per_page"];
  const pageProducts = products.slice(page * perPage, page * perPage + perPage);

  const getProductUrl = (product) => {
    const prodID = product.id;
    const prodSlug = product.slug;
    const catID = categoryId ? categoryId : 0;
    const catSlug = categorySlug ? categorySlug : "all";
    const urlParts = [
      routes.sortiment,
      catID,
      catSlug,
      "product",
      prodID,
      prodSlug,
    ];

    return urlParts.join("/");
  };

  if (products.length === 0) {
    return (
      <Alert variant="secondary" className="text-center">
        <Translation k="sortiment_empty_list" />
      </Alert>
    );
  }

  return (
    <Row>
      {pageProducts.map((product, index) => (
        <Col sm="6" md="4" key={index}>
          <Item product={product} url={getProductUrl(product)} />
        </Col>
      ))}
    </Row>
  );
};

const Item = ({ product, url }) => {
  const { primaryTitle, secondaryTitle } = getProductTitles(product);

  const getImageStyles = () => {
    if (product.thumbnails.list) {
      return {
        backgroundImage: "url(" + product.thumbnails.list + ")",
      };
    }
  };

  const hasDiscount = () => {
    let hasDiscount = false;

    product.variants.forEach((variant) => {
      if (variant.discount > config.CUSTOMER_CARD_DISCOUNT_PERCENT) {
        hasDiscount = true;
      }
    });

    return hasDiscount;
  };

  return (
    <Link to={url} className="productLink" alt={primaryTitle}>
      <div className="item">
        <div className="image" style={getImageStyles()}></div>
        <div className="content">
          <div className="primaryTitle">{primaryTitle}</div>
          <div className="secondaryTitle">{secondaryTitle}</div>
          <Row className="footer">
            <Col className="link" xs="5">
              <Translation k="sortiment_view" /> <i className="arrow right"></i>
            </Col>
            <Col
              className={`price text-right ${
                hasDiscount() ? "hasDiscount" : ""
              }`}
              xs="7"
            >
              <ItemPrice variants={product.variants} />
            </Col>
          </Row>
        </div>
      </div>
    </Link>
  );
};

const ItemPrice = ({ variants }) => {
  if (variants.length === 0) {
    return (
      <span className="currency">
        <Translation k="sortiment_not_available" />
      </span>
    );
  }

  const getValue = () => {
    const { largest, smallest } = getLargestAndSmallestVariantPrice(variants);

    if (smallest === null && largest === null) {
      return "-";
    }

    if (smallest === largest) {
      return smallest;
    }

    return smallest + " - " + largest;
  };

  return (
    <React.Fragment>
      {getValue()} <span className="currency">EUR</span>
    </React.Fragment>
  );
};

const Pagination = ({ pageCount, onPageChange, currentPage }) => {
  if (pageCount <= 1) {
    return null;
  }

  return (
    <Row>
      <Col>
        <div className="d-flex">
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            onPageChange={({ selected }) => onPageChange(selected)}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={currentPage}
          />
        </div>
      </Col>
    </Row>
  );
};

const CategoryText = ({ category }) => {
  if (category.text.length === 0) {
    return null;
  }

  return (
    <Row>
      <Col>
        <RichText text={category.text} />
      </Col>
    </Row>
  );
};

const InfoText = () => {
  return (
    <div className="infoText text-center p-2 rounded mb-3">
      <TranslationHTML k="sortiment_list_info" />
    </div>
  );
};

export default ProductList;
