import React from "react";
import Item from "./components/Item";
import ItemCover from "./components/ItemCover";
import Loader from "./../multiuse/Loader";
import Message from "./../multiuse/Message";
import CheckLink from "./../multiuse/checkLink";
import { Row, Col, Card } from "react-bootstrap";

const PartnerList = ({ data, ready }) => {
  if (!ready) {
    return <Loader variant="secondary" />;
  }
  if (data.length === 0) {
    return <Message variant="secondary" />;
  }

  return (
    <Row>
      {data.map((data) => (
        <Col key={data.id} sm="6" md="4" lg="4" xl="3" className="pb-4">
          <Card className="h-100">
            <ItemCover data={data.thumbnails} />
            <Card.Body>
              <Card.Text>{data.description}</Card.Text>
              <Card.Title>
                <h6>{data.name}</h6>
              </Card.Title>
            </Card.Body>
            <Item data={data.thumbnails} />
            <Col>
              <Row className="contacts">
                <Col
                  xs="3"
                  className="text-left d-flex align-items-start flex-column mt-auto pr-1"
                >
                  {data.phones.split(",").map((phone) => (
                    <p key={phone}>{phone}</p>
                  ))}
                </Col>
                <Col xs="9" className="text-right">
                  <p>{data.email}</p>
                  <CheckLink url={data.www} />
                </Col>
              </Row>
            </Col>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default PartnerList;
