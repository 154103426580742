import React from "react";
import { Row, Col } from "react-bootstrap";
import "./style.scss";

function ContentBlockTitleRow({ primary_title, secondary_title }) {
  if (primary_title.length === 0 && secondary_title.length === 0) {
    return null;
  }

  return (
    <Row>
      <Col className="contentBlockTitles">
        <div className="primaryTitle">{primary_title}</div>
        <div className="secondaryTitle">{secondary_title}</div>
      </Col>
    </Row>
  );
}

export default ContentBlockTitleRow;
