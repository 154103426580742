import React from "react";
import ParseDomain from "../../multiuse/parseDomain";
import { Row, Col, Card } from "react-bootstrap";

const ItemCard = ({ data }) => {
  return (
    <Card>
      {data.thumbnails ? (
        <img src={data.thumbnails.carousel} alt=""></img>
      ) : (
        <div className="alert alert-secondary extra-height"></div>
      )}
      <p className={data.thumbnails ? "title" : "title-dark"}>{data.name}</p>
      <Col>
        <Row className="contacts">
          <Col
            xs="4"
            className="text-left d-flex align-items-start flex-column mt-auto"
          >
            {data.phones.split(",").map((phone) => (
              <p key={phone}>{phone}</p>
            ))}
          </Col>
          <Col className="text-right">
            <p>{data.email}</p>
            <p>
              <ParseDomain url={data.www} />
            </p>
          </Col>
        </Row>
      </Col>
    </Card>
  );
};

export default ItemCard;
