import React from "react";
import Translation, { TranslationCombine } from "translation";

import { ReactComponent as CityIcon } from "./icons/city.svg";
import { ReactComponent as ProtectedIcon } from "./icons/protected.svg";
import { ReactComponent as GenericIcon } from "./icons/generic.svg";

import { ReactComponent as LightFullIcon } from "./icons/light-full.svg";
import { ReactComponent as LightHalfIcon } from "./icons/light-half.svg";
import { ReactComponent as LightNoneIcon } from "./icons/light-none.svg";

import { ReactComponent as SoilFullIcon } from "./icons/soil-full.svg";
import { ReactComponent as SoilHalfIcon } from "./icons/soil-half.svg";
import { ReactComponent as SoilNoneIcon } from "./icons/soil-none.svg";

import { ReactComponent as WaterFullIcon } from "./icons/water-full.svg";
import { ReactComponent as WaterHalfIcon } from "./icons/water-half.svg";
import { ReactComponent as WaterNoneIcon } from "./icons/water-none.svg";

function Icons({ product }) {
  const {
    other_attribute_for_cities,
    other_attribute_protected_area,
    other_attribute_bee_plant,
    other_attribute_floristics,
    other_attribute_rock_garden,
    decorative_autumn,
    decorative_leaf,
    decorative_skin,
    foliage_aroma,
    foliage_evergreen,
    flower_aroma,
    flower_decorative,
    light_sun,
    light_half_shadow,
    light_shadow,
    soil_rich,
    soil_half_rich,
    soil_moderate,
    humidity_wet,
    humidity_half_wet,
    humidity_dry,
  } = product;

  if (
    !(
      other_attribute_for_cities ||
      other_attribute_protected_area ||
      other_attribute_bee_plant ||
      other_attribute_floristics ||
      other_attribute_rock_garden ||
      decorative_autumn ||
      decorative_leaf ||
      decorative_skin ||
      foliage_aroma ||
      foliage_evergreen ||
      flower_aroma ||
      flower_decorative ||
      light_sun ||
      light_half_shadow ||
      light_shadow ||
      soil_rich ||
      soil_half_rich ||
      soil_moderate ||
      humidity_wet ||
      humidity_half_wet ||
      humidity_dry
    )
  ) {
    return null;
  }

  return (
    <div className="holder">
      <Light product={product} />
      <Soil product={product} />
      <Humidity product={product} />

      {other_attribute_for_cities && (
        <CityIcon title={<Translation k="sortiment_modal_icon_city_label" />} />
      )}
      {other_attribute_protected_area && (
        <ProtectedIcon
          title={<Translation k="sortiment_modal_icon_protected_area_label" />}
        />
      )}

      {other_attribute_bee_plant && (
        <GenericIcon
          title={<Translation k="sortiment_modal_icon_bee_label" />}
        />
      )}
      {other_attribute_floristics && (
        <GenericIcon
          title={<Translation k="sortiment_modal_icon_floristic_label" />}
        />
      )}
      {other_attribute_rock_garden && (
        <GenericIcon
          title={<Translation k="sortiment_modal_icon_rock_label" />}
        />
      )}

      {decorative_skin && (
        <GenericIcon
          title={<Translation k="sortiment_modal_icon_dec_skin_label" />}
        />
      )}
      {decorative_leaf && (
        <GenericIcon
          title={<Translation k="sortiment_modal_icon_dec_leaf_label" />}
        />
      )}
      {decorative_autumn && (
        <GenericIcon
          title={<Translation k="sortiment_modal_icon_dec_autumn_label" />}
        />
      )}

      {foliage_aroma && (
        <GenericIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_variable_foliage"
              k2="sortiment_modal_icon_foliage_aroma"
            />
          }
        />
      )}
      {foliage_evergreen && (
        <GenericIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_variable_foliage"
              k2="sortiment_modal_icon_foliage_evergreen"
            />
          }
        />
      )}

      {flower_aroma && (
        <GenericIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_variable_flower"
              k2="sortiment_modal_icon_flower_aroma"
            />
          }
        />
      )}
      {flower_decorative && (
        <GenericIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_variable_flower"
              k2="sortiment_modal_icon_flower_decorative"
            />
          }
        />
      )}
    </div>
  );
}

const Light = ({ product }) => {
  const { light_sun, light_half_shadow, light_shadow } = product;

  return (
    <React.Fragment>
      {light_sun && (
        <LightFullIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_light_label"
              k2="sortiment_modal_icon_light_full"
            />
          }
        />
      )}
      {light_half_shadow && (
        <LightHalfIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_light_label"
              k2="sortiment_modal_icon_light_half"
            />
          }
        />
      )}
      {light_shadow && (
        <LightNoneIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_light_label"
              k2="sortiment_modal_icon_light_none"
            />
          }
        />
      )}
    </React.Fragment>
  );
};

const Soil = ({ product }) => {
  const { soil_rich, soil_half_rich, soil_moderate } = product;

  return (
    <React.Fragment>
      {soil_rich && (
        <SoilFullIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_soil_label"
              k2="sortiment_modal_icon_soil_full"
            />
          }
        />
      )}
      {soil_half_rich && (
        <SoilHalfIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_soil_label"
              k2="sortiment_modal_icon_soil_half"
            />
          }
        />
      )}
      {soil_moderate && (
        <SoilNoneIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_soil_label"
              k2="sortiment_modal_icon_soil_none"
            />
          }
        />
      )}
    </React.Fragment>
  );
};

const Humidity = ({ product }) => {
  const { humidity_wet, humidity_half_wet, humidity_dry } = product;

  return (
    <React.Fragment>
      {humidity_wet && (
        <WaterFullIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_humidity_label"
              k2="sortiment_modal_icon_humidity_full"
            />
          }
        />
      )}
      {humidity_half_wet && (
        <WaterHalfIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_humidity_label"
              k2="sortiment_modal_icon_humidity_half"
            />
          }
        />
      )}
      {humidity_dry && (
        <WaterNoneIcon
          title={
            <TranslationCombine
              k1="sortiment_modal_icon_humidity_label"
              k2="sortiment_modal_icon_humidity_none"
            />
          }
        />
      )}
    </React.Fragment>
  );
};

export default Icons;
