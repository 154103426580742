import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "styles/index.scss";
import App from "App";
import config from "config";
import ErrorSentry from "pages/error_sentry/ErrorSentry";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://55c8c76085b14ab5a03fb940e288644b@o410494.ingest.sentry.io/5550049",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
  });
}

ReactGA.initialize(config.GA_TRACKING_ID);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorSentry}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
