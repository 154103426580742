import React from "react";
import { Col } from "react-bootstrap";

function Message() {
  return (
    <Col className="alert alert-secondary">
      <div className="d-flex justify-content-center">
        <p className="mb-0">Pašlaik informācija vēl nav pievienota.</p>
      </div>
    </Col>
  );
}

export default Message;
