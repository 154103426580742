import routes from "routes.js";

export default [
  {
    translation_key: "navigation_home",
    url: routes.home,
    type: "basic",
  },
  {
    translation_key: "navigation_active_activities",
    url: routes.home_activities,
    type: "mob-activities-scroll",
  },
  {
    translation_key: "home_newsletter_title",
    url: routes.home_newsletter,
    type: "mob-newsletter-scroll",
  },
  {
    translation_key: "navigation_sortiment",
    url: routes.sortiment,
    type: "basic",
  },
  {
    translation_key: "navigation_suggetions",
    url: routes.advice,
    type: "basic",
  },
  {
    translation_key: "navigation_place",
    type: "dropdown",
    children: [
      {
        translation_key: "navigation_about_us",
        url: routes.about_us,
      },
      {
        translation_key: "navigation_gallery",
        url: routes.gallery,
      },
      {
        translation_key: "navigation_publications",
        url: routes.publications,
      },
      {
        translation_key: "navigation_customer_card",
        url: routes.customer_card,
      },
      {
        translation_key: "navigation_gift_card",
        url: routes.gift_card,
      },
    ],
  },
  {
    translation_key: "navigation_partners",
    url: routes.partners,
    type: "basic",
  },
  {
    translation_key: "navigation_contacts",
    url: routes.contact,
    type: "basic",
  },
  {
    translation_key: "navigation_shop",
    url: "https://veikals.baltezers.lv",
    type: "shop",
  },
];
