/**
 * Returns Date() full date as int
 * For example, if current date is 2021-04-03 it will be returned as int(20210403)
 * @param {Date} date
 * @returns {number}
 */
const getCurrentDateInt = (date) => {
  var mm = date.getMonth() + 1; // getMonth() is zero-based
  var dd = date.getDate();

  return parseInt(
    [
      date.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("")
  );
};

/**
 * Returns date in string format as int
 * For example, string('2021-04-03') will be returned as int(20210403)
 * @param {string} dateStr
 * @returns {number}
 */
const getDateInt = (dateStr) => {
  dateStr = dateStr.replace("-", "");
  return parseInt(dateStr.replace("-", ""));
};

/**
 * Returns reordered activities by date - earliest at the top
 * @param {Array} activities
 * @returns {Array}
 */
const reorderActivities = (activities) => {
  let reordered = [];

  activities.forEach((value) => {
    if (reordered.length === 0) {
      reordered.push([value]);
    } else {
      let i = reordered.length - 1;
      let j = reordered[i].length - 1;

      if (Date.parse(reordered[i][j].date) === Date.parse(value.date)) {
        reordered[i].push(value);
      } else {
        reordered.push([value]);
      }
    }
  });

  return reordered;
};

/**
 * Return activities split into two lists - upcoming and past
 * The upcoming list is ordered by nearest at the top
 * The past list is ordered by last past activity first
 * @param {Array} activities
 * @returns {object}
 */
const splitActivitiesInPastAndFuture = (activities) => {
  const reordered = reorderActivities(activities);
  const currentDateInt = getCurrentDateInt(new Date());

  let upcoming = [];
  let past = [];

  reordered.forEach((value) => {
    if (getDateInt(value[0].date) >= currentDateInt) {
      upcoming.push(value);
    } else {
      past.unshift(value);
    }
  });

  return { upcoming, past };
};

export { reorderActivities, splitActivitiesInPastAndFuture };
