import React from "react";
import { Form } from "react-bootstrap";
import "./style.scss";

function Checkbox({ name, isChecked, onChange, uniqueId, variant, size }) {
  return (
    <Form.Check
      inline
      custom
      label={name}
      type="checkbox"
      id={`inline-${uniqueId}`}
      key={uniqueId}
      checked={isChecked}
      onChange={onChange}
      className={`Bootstrap-Custom-Checkbox variant-${variant} size-${size}`}
    />
  );
}

export default Checkbox;
