import React from "react";
import Translation from "translation";
import { getProductDimensionPrefixLocale } from "pages/sortiment/utils";
import Split from "./Split";

function Variables({ product }) {
  return (
    <React.Fragment>
      <Height product={product} />
      <FlowerHeight product={product} />
      <Width product={product} />
      <Crown product={product} />
      <Foliage product={product} />
      <Flower product={product} />
      <Fruit product={product} />
    </React.Fragment>
  );
}

const Height = ({ product }) => {
  const { height_from, height_to, height_text, dimension_unit } = product;

  const hasMin = height_from !== null;
  const hasMax = height_to !== null;
  const hasText = height_text.length > 0;

  if (!hasMin && !hasMax && !hasText) {
    return null;
  }

  // returns height value without .0.. or .[1-9]0
  let height_from_split;
  let height_to_split;

  if (hasMin) {
    height_from_split = <Split value={height_from} />;
  }
  if (hasMax) {
    height_to_split = <Split value={height_to} />;
  }

  return (
    <div className="item">
      <strong>
        <Translation k="sortiment_modal_variable_height" />
      </strong>{" "}
      {getProductDimensionPrefixLocale(hasMin, hasMax)} {height_from_split}{" "}
      {hasMin && hasMax && "-"} {height_to_split}{" "}
      {(hasMin || hasMax) && dimension_unit} {height_text}
    </div>
  );
};

const FlowerHeight = ({ product }) => {
  const { flower_height, dimension_unit } = product;

  if (flower_height === null) {
    return null;
  }

  let flower_height_split;
  flower_height_split = <Split value={flower_height} />;
  return (
    <div className="item">
      <strong>
        <Translation k="sortiment_modal_variable_flower_height" />
      </strong>{" "}
      {flower_height_split} {dimension_unit}
    </div>
  );
};

const Width = ({ product }) => {
  const { width_from, width_to, width_text, dimension_unit } = product;

  const hasMin = width_from !== null;
  const hasMax = width_to !== null;
  const hasText = width_text.length > 0;

  if (!hasMin && !hasMax && !hasText) {
    return null;
  }

  // returns width value without .0.. or .[1-9]0

  let width_from_split;
  let width_to_split;

  if (hasMin) {
    width_from_split = <Split value={width_from} />;
  }
  if (hasMax) {
    width_to_split = <Split value={width_to} />;
  }

  return (
    <div className="item">
      <strong>
        <Translation k="sortiment_modal_variable_width" />
      </strong>{" "}
      {getProductDimensionPrefixLocale(hasMin, hasMax)} {width_from_split}{" "}
      {hasMin && hasMax && "-"} {width_to_split}{" "}
      {(hasMin || hasMax) && dimension_unit} {width_text}
    </div>
  );
};

const Crown = ({ product }) => {
  const names = [
    product.crown_position_one,
    product.crown_position_two,
    product.crown_position_three,
  ]
    .filter((crown) => crown)
    .map((crown) => crown.name);

  if (names.length === 0) {
    return null;
  }

  return (
    <div className="item">
      <strong>
        <Translation k="sortiment_modal_variable_crown" />
      </strong>{" "}
      {names.join(" ")}
    </div>
  );
};

const Foliage = ({ product }) => {
  const {
    foliage_types,
    foliage_text,
    foliage_evergreen,
    foliage_aroma,
  } = product;

  const renderColors = foliage_types.map((type) => (
    <div
      key={type.id}
      className="float-left colorBox"
      title={type.name}
      style={{ backgroundColor: type.color }}
    ></div>
  ));

  if (foliage_types.length === 0 && foliage_text.length === 0) {
    return null;
  }

  let evergreen;
  let aroma;

  if (foliage_evergreen) {
    evergreen = <Translation k="variable_evergreen" />;
  }
  if (foliage_aroma) {
    aroma = <Translation k="variable_aroma" />;
  }
  return (
    <div className="item">
      <div className="float-left ml-0 mr-1">
        <strong>
          <Translation k="sortiment_modal_variable_foliage" />
        </strong>
      </div>
      {renderColors}
      <div className="m-0">
        {evergreen}
        {foliage_evergreen ? ", " : null}
        {aroma}
        {foliage_aroma ? ", " : null}
        {foliage_text}
      </div>
    </div>
  );
};

const Flower = ({ product }) => {
  const {
    flower_types,
    flower_text,
    flower_custom_text,
    flower_decorative,
    flower_aroma,
  } = product;

  const renderColors = flower_types.map((type) => (
    <div
      key={type.id}
      className="float-left colorBox"
      title={type.name}
      style={{ backgroundColor: type.color }}
    ></div>
  ));

  if (flower_types.length === 0 && flower_text.length === 0) {
    return null;
  }

  let decorative;
  let aroma;

  if (flower_decorative) {
    decorative = <Translation k="variable_decorative" />;
  }
  if (flower_aroma) {
    aroma = <Translation k="variable_aroma" />;
  }

  return (
    <div className="item">
      <div className="float-left ml-0 mr-1">
        <strong>
          <Translation k="sortiment_modal_variable_flower" />
        </strong>
      </div>
      {renderColors}
      <div className="m-0">
        {flower_text}
        {flower_decorative ? ", " : null}
        {decorative}
        {flower_aroma ? ", " : null}
        {aroma}
        {flower_custom_text.length > 0 ? ", " : null}
        {flower_custom_text}
      </div>
    </div>
  );
};

const Fruit = ({ product }) => {
  const {
    fruit_text,
    fruit_edible,
    fruit_poisonous,
    fruit_decorative,
  } = product;

  if (fruit_text.length === 0) {
    return null;
  }

  let decorative;
  let edible;
  let poisonous;

  if (fruit_edible) {
    edible = <Translation k="variable_edible" />;
  }
  if (fruit_poisonous) {
    poisonous = <Translation k="variable_poisonous" />;
  }
  if (fruit_decorative) {
    decorative = <Translation k="fruit_decorative" />;
  }

  return (
    <div className="item">
      <div className="float-left ml-0 mr-1">
        <strong>
          <Translation k="sortiment_modal_variable_fruit" />
        </strong>
      </div>

      {decorative}
      {fruit_decorative ? " - " : null}
      {edible}
      {fruit_edible ? " - " : null}
      {poisonous}
      {fruit_poisonous ? " - " : null}
      {fruit_text}
    </div>
  );
};

export default Variables;
