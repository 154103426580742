import React, { useState, useEffect, useContext } from "react";
import { LanguageContext } from "context";
import { apiContentPreview } from "api";
import { useParams } from "react-router-dom";
import { Row, Col, Alert, Spinner } from "react-bootstrap";
import "./style.scss";
import ContentBlocks from "common/content_blocks/ContentBlocks";
import Translation from "translation";

function PreviewContent() {
  const { language } = useContext(LanguageContext);
  const { type, id, hash } = useParams();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setLoading(true);

    apiContentPreview(language, type, id, hash).then(
      (data) => {
        setData(data);
        setLoading(false);
      },
      (error) => {
        setHasError(true);
        setLoading(false);
      }
    );
  }, [language, id, type, hash]);

  const showNavigation = () => {
    if (loading || hasError) {
      return true;
    }

    if (type === "custom" && data.children.length === 0) {
      return false;
    }

    return true;
  };

  return (
    <Row className="ContentPreview">
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Row className="header">
          <Col>
            <Header ready={!loading && !hasError} data={data} />
          </Col>
        </Row>
        <Row>
          <Col lg="3" hidden={!showNavigation()}>
            <Navigation ready={!loading} hasError={hasError} />
          </Col>
          <Col>
            <Content ready={!loading} data={data} hasError={hasError} />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const Header = ({ ready, data }) => {
  return (
    <div className="navigationLevel">
      {!ready && "..."}
      {ready && data.name}
    </div>
  );
};

const Navigation = ({ ready, hasError }) => {
  if (hasError) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Translation k="error_404" />
      </Alert>
    );
  }

  if (!ready) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  return (
    <div className="filterHolder text-center">
      <Translation k="custom_page_sub_sections_title" />
    </div>
  );
};

const Content = ({ ready, data, hasError }) => {
  if (hasError) {
    return (
      <Alert variant="secondary" className="text-center">
        <Translation k="error_404" />
      </Alert>
    );
  }

  if (!ready) {
    return (
      <Alert variant="secondary" className="text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  return <ContentBlocks contents={data.contents} />;
};

export default PreviewContent;
