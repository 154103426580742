import React, { useContext, useState, useEffect, useReducer } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import { LanguageContext } from "context";
import "./style.scss";
import { apiNewsletterTypes, apiCreateNewsletterSubscriber } from "api";
import { customTypesReducer, subscriberReducer } from "./reducers";
import Translation, { TranslationHTML } from "translation";
import Checkbox from "common/checkbox/Checkbox";

const initialSubscriber = {
  email: {
    value: "",
    errors: [],
  },
  phone: {
    value: "",
    errors: [],
  },
  first_name: {
    value: "",
    errors: [],
  },
  last_name: {
    value: "",
    errors: [],
  },
  receive_types: {
    email: true,
    sms: false,
    error: false,
  },
};

function Newsletter() {
  const { language } = useContext(LanguageContext);

  const [typesIsLoaded, setTypesIsLoaded] = useState(false);
  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [subscriber, subscriberDispatch] = useReducer(
    subscriberReducer,
    initialSubscriber
  );
  const [customTypes, customTypesDispatch] = useReducer(customTypesReducer, []);

  const createUser = (event) => {
    event.preventDefault();
    setFormIsSubmitting(true);
    setFormSubmitted(false);
    subscriberDispatch({ type: "reset_errors" });

    let submitData = {
      email: subscriber.email.value,
      phone: subscriber.phone.value,
      first_name: subscriber.first_name.value,
      last_name: subscriber.last_name.value,
      language: language,
      register_type: 2,
      receive_email: subscriber.receive_types.email,
      receive_sms: subscriber.receive_types.sms,
      types: customTypes.flatMap((type) => {
        return type.selected ? type.id : [];
      }),
    };

    apiCreateNewsletterSubscriber(submitData, language).then(
      (response) => {
        setFormSubmitted(true);
        setFormIsSubmitting(false);
        subscriberDispatch({ type: "reset", payload: initialSubscriber });
        customTypesDispatch({ type: "reset" });
      },
      (error) => {
        let error_dispatches = {
          email: "set_email_error",
          phone: "set_phone_error",
          first_name: "set_first_name_error",
          last_name: "set_last_name_error",
        };

        for (let key in error_dispatches) {
          if (key in error) {
            subscriberDispatch({
              type: error_dispatches[key],
              payload: error[key],
            });
          }
        }

        setFormIsSubmitting(false);
      }
    );
  };

  useEffect(() => {
    let isSubscribed = true;

    setTypesIsLoaded(false);

    apiNewsletterTypes(language).then((response) => {
      if (!isSubscribed) {
        return;
      }

      response.forEach((value) => {
        value["selected"] = true;
      });

      customTypesDispatch({ type: "set", payload: response });
      setTypesIsLoaded(true);
    });

    return () => (isSubscribed = false);
  }, [language]);

  return (
    <Row className="Home-Newsletter" id="newsletter">
      <Col></Col>
      <Col xl="6" lg="7" md="9">
        <Row>
          <Col className="title">
            <Translation k="home_newsletter_title" />
          </Col>
        </Row>

        <Row>
          <Col className="text">
            <TranslationHTML k="home_newsletter_text" />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form onSubmit={createUser}>
              <Row>
                <Col xl="6" lg="6" md="6">
                  <Form.Group controlId="formEmail" className="inputHolder">
                    <Form.Label>
                      <Translation k="home_newsletter_label_email" />
                    </Form.Label>
                    <Form.Control
                      type="email"
                      onChange={(e) =>
                        subscriberDispatch({
                          type: "set_email",
                          payload: e.target.value,
                        })
                      }
                      isInvalid={subscriber.email.errors.length > 0}
                      value={subscriber.email.value}
                    />
                    {subscriber.email.errors.map((error, i) => (
                      <Form.Control.Feedback type="invalid" key={i}>
                        {error}
                      </Form.Control.Feedback>
                    ))}
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="6">
                  <Form.Group controlId="formPhone" className="inputHolder">
                    <Form.Label>
                      <Translation k="home_newsletter_label_phone" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        subscriberDispatch({
                          type: "set_phone",
                          payload: e.target.value,
                        })
                      }
                      isInvalid={subscriber.phone.errors.length > 0}
                      value={subscriber.phone.value}
                    />
                    {subscriber.phone.errors.map((error, i) => (
                      <Form.Control.Feedback type="invalid" key={i}>
                        {error}
                      </Form.Control.Feedback>
                    ))}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xl="6" lg="6" md="6">
                  <Form.Group controlId="formfirstName" className="inputHolder">
                    <Form.Label>
                      <Translation k="home_newsletter_label_first_name" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        subscriberDispatch({
                          type: "set_first_name",
                          payload: e.target.value,
                        })
                      }
                      isInvalid={subscriber.first_name.errors.length > 0}
                      value={subscriber.first_name.value}
                    />
                    {subscriber.first_name.errors.map((error, i) => (
                      <Form.Control.Feedback type="invalid" key={i}>
                        {error}
                      </Form.Control.Feedback>
                    ))}
                  </Form.Group>
                </Col>
                <Col xl="6" lg="6" md="6">
                  <Form.Group controlId="formLastName" className="inputHolder">
                    <Form.Label>
                      <Translation k="home_newsletter_label_last_name" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) =>
                        subscriberDispatch({
                          type: "set_last_name",
                          payload: e.target.value,
                        })
                      }
                      isInvalid={subscriber.last_name.errors.length > 0}
                      value={subscriber.last_name.value}
                    />
                    {subscriber.last_name.errors.map((error, i) => (
                      <Form.Control.Feedback type="invalid" key={i}>
                        {error}
                      </Form.Control.Feedback>
                    ))}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {customTypes.length > 0 ? (
                  <Col className="checkboxesHolder" xl="6" lg="6" md="6" sm="6">
                    <Form.Label>
                      <Translation k="home_newsletter_label_types" />
                    </Form.Label>

                    <div hidden={!typesIsLoaded}>
                      {customTypes.map((value, i) => (
                        <Checkbox
                          name={value.name}
                          isChecked={value.selected}
                          onChange={() =>
                            customTypesDispatch({
                              type: "change",
                              payload: value.id,
                            })
                          }
                          uniqueId={i}
                          key={i}
                        />
                      ))}
                    </div>

                    <div hidden={typesIsLoaded}>
                      <Spinner animation="border" variant="light" />
                    </div>
                  </Col>
                ) : null}

                <Col className="checkboxesHolder" xl="6" lg="6" md="6" sm="6">
                  <Form.Label>
                    <Translation k="home_newsletter_label_receive_types" />
                  </Form.Label>

                  <div>
                    <Checkbox
                      name={
                        <Translation k="home_newsletter_receive_type_email" />
                      }
                      isChecked={subscriber.receive_types.email}
                      onChange={() =>
                        subscriberDispatch({ type: "set_receive_email" })
                      }
                      uniqueId="email"
                    />
                    <Checkbox
                      name={
                        <Translation k="home_newsletter_receive_type_sms" />
                      }
                      isChecked={subscriber.receive_types.sms}
                      onChange={() =>
                        subscriberDispatch({ type: "set_receive_sms" })
                      }
                      uniqueId="sms"
                    />
                  </div>

                  <div
                    hidden={!subscriber.receive_types.error}
                    className="invalid-feedback pb-3"
                  >
                    <Translation k="home_newsletter_receive_type_error" />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col></Col>
                <Col xl="3" lg="3" md="3">
                  <Button
                    type="submit"
                    variant="custom"
                    disabled={formIsSubmitting}
                  >
                    <span hidden={formIsSubmitting}>
                      <Translation k="home_newsletter_button" />
                    </span>
                    <Spinner
                      animation="border"
                      size="sm"
                      variant="light"
                      hidden={!formIsSubmitting}
                    />
                  </Button>
                </Col>
              </Row>

              <Row hidden={!formSubmitted}>
                <Col>
                  <Alert variant="success" className="text-center mt-4">
                    <Translation k="home_newsletter_submit_success" />
                  </Alert>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

export default Newsletter;
