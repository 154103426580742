import React, { useState } from "react";
import { Row, Col, Carousel, Button } from "react-bootstrap";
import "./style.scss";
import RichText from "common/richtext/RichText";
import ContentBlockTitleRow from "common/content_blocks/components/common/title/ContentBlockTitleRow";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PlaceHolderImage from "./images/placeholder.jpg";
import ImageModal from "common/image_modal/ImageModal";

function TextImagesBlock({ content }) {
  const { primary_title, secondary_title, text } = content;

  return (
    <Row className="Content-Block-Text-Images">
      <Col>
        <ContentBlockTitleRow
          primary_title={primary_title}
          secondary_title={secondary_title}
        />
        <Row>
          <Col>
            <RichText text={text} />
          </Col>
        </Row>
      </Col>
      <GalleryCol content={content} />
    </Row>
  );
}

const GalleryCol = ({ content }) => {
  const [index, setIndex] = useState(0);
  const { images, button_name, button_url } = content;

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  if (images.length === 0) {
    return null;
  }

  return (
    <Col md="5" className="text-center mb-3">
      {images.length === 1 && <LazyImage image={images[0]} />}

      {images.length > 1 && (
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {images.map((img, index) => (
            <Carousel.Item key={index}>
              <LazyImage image={img} />
            </Carousel.Item>
          ))}
        </Carousel>
      )}

      {button_name.length > 0 && button_url.length > 0 && (
        <Button
          href={button_url}
          variant="custom"
          className="mt-4 galleryButton"
        >
          {button_name}
        </Button>
      )}
    </Col>
  );
};

const LazyImage = ({ image }) => {
  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <ImageModal
        show={show}
        src={image.thumbnails.large}
        name={image.image.default_alt_text}
        description={image.image.description}
        onShowChange={changeShow}
      />
      <div
        className="position-relative contentBlockLazyImageHolder"
        onClick={changeShow}
      >
        <img src={PlaceHolderImage} alt="" />
        <div className="position-absolute w-100 lazy">
          <LazyLoadImage
            src={image.thumbnails.carousel}
            alt={image.image.default_alt_text}
            effect="opacity"
            wrapperClassName="w-100 link"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default TextImagesBlock;
