import React from "react";
import Item from "./components/Item";
import Loader from "./../multiuse/Loader";
import Message from "./../multiuse/Message";
import { Row, Col } from "react-bootstrap";

const Showcase = ({ data, ready }) => {
  if (!ready) {
    return <Loader variant="light" />;
  }
  if (data.length === 0) {
    return <Message variant="light" />;
  }

  return (
    <Row>
      {data.map((data) => (
        <Col key={data.id} sm="6" md="4" lg="3" className="pb-4">
          <Item data={data} />
        </Col>
      ))}
    </Row>
  );
};

export default Showcase;
