import React, { useReducer, useContext, useState } from "react";
import { Row, Col, Form, Button, Spinner, Alert } from "react-bootstrap";
import "./style.scss";
import { LanguageContext } from "context";
import { apiOrderCustomerCard } from "api";
import { customercardReducer } from "./reducers";
import Translation, { TranslationHTML } from "translation";
import Checkbox from "common/checkbox/Checkbox";

const initialData = {
  first_name: {
    value: "",
    errors: [],
  },
  last_name: {
    value: "",
    errors: [],
  },
  email: {
    value: "",
    errors: [],
  },
  newsletter: {
    value: false,
    errors: [],
  },
};

function CustomerCard() {
  return (
    <Row className="Customer-Card">
      <Col></Col>
      <Col xl="8" lg="10" md="10">
        <Row>
          <Col xs={{ order: "last" }} md={{ order: "first" }}>
            <CardInfo />
          </Col>
          <Col lg="6" md="8">
            <CardForm />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const CardForm = () => {
  const { language } = useContext(LanguageContext);

  const [formIsSubmitting, setFormIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [customercard, customercardDispatch] = useReducer(
    customercardReducer,
    initialData
  );

  const orderCustomerCard = (event) => {
    event.preventDefault();
    setFormIsSubmitting(true);
    setFormSubmitted(false);
    customercardDispatch({ type: "reset_errors" });

    let submitData = {
      first_name: customercard.first_name.value,
      last_name: customercard.last_name.value,
      email: customercard.email.value,
      newsletter: customercard.newsletter.value,
      language: language,
    };

    apiOrderCustomerCard(submitData, language).then(
      (response) => {
        setFormSubmitted(true);
        setFormIsSubmitting(false);
        customercardDispatch({ type: "reset", payload: initialData });
      },
      (error) => {
        ["first_name", "last_name", "email", "newsletter"].map((value) => {
          if (value in error) {
            customercardDispatch({
              type: "error",
              field: value,
              payload: error[value],
            });
          }
          return null;
        });

        setFormIsSubmitting(false);
      }
    );
  };

  return (
    <div className="formHolder">
      <Row>
        <Col md={{ span: 8, offset: 2 }} xs={{ span: 10, offset: 1 }}>
          <Form className="submitForm" onSubmit={orderCustomerCard}>
            <Row>
              <Col className="section-name">
                <Translation k="customercard_section_name" />
              </Col>
            </Row>
            <Row>
              <Col className="title">
                <Translation k="customercard_title" />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formFirstName">
                  <Form.Label>
                    <Translation k="customercard_first_name" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) =>
                      customercardDispatch({
                        type: "set",
                        field: "first_name",
                        payload: e.target.value,
                      })
                    }
                    isInvalid={customercard.first_name.errors.length > 0}
                    value={customercard.first_name.value}
                  />
                  {customercard.first_name.errors.map((error, i) => (
                    <Form.Control.Feedback type="invalid" key={i}>
                      {error}
                    </Form.Control.Feedback>
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formLastName">
                  <Form.Label>
                    <Translation k="customercard_last_name" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) =>
                      customercardDispatch({
                        type: "set",
                        field: "last_name",
                        payload: e.target.value,
                      })
                    }
                    isInvalid={customercard.last_name.errors.length > 0}
                    value={customercard.last_name.value}
                  />
                  {customercard.last_name.errors.map((error, i) => (
                    <Form.Control.Feedback type="invalid" key={i}>
                      {error}
                    </Form.Control.Feedback>
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>
                    <Translation k="customercard_email" />
                  </Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) =>
                      customercardDispatch({
                        type: "set",
                        field: "email",
                        payload: e.target.value,
                      })
                    }
                    isInvalid={customercard.email.errors.length > 0}
                    value={customercard.email.value}
                  />
                  {customercard.email.errors.map((error, i) => (
                    <Form.Control.Feedback type="invalid" key={i}>
                      {error}
                    </Form.Control.Feedback>
                  ))}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Checkbox
                  name={
                    <Translation k="customercard_subscribe_to_newsletter" />
                  }
                  isChecked={customercard.newsletter.value}
                  onChange={() =>
                    customercardDispatch({
                      type: "set",
                      field: "newsletter",
                      payload: !customercard.newsletter.value,
                    })
                  }
                  uniqueId="newsletter"
                  variant="dark"
                  size="large"
                />
                {customercard.newsletter.errors.map((error, i) => (
                  <div key={i} className="invalid-feedback mb-2">
                    {error}
                  </div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col sm={{ offset: 6 }}>
                <Button
                  type="submit"
                  variant="custom"
                  disabled={formIsSubmitting}
                  className="btn-custom-submit"
                >
                  <span hidden={formIsSubmitting}>
                    <Translation k="customercard_button" />
                  </span>
                  <Spinner
                    animation="border"
                    size="sm"
                    variant="light"
                    hidden={!formIsSubmitting}
                  />
                </Button>
              </Col>
            </Row>
            <Row hidden={!formSubmitted}>
              <Col>
                <Alert variant="success" className="mt-4">
                  <Translation k="customercard_success" />
                </Alert>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const CardInfo = () => {
  return (
    <div className="infoHolder">
      <TranslationHTML k="customercard_info" />
    </div>
  );
};

export default CustomerCard;
