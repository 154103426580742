const ParseDomain = ({ url }) => {
  let newUrl;
  let display;

  if (!url) {
    return null;
  }

  if (url.includes("http://")) {
    newUrl = url.split("http://")[1];
    if (newUrl.includes("/")) {
      display = newUrl.split("/")[0];
      return display;
    }
    display = newUrl;
    return display;
  }

  if (url.includes("https://")) {
    newUrl = url.split("https://")[1];
    if (newUrl.includes("/")) {
      display = newUrl.split("/")[0];
      return display;
    }
    display = newUrl;
    return display;
  }

  if (url.includes("/")) {
    display = url.split("/")[0];
    return display;
  }
  display = url;
  return display;
};

export default ParseDomain;
