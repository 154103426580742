import React from "react";
import Translation from "translation";
import sha1 from "object-hash";

/**
 * Returns largest and smallest price values from product variants
 */
const getLargestAndSmallestVariantPrice = (variants) => {
  let largest = null;
  let smallest = null;

  variants.forEach((value) => {
    if (value.price != null) {
      const price = Number(value.price);

      if (largest === null && smallest === null) {
        largest = 0;
        smallest = 0;
      }

      if (price > largest) {
        largest = value.price;
      }

      if (smallest === 0 || price < smallest) {
        smallest = value.price;
      }
    }
  });

  return { largest, smallest };
};

/**
 * Returns total page count
 */
const getPageCount = (productCount, perPage) => {
  return Math.ceil(productCount / perPage);
};

/**
 * Find category by id in MPTT tree and return category object and array of direct parents to object
 */
const getCategoryById = (categories, categoryId, prevValues = []) => {
  let values = prevValues;

  for (let category of categories) {
    if (category.id === parseInt(categoryId)) {
      values.push(category);

      return {
        category: category,
        tree: values,
      };
    }

    if (category.children.length > 0) {
      values.push(category);
      const data = getCategoryById(category.children, categoryId, values);

      if (data !== null) {
        return {
          category: data.category,
          tree: data.tree,
        };
      } else {
        values.pop();
      }
    }
  }

  return null;
};

/**
 * Return array of current and all children category IDs
 */
const getCategoryAndChildrenIDs = (category) => {
  let results = [];

  const lookup = (value) => {
    results.push(value.id);
    value.children && value.children.forEach(lookup);
  };

  [category].forEach(lookup);

  return results;
};

/**
 * Returns appropriate product primary and secondary title
 */
const getProductTitles = (product) => {
  const getPrimaryTitle = () => {
    if (product.is_custom_product) {
      return product.name;
    }

    if (
      [product.latin_name, product.latin_species, product.latin_variety].join(
        ""
      ).length === 0
    ) {
      return "-";
    }

    return [
      product.latin_name,
      product.latin_species,
      product.latin_variety,
    ].join(" ");
  };

  const getSecondaryTitle = () => {
    if (product.is_custom_product) {
      return null;
    }

    return product.name;
  };

  return {
    primaryTitle: getPrimaryTitle(),
    secondaryTitle: getSecondaryTitle(),
  };
};

/**
 * Returns prefix locale for product Height and Width variables
 */
const getProductDimensionPrefixLocale = (hasMin, hasMax) => {
  if (hasMin && !hasMax) {
    return <Translation k="sortiment_modal_variable_from" />;
  } else if (hasMax && !hasMin) {
    return <Translation k="sortiment_modal_variable_to" />;
  }

  return null;
};

/**
 * Returns variant discounted price
 */
const getDiscountedPrice = (price, discount) => {
  const parsedPrice = Number(price);
  const discountPercent = parseInt(discount);

  if (!parsedPrice) {
    return "-";
  }

  return (parsedPrice - (parsedPrice / 100) * discountPercent).toFixed(2);
};

/**
 * Returns variant data for purchases list
 */
const _getPurcahsesListData = (product, variant) => {
  return {
    count: 1,
    data: variant,
    product: {
      id: product.id,
      name: product.name,
      is_custom_product: product.is_custom_product,
      latin_name: product.latin_name,
      latin_species: product.latin_species,
      latin_variety: product.latin_variety,
    },
  };
};

/**
 * Add or remove a variant from purchases list and returns the updated list
 */
const addOrRemoveVariantOnPurchasesList = (
  currentPurchasesList,
  product,
  variant
) => {
  const key = sha1(variant);
  let data = Object.assign({}, currentPurchasesList);

  if (key in data) {
    delete data[key];
  } else {
    data[key] = _getPurcahsesListData(product, variant);
  }

  return data;
};

/**
 * Adds multiple product variants to purchases list and returns the updated list
 */
const addMultipleVariantToPurchasesList = (currentPurchasesList, products) => {
  let data = Object.assign({}, currentPurchasesList);

  products.forEach((product) => {
    product.variants.forEach((variant) => {
      const key = sha1(variant);
      data[key] = _getPurcahsesListData(product, variant);
    });
  });

  return data;
};

export {
  getLargestAndSmallestVariantPrice,
  getPageCount,
  getCategoryById,
  getCategoryAndChildrenIDs,
  getProductTitles,
  getProductDimensionPrefixLocale,
  getDiscountedPrice,
  addOrRemoveVariantOnPurchasesList,
  addMultipleVariantToPurchasesList,
};
