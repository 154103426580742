import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.scss";
import RichText from "common/richtext/RichText";

function News({ isLoading, isError, data }) {
  return (
    <Row className={`Home-News-Content`} hidden={isLoading || isError}>
      {!isError && (
        <>
          <Col></Col>
          <Col xl="4" lg="5" md="5" className="primaryText">
            <p>
              <PrimaryTitle ready={!isLoading} data={data} />
            </p>
          </Col>
          <Col xl="4" lg="5" md="5" className="secondaryText">
            <SecondaryTitle ready={!isLoading} data={data} />
            <SecondaryTitleUrl ready={!isLoading} data={data} />
          </Col>
          <Col></Col>
        </>
      )}
    </Row>
  );
}

const PrimaryTitle = ({ ready, data }) => {
  if (!ready) {
    return "...";
  }

  return data.primary_text;
};

const SecondaryTitle = ({ ready, data }) => {
  if (!ready) {
    return "...";
  }

  return <RichText text={data.secondary_text} variant="large-text" />;
};

const SecondaryTitleUrl = ({ ready, data }) => {
  if (!ready) {
    return "";
  }

  if (data.button_name.length > 0) {
    return (
      <Link to={data.button_url} className="button">
        {data.button_name}
      </Link>
    );
  }
};

export default News;
