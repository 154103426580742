import React, { useContext, useEffect, useState } from "react";
import PartnerList from "./components/partner_list/PartnerList";
import { apiPartnersResell } from "api";
import { apiPartnersOther } from "api";
import { apiPartnersGardeners } from "api";
import { apiShowcase } from "api";
import { apiPartners } from "api";
import { LanguageContext } from "context";
import Showcase from "./components/showcase_list/Showcase";
import Translation, { TranslationHTML } from "translation";
import List from "./components/contact_list/List";
import AnchorList from "./components/anchor_list/AnchorList";
import { Row, Col, Alert } from "react-bootstrap";
import BackgroundImage from "./images/background_img.jpeg";
import "./style.scss";

let BgrImg = {
  background: "url(" + BackgroundImage + ") no-repeat",
  backgroundSize: "cover",
};

function Partners() {
  const { language } = useContext(LanguageContext);

  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(true);

  const [showcase, setShowcase] = useState([]);
  const [loadingShowcase, setLoadingShowcase] = useState(true);

  const [resell, setResell] = useState([]);
  const [loadingResell, setLoadingResell] = useState(true);

  const [other, setOther] = useState([]);
  const [loadingOther, setLoadingOther] = useState(true);

  const [gardener, setGardener] = useState([]);
  const [loadingGardener, setLoadingGardener] = useState(true);

  useEffect(() => {
    setLoadingPartners(true);

    apiPartners(language).then((data) => {
      setPartners(data);
      setLoadingPartners(false);
    });

    setLoadingShowcase(true);

    apiShowcase(language).then((data) => {
      setShowcase(data);
      setLoadingShowcase(false);
    });

    setLoadingResell(true);

    apiPartnersResell(language).then((data) => {
      setResell(data);
      setLoadingResell(false);
    });

    setLoadingOther(true);

    apiPartnersOther(language).then((data) => {
      setOther(data);
      setLoadingOther(false);
    });

    setLoadingGardener(true);

    apiPartnersGardeners(language).then((data) => {
      setGardener(data);
      setLoadingGardener(false);
    });
  }, [language]);

  return (
    <div className="Partners">
      <Row style={BgrImg}>
        <Col></Col>
        <Col xl="8" lg="11" xs="12">
          <Row className="transformY">
            <Col className="d-flex align-items-end order-2 order-lg-1">
              <AnchorList />
            </Col>
            <Col lg="7" xs="12" className="pr-md-3 order-1 order-lg-2">
              <Alert variant="light" className="content">
                <Row>
                  <Col xs="1" md="2"></Col>
                  <Col xs="10" md="8">
                    <h3>
                      <Translation k="navigation_partners" />
                    </h3>
                    <h1>
                      <Translation k="partners_main_title" />
                    </h1>
                    <TranslationHTML k="partners_main_description" />
                  </Col>
                  <Col xs="1" md="2"></Col>
                </Row>
              </Alert>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>
      <Row className="partner-info justify-content-center">
        <Col xl="8" md="11">
          <PartnerList data={partners} ready={!loadingPartners} />
        </Col>
      </Row>
      <Row id="showcase" className="check-us-out">
        <Col></Col>
        <Col xl="8" md="11">
          <h2>
            <Translation k="partners_showcase" />
          </h2>
          <Showcase data={showcase} ready={!loadingShowcase} />
        </Col>
        <Col></Col>
      </Row>
      <Col>
        <Row id="resell" className="partner-list">
          <Col></Col>
          <Col xl="7" md="10">
            <Row>
              <h2>
                <Translation k="partners_resell" />
              </h2>
            </Row>
            <List data={resell} ready={!loadingResell} />
          </Col>
          <Col></Col>
        </Row>
      </Col>
      <Row id="other" className="partner-list-other">
        <Col className="d-flex justify-content-center">
          <Col xl="7" md="10">
            <Row>
              <h2>
                <Translation k="partners_other" />
              </h2>
            </Row>
            <List data={other} ready={!loadingOther} />
          </Col>
        </Col>
      </Row>
      <Col>
        <Row id="gardener" className="gardener-list">
          <Col></Col>
          <Col xl="7" md="10">
            <Row>
              <h2>
                <Translation k="partners_gardeners" />
              </h2>
            </Row>
            <List data={gardener} ready={!loadingGardener} />
          </Col>
          <Col></Col>
        </Row>
      </Col>
    </div>
  );
}

export default Partners;
