import React, { useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { LanguageContext } from "context";
import { apiHomeNewsImages } from "api";
import { useQuery } from "react-query";
import "./style.scss";

function NewsImages({ hasNewsText }) {
  const { language } = useContext(LanguageContext);

  const { isLoading, data, isError } = useQuery(
    ["homeNewsImages", language],
    () => apiHomeNewsImages(language),
    { retry: 0 } // There could be no images so no need to retry
  );

  const RenderImage = ({ size, classes }) => {
    const variant = data && data[size];

    if (variant) {
      return (
        <img
          src={variant.url}
          className={classes}
          alt={variant.default_alt_text || variant.name}
        />
      );
    }

    return null;
  };

  return (
    <Row hidden={isLoading || isError}>
      <Col></Col>
      <Col
        xl="8"
        lg="11"
        md="11"
        className={`Home-News-Image-Content ${
          !hasNewsText ? "bottom-padding" : ""
        }`}
      >
        <RenderImage size="large" classes="d-none d-lg-block large" />
        <RenderImage
          size="medium"
          classes="d-none d-md-block d-lg-none medium"
        />
        <RenderImage size="small" classes="d-block d-md-none small" />
      </Col>
      <Col></Col>
    </Row>
  );
}

export default NewsImages;
