import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.scss";
import routes from "routes";
import Translation, { TranslationImage } from "translation";

import TujaImage from "./images/tuja.jpg";
import EgleImage from "./images/egle.jpg";
import BarbeleImage from "./images/barbele.jpg";
import RododendrsImage from "./images/rododendrs.jpg";
import KadikisImage from "./images/kadikis.jpg";
import PlaceholderImage from "./images/placeholder.jpg";

const LINKS = {
  thuja: "/sortiment/7/tujas",
  fir: "/sortiment/6/egles",
  barberry: "/sortiment/8/barbeles",
  rhododendron: "/sortiment/14/rododendri",
  junipers: "/sortiment/5/kadiki",
};

function Sortiment() {
  return (
    <Row className="Home-Sortiment-Suggestions">
      <Col></Col>
      <Col xl="8" md="11">
        <Row>
          <Col>
            <div className="sortimentBox">
              <div className="text">
                <Translation k="landing_tujas" />
              </div>
              <Link to={LINKS.thuja}>
                <img src={PlaceholderImage} alt="" />
                <TranslationImage
                  k="landing_tujas"
                  src={TujaImage}
                  variant="mainImage"
                />
              </Link>
            </div>
          </Col>
          <Col className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
            <div className="sortimentBox">
              <div className="text">
                <Translation k="landing_egles" />
              </div>
              <Link to={LINKS.fir}>
                <img src={PlaceholderImage} alt="" />
                <TranslationImage
                  k="landing_egles"
                  src={EgleImage}
                  variant="mainImage"
                />
              </Link>
            </div>
          </Col>
          <Col className="d-none d-md-block d-lg-block d-xl-block">
            <div className="sortimentBox">
              <div className="text">
                <Translation k="landing_barbeles" />
              </div>
              <Link to={LINKS.barberry}>
                <img src={PlaceholderImage} alt="" />
                <TranslationImage
                  k="landing_barbeles"
                  src={BarbeleImage}
                  variant="mainImage"
                />
              </Link>
            </div>
          </Col>
          <Col className="d-none d-lg-block d-xl-block">
            <div className="sortimentBox">
              <div className="text">
                <Translation k="landing_rododendri" />
              </div>
              <Link to={LINKS.rhododendron}>
                <img src={PlaceholderImage} alt="" />
                <TranslationImage
                  k="landing_rododendri"
                  src={RododendrsImage}
                  variant="mainImage"
                />
              </Link>
            </div>
          </Col>
          <Col className="d-none d-xl-block">
            <div className="sortimentBox">
              <div className="text">
                <Translation k="landing_kadiki" />
              </div>
              <Link to={LINKS.junipers}>
                <img src={PlaceholderImage} alt="" />
                <TranslationImage
                  k="landing_kadiki"
                  src={KadikisImage}
                  variant="mainImage"
                />
              </Link>
            </div>
          </Col>
          <Col>
            <div className="sortimentBox open-all d-flex">
              <div className="align-self-center w-100 text-center">
                <Link to={routes.sortiment}>
                  <Translation k="landing_view_all" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

export default Sortiment;
