import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PlaceHolderImage from "./images/placeholder.jpg";
import "./style.scss";
import ImageModal from "common/image_modal/ImageModal";

const ContentBlockImage = ({ image, thumbnails }) => {
  const { default_alt_text, description } = image;
  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <ImageModal
        show={show}
        src={thumbnails.large}
        name={default_alt_text}
        description={description}
        onShowChange={changeShow}
      />
      <span onClick={changeShow} className="contentBlockLazyImageLink">
        <LazyImage image={thumbnails.small} alt={default_alt_text} />
      </span>
    </React.Fragment>
  );
};

const LazyImage = ({ image, alt }) => {
  return (
    <div className="position-relative contentBlockLazyImageHolder mb-3">
      <img src={PlaceHolderImage} alt="" />
      <div className="position-absolute w-100 lazy">
        <LazyLoadImage
          src={image}
          alt={alt}
          effect="opacity"
          wrapperClassName="w-100"
        />
      </div>
    </div>
  );
};

export default ContentBlockImage;
