import React, { useContext, useState, useEffect } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { slide as Menu } from "react-burger-menu";
import { NavLink, Link } from "react-router-dom";
import { LanguageContext } from "context";
import { PhoneIcon, LanguageArrowDownIcon, MobileMenu } from "./icons";
import "./styles/style.scss";
import "./styles/mobileMenu.scss";
import NavigationButton from "./NavigationButton";
import navigation from "navigation.js";
import routes from "routes";
import Translation from "translation";
import config from "config";
import {
  FaRegCalendarAlt as CalendarIcon,
  FaRegEnvelopeOpen as SubscribeToNewsIcon,
} from "react-icons/fa";
import { FiMapPin as PinIcon } from "react-icons/fi";
import { NavHashLink } from "react-router-hash-link";
import { apiHasActiveActivities } from "api";
import LargeLogoImage from "./images/logo.png";
import SmallLogoImage from "./images/small_logo.png";

const navigationItemsReversed = navigation.slice().reverse();

function Header() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [mobileMenuIsOpen, setmobileMenuIsOpen] = useState(false);
  const [hasActivities, setHasActivities] = useState(false);

  useEffect(() => {
    apiHasActiveActivities().then((respone) => {
      setHasActivities(respone["status"]);
    });
  }, []);

  const renderMobileNavItem = (item) => {
    const { url, translation_key, type } = item;

    if (type === "dropdown") {
      return item.children.map(({ translation_key, url }) => {
        return (
          <NavLink
            key={translation_key}
            exact={true}
            to={url}
            activeClassName="active"
            onClick={() => setmobileMenuIsOpen(!mobileMenuIsOpen)}
          >
            <Translation k={translation_key} />
          </NavLink>
        );
      });
    }

    if (type === "shop") {
      return (
        <a
          href={url}
          key={translation_key}
          target="_blank"
          className="shopButton"
          rel="noopener noreferrer"
        >
          <Translation k={translation_key} />
        </a>
      );
    }

    if (type === "mob-activities-scroll") {
      return (
        <NavHashLink
          to={url}
          key={translation_key}
          onClick={() => setmobileMenuIsOpen(!mobileMenuIsOpen)}
          activeClassName=""
          hidden={!hasActivities}
        >
          <Translation k={translation_key} />
        </NavHashLink>
      );
    }

    if (type === "mob-newsletter-scroll") {
      return (
        <NavHashLink
          to={url}
          key={translation_key}
          onClick={() => setmobileMenuIsOpen(!mobileMenuIsOpen)}
          activeClassName=""
        >
          <Translation k={translation_key} />
        </NavHashLink>
      );
    }

    if (type === "basic") {
      return (
        <NavLink
          key={translation_key}
          exact={true}
          to={url}
          activeClassName="active"
          onClick={() => setmobileMenuIsOpen(!mobileMenuIsOpen)}
        >
          <Translation k={translation_key} />
        </NavLink>
      );
    }
  };

  return (
    <React.Fragment>
      {/* Mobile side menu */}
      <Menu
        width={"100%"}
        customBurgerIcon={false}
        isOpen={mobileMenuIsOpen}
        onStateChange={(state) => setmobileMenuIsOpen(state.isOpen)}
      >
        {navigation.map((value) => renderMobileNavItem(value))}

        <div className="languageButtons">
          {config.LANGAUGES.map(({ key, value }) => (
            <button
              onClick={() => setLanguage(key)}
              className={language === key ? "active" : ""}
              key={key}
            >
              {value}
            </button>
          ))}
        </div>
      </Menu>

      {/* Mobile header */}
      <Row>
        <Col md="12" className="Mobile-Menu d-lg-none d-xl-none d-md-block">
          <div className="mobileHeaderLogo">
            <Link to={routes.home}>
              <img
                src={SmallLogoImage}
                className="mobileMainLogo"
                alt={config.COMPANY_NAME}
              />
            </Link>
          </div>

          <MobileMenu
            className="menuButton"
            onClick={() => setmobileMenuIsOpen(!mobileMenuIsOpen)}
          />
        </Col>
      </Row>

      <Row>
        <Col></Col>
        <Col
          xl="8"
          md="11"
          className="Header-Info d-none d-lg-block d-xl-block"
        >
          <div className="headerLogo">
            <Link to={routes.home}>
              <img
                src={LargeLogoImage}
                className="mainLogo"
                alt={config.COMPANY_NAME}
              />
            </Link>
          </div>

          <ul>
            <li>
              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>{language}</Dropdown.Toggle>

                <Dropdown.Menu alignRight>
                  {config.LANGAUGES.map(({ key, value }) => (
                    <Dropdown.Item
                      onSelect={() => setLanguage(key)}
                      hidden={language === key}
                      key={key}
                    >
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <PhoneIcon className="icon phone" />
              {config.CONTACTS.header.phone}
            </li>
            <li>
              <PinIcon className="icon pin" />
              {config.CONTACTS.header.address}
            </li>
            <li>
              <SubscribeToNewsIcon className="icon newsletter" />
              <NavHashLink to={routes.home_newsletter}>
                <Translation k="home_newsletter_title" />
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={routes.home_activities} hidden={!hasActivities}>
                <CalendarIcon className="icon calendar" />
                <Translation k="navigation_active_activities" />
              </NavHashLink>
            </li>
          </ul>
        </Col>
        <Col></Col>
      </Row>

      <Row>
        <Col></Col>
        <Col
          xl="8"
          md="11"
          className="Header-Navigation d-none d-lg-block d-xl-block"
        >
          <Navigation />
        </Col>
        <Col></Col>
      </Row>
    </React.Fragment>
  );
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="languageDropdown"
  >
    {children} <LanguageArrowDownIcon />
  </div>
));

const Navigation = () => {
  return (
    <ul>
      {navigationItemsReversed.map((value, index) => (
        <NavigationButton key={index} data={value} />
      ))}
    </ul>
  );
};

export default Header;
