import React from "react";
import { Row, Col, Alert } from "react-bootstrap";
import Translation from "translation";

function Error404() {
  const style = {
    paddingBottom: "100px",
    paddingTop: "100px",
  };

  return (
    <Row>
      <Col></Col>
      <Col xl="8" lg="11" md="12" style={style} className="text-center">
        <Alert variant="dark">
          <Translation k="error_404" />
        </Alert>
      </Col>
      <Col></Col>
    </Row>
  );
}

export default Error404;
