// Size in CM
const WIDTH_MIN = 0;
const WIDTH_MAX = 350;

// Price in EUR
const PRICE_MIN = 0;
const PRICE_MAX = 300;

// Size in CM
const HEIGHT_MIN = 0;
const HEIGHT_MAX = 200;

// Initial filter values
const INITIAL_FILTERS = {
  is_hedge: false,
  is_discount: false,
  height: {
    min: HEIGHT_MIN,
    max: HEIGHT_MAX,
  },
  width: {
    min: WIDTH_MIN,
    max: WIDTH_MAX,
  },
  price: {
    min: PRICE_MIN,
    max: PRICE_MAX,
  },
};

export {
  PRICE_MIN,
  PRICE_MAX,
  WIDTH_MIN,
  WIDTH_MAX,
  HEIGHT_MIN,
  HEIGHT_MAX,
  INITIAL_FILTERS,
};
