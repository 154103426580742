import React from "react";
import { Link } from "react-router-dom";
import ItemCard from "./Card";

const Item = ({ data }) => {
  let url = data.www;
  if (url) {
    return (
      <Link
        to={
          !url.includes("https://") && !url.includes("http://")
            ? { pathname: "http://" + url }
            : { pathname: url }
        }
        rel="noopener noreferrer"
        target="_blank"
      >
        <ItemCard data={data} />
      </Link>
    );
  }
  return <ItemCard data={data} />;
};

export default Item;
