/**
 * Returns variants summed total price and summed total price with discount
 */
const getSummedVariantsPrices = (variants) => {
  let total = 0;
  let totalWithDiscount = 0;

  variants.forEach((variant) => {
    const { count, data } = variant[1];
    const price = Number(data.price);
    const discount = data.discount / 100;

    total += count * price;
    totalWithDiscount += count * (price - price * discount);
  });

  total = total.toFixed(2);
  totalWithDiscount = totalWithDiscount.toFixed(2);

  return { total, totalWithDiscount };
};

/**
 * Returns list of objects with variant data and count
 */
const getVariantIDsWithCount = (variants) => {
  let result = [];

  variants.forEach((variant) => {
    result.push({
      variant_id: variant[1].data.id,
      count: variant[1].count,
    });
  });

  return result;
};

/**
 * Force browser to download file generated from blob data
 */
const downloadFile = (data, filename) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

/**
 * Format object to list. Instead of Object.entries() this function is used to support old browsers.
 */
const formatObjectToList = (obj) => {
  let ownProps = Object.keys(obj),
    i = ownProps.length,
    resArray = new Array(i); // preallocate the Array
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
};

export {
  getSummedVariantsPrices,
  getVariantIDsWithCount,
  downloadFile,
  formatObjectToList,
};
