import React from "react";
import Translation from "translation";
import Loader from "../multiuse/Loader";
import Message from "../multiuse/Message";
import NewsBox from "../multiuse/NewsBox";
import { Col, Row } from "react-bootstrap";
import "./style.scss";

const OpenHours = ({ data, ready }) => {
  if (!ready) {
    return <Loader />;
  }
  if (!data) {
    return <Message />;
  }
  if (data.days.length === 0) {
    return (
      <Row className="open-hours-wrap">
        <Col className="d-none d-sm-block d-md-none d-lg-block"></Col>
        <Col sm="9" md="12" lg="10" className="open-hours">
          <Row className="justify-content-center">
            <Col xs="8" sm="6" md="5" lg="4" className="d-lg-block d-xl-none">
              {data.text ? (
                <Row className="news lower align-items-center">
                  <NewsBox data={data} />
                </Row>
              ) : null}
            </Col>
          </Row>
        </Col>
        <Col className="d-none d-sm-block d-md-none d-lg-block"></Col>
      </Row>
    );
  }
  return (
    <Row className="open-hours-wrap">
      <Col className="d-none d-sm-block d-md-none d-lg-block"></Col>
      <Col sm="9" md="12" lg="10" className="open-hours">
        <h2>
          <Translation k="contacts_open_hours" />
        </h2>
        {data.days.map((days) => (
          <Row key={days.id}>
            <Col>
              {days.name.split(",").map((name) => (
                <p key={name}>{name}</p>
              ))}
            </Col>
            <Col xs="7" md="8" className="m-auto">
              {days.times.map((times) => (
                <p key={times.id}>
                  <b className="font-weight-bold">{times.time}</b>
                </p>
              ))}
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center">
          <Col xs="8" sm="6" md="5" lg="4" className="d-lg-block d-xl-none">
            {data.text ? (
              <Row className="news lower align-items-center">
                <NewsBox data={data} />
              </Row>
            ) : null}
          </Col>
        </Row>
      </Col>
      <Col className="d-none d-sm-block d-md-none d-lg-block"></Col>
    </Row>
  );
};

export default OpenHours;
