import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Slider from "common/slider/Slider";

function RangeFilter({
  type,
  label,
  defaultMin,
  defaultMax,
  onRangeChange,
  contentClasses,
}) {
  const [min, setMin] = useState(defaultMin);
  const [max, setMax] = useState(defaultMax);
  const [values, setValues] = useState({ min: defaultMin, max: defaultMax });

  const submit = (e) => {
    e.preventDefault();
    changeValue(min, max);
  };

  const sliderChange = (value) => {
    changeValue(value.min, value.max);
  };

  const changeValue = (from, to) => {
    let newMin = parseInt(from);
    let newMax = parseInt(to);

    if (!newMin || newMin < defaultMin || newMin > defaultMax) {
      newMin = defaultMin;
    }

    if (!newMax || newMax > defaultMax) {
      newMax = defaultMax;
    }

    if (newMin > newMax) {
      let oldNewMin = newMin;
      newMin = newMax;
      newMax = oldNewMin;
    }

    setMin(newMin);
    setMax(newMax);
    setValues({ min: newMin, max: newMax });

    onRangeChange(type, { min: newMin, max: newMax });
  };

  const getContentClasses = () => {
    return contentClasses ? contentClasses : "";
  };

  return (
    <React.Fragment>
      <div className="label">{label}</div>
      <div className={"content " + getContentClasses()}>
        <Form onSubmit={submit}>
          <Row>
            <Col xs="5" className="pr-0">
              <Form.Group controlId={`min-` + type}>
                <Form.Control
                  type="number"
                  onChange={(e) => setMin(e.target.value)}
                  value={min}
                  onBlur={(e) => changeValue(min, max)}
                />
              </Form.Group>
            </Col>
            <Col xs="2">
              <div className="d-flex h-100">
                <div className="m-auto">-</div>
              </div>
            </Col>
            <Col xs="5" className="pl-0">
              <Form.Group controlId={`max-` + type}>
                <Form.Control
                  type="number"
                  onChange={(e) => setMax(e.target.value)}
                  value={max}
                  onBlur={(e) => changeValue(min, max)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="pl-2 pr-2 pt-4">
                <Slider
                  maxValue={defaultMax}
                  minValue={defaultMin}
                  step={1}
                  onValueChange={sliderChange}
                  showLabel={true}
                  updateValues={values}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type="submit" hidden></Button>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
}

export default RangeFilter;
