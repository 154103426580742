import React from "react";
import { Table } from "react-bootstrap";
import Translation from "translation";
import { ReactComponent as CheckIcon } from "./icons/check.svg";

function MonthsTable({ product }) {
  const monthLiterals = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];

  const isVisible = () => {
    let visible = false;
    monthLiterals.forEach((name) => {
      if (product["blooming_" + name] || product["trimming_" + name])
        visible = true;
    });
    return visible;
  };

  if (!isVisible()) {
    return null;
  }

  return (
    <Table responsive className="text-center">
      <thead>
        <tr>
          <th>
            <Translation k="sortiment_modal_table_months_time" />
          </th>
          {monthLiterals.map((name, index) => (
            <th key={index}>
              <Translation
                k={"month_short_" + String("0" + (index + 1)).slice(-2)}
              />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-left">
            <Translation k="sortiment_modal_table_months_blooming" />
          </td>
          {monthLiterals.map((name, index) => (
            <td key={index}>{product["blooming_" + name] && <CheckIcon />}</td>
          ))}
        </tr>
        <tr>
          <td className="text-left">
            <Translation k="sortiment_modal_table_months_trimming" />
          </td>
          {monthLiterals.map((name, index) => (
            <td key={index}>{product["trimming_" + name] && <CheckIcon />}</td>
          ))}
        </tr>
      </tbody>
    </Table>
  );
}

export default MonthsTable;
