import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import Translation from "translation";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PlaceHolderImage from "./images/placeholder.jpg";
import ImageModal from "common/image_modal/ImageModal";

function Images({ mainImage, galleryImages }) {
  const [index, setIndex] = useState(0);
  const [images, setImages] = useState([]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const getImages = (main, gallery) => {
    let images = gallery.map((e) => e);

    if (main.image) images.unshift(main);

    return images;
  };

  useEffect(() => {
    setImages(getImages(mainImage, galleryImages));
  }, [mainImage, galleryImages]);

  if (images.length === 0) {
    return (
      <div
        className="d-block w-100 rounded d-flex"
        style={{ backgroundColor: "#ccc", height: "200px" }}
      >
        <div className="m-auto">
          <Translation k="sortiment_modal_no_image" />
        </div>
      </div>
    );
  }

  if (images.length === 1) {
    return <LazyImage image={images[0]} />;
  }

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {images.map((img, index) => (
        <Carousel.Item key={index}>
          <LazyImage image={img} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

const LazyImage = ({ image }) => {
  const [show, setShow] = useState(false);

  const changeShow = () => {
    setShow(!show);
  };

  return (
    <React.Fragment>
      <ImageModal
        show={show}
        src={image.thumbnails.large}
        name={image.image.default_alt_text}
        description={image.image.description}
        onShowChange={changeShow}
      />
      <div
        className="position-relative contentBlockLazyImageHolder"
        onClick={changeShow}
      >
        <img src={PlaceHolderImage} alt="" />
        <div className="position-absolute w-100 lazy">
          <LazyLoadImage
            src={image.thumbnails.carousel}
            alt={image.image.default_alt_text}
            effect="opacity"
            wrapperClassName="w-100 link"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Images;
