const giftcardReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return action.payload;
    case "set_name":
      return { ...state, name: { ...state.name, value: action.payload } };
    case "set_email":
      return { ...state, email: { ...state.email, value: action.payload } };
    case "set_phone":
      return { ...state, phone: { ...state.phone, value: action.payload } };
    case "set_price":
      let price_value = action.payload;
      if (price_value !== 0 && price_value.length > 0)
        price_value = Math.floor(action.payload);
      return {
        ...state,
        price: {
          ...state.price,
          value: price_value,
          error: action.payload < 10,
        },
      };
    case "set_count":
      let count_value = action.payload;
      if (count_value !== 0 && count_value.length > 0)
        count_value = Math.floor(action.payload);
      return {
        ...state,
        count: {
          ...state.count,
          value: count_value,
          error: action.payload < 1 || action.payload > 8,
        },
      };
    case "set_legal_nr":
      return {
        ...state,
        legal_nr: { ...state.legal_nr, value: action.payload },
      };
    case "set_address":
      return { ...state, address: { ...state.address, value: action.payload } };
    case "set_bank_account":
      return {
        ...state,
        bank_account: { ...state.bank_account, value: action.payload },
      };
    case "set_bank":
      return { ...state, bank: { ...state.bank, value: action.payload } };
    case "reset_errors":
      return {
        ...state,
        name: { ...state.name, errors: [] },
        email: { ...state.email, errors: [] },
        phone: { ...state.phone, errors: [] },
        legal_nr: { ...state.legal_nr, errors: [] },
        address: { ...state.address, errors: [] },
        bank_account: { ...state.bank_account, errors: [] },
        bank: { ...state.bank, errors: [] },
        price: { ...state.price, error: false },
        count: { ...state.count, error: false },
      };
    case "set_name_error":
      return { ...state, name: { ...state.name, errors: action.payload } };
    case "set_email_error":
      return { ...state, email: { ...state.email, errors: action.payload } };
    case "set_phone_error":
      return { ...state, phone: { ...state.phone, errors: action.payload } };
    case "set_legal_nr_error":
      return {
        ...state,
        legal_nr: { ...state.legal_nr, errors: action.payload },
      };
    case "set_address_error":
      return {
        ...state,
        address: { ...state.address, errors: action.payload },
      };
    case "set_bank_account_error":
      return {
        ...state,
        bank_account: { ...state.bank_account, errors: action.payload },
      };
    default:
      return state;
  }
};

export { giftcardReducer };
