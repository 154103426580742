import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import routes from "routes";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  const isSortimentSubPage = (path) => {
    return path.includes(routes.sortiment) && path.split("/").length > 2;
  };

  useEffect(() => {
    if (!isSortimentSubPage(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
