import React from "react";
import { Row, Col } from "react-bootstrap";
import "./style.scss";
import config from "config";

import TitleImageLarge from "./images/large.jpg";
import TitleImageMedium from "./images/medium.jpg";
import TitleImageSmall from "./images/small.jpg";

function Image() {
  return (
    <Row>
      <Col className="Home-Landing-Image d-none d-xl-block d-lg-block">
        <img src={TitleImageLarge} alt={config.COMPANY_NAME} />
      </Col>

      <Col className="Home-Landing-Image d-none d-md-block d-lg-none">
        <img src={TitleImageMedium} alt={config.COMPANY_NAME} />
      </Col>

      <Col className="Home-Landing-Image d-lg-none d-xl-none d-md-none">
        <img src={TitleImageSmall} alt={config.COMPANY_NAME} />
      </Col>
    </Row>
  );
}

export default Image;
