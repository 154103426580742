import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Alert, Spinner, Button } from "react-bootstrap";
import Translation from "translation";
import "./style.scss";
import { apiPublicationCategories, apiPublications } from "api";
import { LanguageContext } from "context";
import routes from "routes";
import { Link, useParams } from "react-router-dom";
import { getPublicationUrl } from "./utils";
import ContentBlocks from "common/content_blocks/ContentBlocks";

function Publications() {
  const { categoryId, publicationId } = useParams();
  const { language } = useContext(LanguageContext);

  const [loadingCategories, setLoadingCategories] = useState(true);
  const [loadingPublications, setLoadingPublications] = useState(true);

  const [categories, setCategories] = useState([]);
  const [publications, setPublications] = useState([]);

  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentPublication, setCurrentPublication] = useState(null);
  const [filteredPublications, setFilteredPublications] = useState([]);

  // Load categories and publications from API
  useEffect(() => {
    setLoadingCategories(true);
    setLoadingPublications(true);

    apiPublicationCategories(language).then((data) => {
      setCategories(data);
      setLoadingCategories(false);
    });

    apiPublications(language).then((data) => {
      setPublications(data);
      setLoadingPublications(false);
    });
  }, [language]);

  // Set current active category
  useEffect(() => {
    if (!categoryId || parseInt(categoryId) === 0) {
      setCurrentCategory(null);
      return;
    }

    categories.forEach((category) => {
      if (category.id === parseInt(categoryId)) {
        setCurrentCategory(category);
      }
    });
  }, [categoryId, categories]);

  // Filter publications based on selected category
  useEffect(() => {
    if (!categoryId || parseInt(categoryId) === 0) {
      setFilteredPublications(publications);
      return;
    }

    setFilteredPublications(
      publications.filter((p) => p.category.id === parseInt(categoryId))
    );
  }, [categoryId, publications]);

  // Set current active category
  useEffect(() => {
    if (!publicationId) {
      setCurrentPublication(null);
      return;
    }

    publications.forEach((publication) => {
      if (publication.id === parseInt(publicationId)) {
        setCurrentPublication(publication);
      }
    });
  }, [publicationId, publications]);

  return (
    <Row className="Publication">
      <Col></Col>
      <Col xl="8" lg="11" md="12">
        <Row className="header">
          <Col>
            <Sections
              ready={!loadingCategories && !loadingPublications}
              category={currentCategory}
              publication={currentPublication}
            />
          </Col>
          <Col md="3" className="d-none d-md-flex">
            <DownloadButton publication={currentPublication} />
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <Navigation
              ready={!loadingCategories && !loadingPublications}
              categories={categories}
              currentCategory={currentCategory}
            />
            <div className="d-md-none">
              <DownloadButton publication={currentPublication} />
            </div>
          </Col>
          <Col md="9">
            <Articles
              ready={!loadingCategories && !loadingPublications}
              filteredPublications={filteredPublications}
              currentPublication={currentPublication}
              currentCategory={currentCategory}
            />
          </Col>
        </Row>
      </Col>
      <Col></Col>
    </Row>
  );
}

const Sections = ({ ready, category, publication }) => {
  if (!ready) {
    return (
      <div className="navigationLevel">
        <Translation k="publication_publication" />
      </div>
    );
  }

  const renderCategory = () => {
    if (!category) {
      return (
        <Link to={routes.publications}>
          <i className="arrow right"></i>
          <Translation k="publication_all" />
        </Link>
      );
    }

    return (
      <React.Fragment>
        <i className="arrow right"></i>
        <Link
          to={routes.publications + "/" + category.id + "/" + category.slug}
        >
          {category.name}
        </Link>
      </React.Fragment>
    );
  };

  return (
    <div className="navigationLevel">
      <Link to={routes.publications}>
        <Translation k="publication_publication" />
      </Link>
      <span>{renderCategory()}</span>
      {publication && (
        <span>
          <i className="arrow right"></i>
          <Link to={getPublicationUrl(publication, category)}>
            {publication.name}
          </Link>
        </span>
      )}
    </div>
  );
};

const DownloadButton = ({ publication }) => {
  if (!publication || !publication.original) {
    return null;
  }

  return (
    <Button
      href={publication.original.url}
      target="_blank"
      variant="custom"
      className="downloadButton w-100"
    >
      <Translation k="publication_donwload_original" />
    </Button>
  );
};

const Navigation = ({ ready, categories, currentCategory }) => {
  const [showNavigation, setShowNavigation] = useState(true);

  // Hackish way to close filters on mobile devices by default
  useEffect(() => {
    if (window.innerWidth < 768) {
      setShowNavigation(false);
    }
    // eslint-disable-next-line
  }, []);

  if (!ready) {
    return (
      <Alert variant="secondary" className="pt-5 pb-5 text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  return (
    <React.Fragment>
      <button
        className="btn filterButton d-block d-md-none"
        onClick={() => setShowNavigation(!showNavigation)}
      >
        <Translation k="publication_all" />
      </button>
      <div className="filterHolder" hidden={!showNavigation}>
        <ul className="categories">
          <li>
            <Link
              to={routes.publications}
              className={currentCategory === null ? "active" : ""}
            >
              <Translation k="publication_all" />
            </Link>
          </li>
          {categories.map((category) => (
            <li key={category.id}>
              <Link
                to={
                  routes.publications + "/" + category.id + "/" + category.slug
                }
                className={
                  currentCategory && currentCategory.id === category.id
                    ? "active"
                    : ""
                }
              >
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );
};

const Articles = ({
  ready,
  filteredPublications,
  currentPublication,
  currentCategory,
}) => {
  if (!ready) {
    return (
      <Alert variant="secondary" className="text-center">
        <Spinner animation="border" variant="secondary" />
      </Alert>
    );
  }

  if (currentPublication) {
    return <ContentBlocks contents={currentPublication.contents} />;
  }

  if (filteredPublications.length === 0) {
    return (
      <Row>
        <Col>
          <Alert variant="secondary" className="text-center">
            <Translation k="error_no_content" />
          </Alert>
        </Col>
      </Row>
    );
  }

  return (
    <React.Fragment>
      <Row className="listHeader">
        <Col className="title">
          <Translation k="publication_publication" />
        </Col>
        <Col className="align-self-end d-none d-lg-flex" md="2">
          <Translation k="publication_date" />
        </Col>
        <Col className="align-self-end d-none d-lg-flex" md="3">
          <Translation k="publication_issuer" />
        </Col>
      </Row>

      {filteredPublications.map((publication) => (
        <Row key={publication.id}>
          <Col>
            <Link
              to={getPublicationUrl(publication, currentCategory)}
              className="listItemLink"
            >
              <div className="listItem">
                <Row>
                  <Col className="d-flex" xs="12" sm="9" lg="7">
                    <div className="m-auto w-100">{publication.name}</div>
                  </Col>
                  <Col className="d-flex d-sm-none small" xs="12">
                    {publication.issue_date}{" "}
                    <i className="pl-2">{publication.category.name}</i>
                  </Col>
                  <Col className="d-none d-sm-flex" sm="3" lg="2">
                    <div className="m-auto w-100">
                      {publication.issue_date}
                      <div className="d-lg-none">
                        <i>{publication.category.name}</i>
                      </div>
                    </div>
                  </Col>
                  <Col className="d-none d-lg-flex" xs="3">
                    <div className="m-auto w-100">
                      <img
                        alt={publication.category.image.default_alt_text}
                        src={publication.category.thumbnails.carousel}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </Link>
          </Col>
        </Row>
      ))}
    </React.Fragment>
  );
};

export default Publications;
