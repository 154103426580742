import React from "react";
import ParseDomain from "./parseDomain";
import { Link } from "react-router-dom";

const checkLink = ({ url }) => {
  return (
    <Link
      to={
        !url.includes("https://") && !url.includes("http://")
          ? { pathname: "http://" + url }
          : { pathname: url }
      }
      rel="noopener noreferrer"
      target="_blank"
    >
      <ParseDomain url={url} />
    </Link>
  );
};

export default checkLink;
