const filtersReducer = (state, action) => {
  switch (action.type) {
    case "update_height":
      return { ...state, height: action.payload };
    case "update_width":
      return { ...state, width: action.payload };
    case "update_price":
      return { ...state, price: action.payload };
    case "update_hedge":
      return { ...state, is_hedge: action.payload };
    case "update_discount":
      return { ...state, is_discount: action.payload };
    case "reset":
      return action.payload;
    default:
      return state;
  }
};

export { filtersReducer };
