const Split = ({ value }) => {
  let split_value;

  if (value.indexOf("0") === 0) {
    split_value = "0" + value.split("0")[1];
    return split_value;
  }
  if (value.indexOf(".0") > -1) {
    split_value = value.split(".0")[0];
    return split_value;
  }
  split_value = value.split(0);
  return split_value;
};

export default Split;
